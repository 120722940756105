export const trelloLabelOptions = [
  {
    id: 1, value: 'green_light', text: 'Light Green', color: '#B7DDB0',
  },
  {
    id: 2, value: 'yellow_light', text: 'Light Yellow', color: '#F5EA92',
  },
  {
    id: 3, value: 'orange_light', text: 'Light Orange', color: '#FAD29C',
  },
  {
    id: 4, value: 'red_light', text: 'Light Red', color: '#EFB3AB',
  },
  {
    id: 5, value: 'purple_light', text: 'Light Purple', color: '#DFC0EB',
  },
  {
    id: 6, value: 'blue_light', text: 'Light Blue', color: '#8BBDD9',
  },
  {
    id: 7, value: 'sky_light', text: 'Light Sky', color: '#8FDFEB',
  },
  {
    id: 8, value: 'lime_light', text: 'Light Lime', color: '#B3F1D0',
  },
  {
    id: 9, value: 'pink_light', text: 'Light Pink', color: '#F9C2E4',
  },
  {
    id: 10, value: 'black_light', text: 'Light Black', color: '#505F79',
  },
  {
    id: 11, value: 'green', text: 'Green', color: '#7BC86C',
  },
  {
    id: 12, value: 'yellow', text: 'Yellow', color: '#F5DD29',
  },
  {
    id: 13, value: 'orange', text: 'Orange', color: '#FFAF3F',
  },
  {
    id: 14, value: 'red', text: 'Red', color: '#EF7564',
  },
  {
    id: 15, value: 'purple', text: 'Purple', color: '#CD8DE5',
  },
  {
    id: 16, value: 'blue', text: 'Blue', color: '#5BA4CF',
  },
  {
    id: 17, value: 'sky', text: 'Sky', color: '#29CCE5',
  },
  {
    id: 18, value: 'lime', text: 'Lime', color: '#6DECA9',
  },
  {
    id: 19, value: 'pink', text: 'Pink', color: '#FF8ED4',
  },
  {
    id: 20, value: 'black', text: 'Black', color: '#344563',
  },
  {
    id: 21, value: 'green_dark', text: 'Dark Green', color: '#5AAC44',
  },
  {
    id: 22, value: 'yellow_dark', text: 'Dark Yellow', color: '#E6C60D',
  },
  {
    id: 23, value: 'orange_dark', text: 'Dark Orange', color: '#E79217',
  },
  {
    id: 24, value: 'red_dark', text: 'Dark Red', color: '#CF513D',
  },
  {
    id: 25, value: 'purple_dark', text: 'Dark Purple', color: '#A86CC1',
  },
  {
    id: 26, value: 'blue_dark', text: 'Dark Blue', color: '#026AA7',
  },
  {
    id: 27, value: 'sky_dark', text: 'Dark Sky', color: '#00AECC',
  },
  {
    id: 28, value: 'lime_dark', text: 'Dark Lime', color: '#4ED583',
  },
  {
    id: 29, value: 'pink_dark', text: 'Dark Pink', color: '#E568AF',
  },
  {
    id: 30, value: 'black_dark', text: 'Dark Black', color: '#091E42',
  },
]
