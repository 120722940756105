<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="accurateForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <accurate-user-select
            v-model="selectedToken"
            :items="accurateUserList"
            :loading="loadingUserAccurate"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Accurate"
                :details="[
                  'Akun Accurate yang akan digunakan untuk melakukan aksi',
                  'Jika akun Accurate belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </accurate-user-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <accurate-database-select
                :key="0"
                v-model="selectedDatabase"
                :token-id="selectedToken.id"
                @input="changeDatabase"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-3'"
                    title="Database Accurate"
                    :details="[
                      'Database Accurate yang akan digunakan sebagai tempat melakukan aksi',
                      'Jika belum ada database, silahkan membuat database terlebih dahulu di Accurate',
                    ]"
                  />
                </template>
              </accurate-database-select>
              <!-- <template v-if="selectedDatabase">
                <accurate-account-select
                  :key="1"
                  v-model="selectedExpenseAccount"
                  :token-id="selectedToken.id"
                  :db-id="selectedDatabase"
                  label="Akun Beban"
                  type="expense"
                />
              </template> -->
              <div
                :key="1"
                class="mt-4 text-center"
              >
                Data Produk & customer dari Sales Order akan diisi otomatis oleh sistem
              </div>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>

import AccurateUserSelect from '../../components/selects/AccurateUserSelect.vue'
import AccurateDatabaseSelect from './components/AccurateDatabaseSelect.vue'
// import AccurateAccountSelect from './components/AccurateAccountSelect.vue'
import { onMounted, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import useAccurate from '@/composables/useAccurate'
import { isNumber } from '@/@core/utils/filter'
import { mdiHelpCircleOutline } from '@mdi/js'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    AccurateUserSelect,
    AccurateDatabaseSelect,
    // AccurateAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectedToken = ref(null)
    const selectedDatabase = ref(null)
    const selectedExpenseAccount = ref(null)
    const accurateForm = ref({})
    const formData = ref({})
    const accurateInput = ref([])

    const loadFormEdit = data => {
      data.forEach(el => {
        console.log(el)
        // props untuk workflowInput
        if (el.property_id === 1301) selectedDatabase.value = +el.value

        const formDataIndex = el.property_id
        if (!formData.value[formDataIndex]) formData.value[formDataIndex] = []
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    const {
      accurateUserList, loadingUserAccurate, fetchUserAccurate,
    } = useAccurate()

    onMounted(() => {
      fetchUserAccurate().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = accurateUserList.value.find(el => +el.id === props.token.id)
          loadFormEdit(props.props)
        }
      })
    })

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      emit('input', result)
    }

    const changeDatabase = val => {
      formData.value['1301'] = {
        parent_id: null,
        property_id: 1301,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const validate = () => {
      let isValid = true
      console.log(accurateInput.value)
      if (!accurateForm.value.validate()) isValid = false
      for (let i = 0; i < accurateInput.value.length; i += 1) {
        if (!accurateInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    return {
      selectedToken,
      selectedDatabase,
      selectedExpenseAccount,

      required,
      changeToken,
      onInput,

      accurateUserList,
      loadingUserAccurate,

      formData,
      accurateForm,
      validate,
      changeDatabase,
      icons: {
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>
