import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"whatsappForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(!_vm.loadingEdit)?[_c('workflow-input',{key:1,ref:"whatsappInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1501,"placeholder":"Kepada","variables":_vm.options,"required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Kepada","details":[
                    'Nomor Tujuan yang akan dikirimi pesan',
                    'Contoh: 6281234567890'
                  ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1501']),callback:function ($$v) {_vm.$set(_vm.formData, '1501', $$v)},expression:"formData['1501']"}}),_c('workflow-input',{key:2,ref:"whatsappInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1502,"placeholder":"Text","variables":_vm.options,"required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Text","details":[
                    'Isi dari pesan yang akan dikirim' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1502']),callback:function ($$v) {_vm.$set(_vm.formData, '1502', $$v)},expression:"formData['1502']"}})]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }