<template>
  <div>
    <v-card>
      <v-row class="mx-2 pt-2">
        <v-col>
          <h3>Workflow</h3>
          <span class="d-block text-subtitle-2 mt-1">Buat aksi otomatis dengan Oriens CRM Workflow!</span>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="ms-auto d-flex"
        >
          <v-btn
            color="primary"
            block
            @click="$refs.workflowForm.show()"
          >
            Buat Workflow Baru
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      class="mt-8"
    >
      <v-card-title>
        Daftar Workflow
      </v-card-title>
      <v-overlay
        :value="loadingWorkflowList || loadingDelete"
        absolute
        color="black"
        opacity="0.4"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-overlay>
      <v-divider />

      <template v-if="workflowCount > 0">
        <v-card-text>
          <v-row
            dense
          >
            <v-col
              v-for="workflow in workflowList"
              :key="workflow.id"
              cols="12"
              md="6"
            >
              <workflow-card
                :data="workflow"
                @change="refetchWorkflow"
                @onOpen="editWorkflow"
                @onDelete="loadingDelete = true"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-center">
          <v-pagination
            v-model="pagination.page"
            :length="Math.ceil(workflowCount / pagination.limit)"
            rounded="circle"
            @input="fetchWorkflowListV2({ limit: pagination.limit, offset: (pagination.page - 1) * pagination.limit })"
          />
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text class=" d-flex align-center justify-center ">
          <div class="my-16 text-subtitle-1">
            Belum Ada Workflow
          </div>
        </v-card-text>
      </template>
    </v-card>

    <WorkflowForm
      ref="workflowForm"
      @onSubmitWorkflow="refetchWorkflow"
    />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import useAutomationV2 from '@/composables/useAutomation'
import WorkflowCard from './components/WorkflowCard.vue'
import WorkflowForm from './WorkflowForm.vue'

export default {
  components: {
    WorkflowCard,
    WorkflowForm,
  },
  setup() {
    const {
      workflowList, fetchWorkflowListV2, loadingWorkflowList, workflowCount,
    } = useAutomationV2()

    const pagination = ref({
      page: 1,
      limit: 10,
    })

    const workflowForm = ref(null)
    onMounted(() => {
      fetchWorkflowListV2({ limit: pagination.value.limit, offset: (pagination.value.page - 1) * pagination.value.limit })
    })

    const loadingDelete = ref(false)

    const editWorkflow = id => {
      workflowForm.value.showEdit(id)
    }

    const refetchWorkflow = () => {
      fetchWorkflowListV2({ limit: pagination.value.limit, offset: (pagination.value.page - 1) * pagination.value.limit }).then(() => {
        loadingDelete.value = false
      })
    }

    return {
      workflowList,
      refetchWorkflow,
      editWorkflow,
      workflowForm,
      loadingDelete,
      loadingWorkflowList,
      pagination,
      workflowCount,
      fetchWorkflowListV2,
    }
  },
}
</script>

<style>

</style>
