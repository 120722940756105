<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="taskForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <workflow-task-auto-suggest
            v-model="selectedTask"
            :property-id="1"
            class="mb-4"
            :rules="[required]"
            @input="changeTask"
          />
          <workflow-input
            ref="taskInput"
            v-model="formData['2']"
            :property-id="2"
            placeholder="Judul Task"
            :variables="options"
            :required="true"
            @input="onInput(formData)"
          />
          <workflow-input
            ref="taskInput"
            v-model="formData['3']"
            :property-id="3"
            long
            class="my-4"
            placeholder="Deskripsi Task"
            :variables="options"
            @input="onInput(formData)"
          />
          <!-- <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <span class="mb-2 text-caption d-block">Waktu Mulai</span>
              <DateTimePicker
                v-model="formData.startDate"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="mb-2 text-caption d-block">Waktu Selesai (Deadline)</span>
              <DateTimePicker
                v-model="formData.endDate"
                :rules="[required, validateDeadline(formData.startDate, formData.endDate)]"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <VisibilityPicker
                ref="visibilityPicker"
                v-model="formData.visibility"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="mt-n4"
            >
              <span class="mb-2 mt-4 text-caption d-block">Ditugaskan ke</span>
              <UserTeamAutoSuggest
                v-model="formData.assign"
                label="Ditugaskan ke"
                show-teams
                multiple
                :visibility-id="formData.visibility"
              />
            </v-col>
          </v-row> -->
          <!-- <span class="mb-2 mt-4 text-caption d-block">Tag Task</span>
          <TaskTag
            v-model="formData.tags"
          /> -->
        </v-col>
        <!-- <v-col
          cols="12"
          md="12"
        >
          <v-card
            outlined
            class="pa-3 mb-4 d-flex flex-column"
          >
            <span class="text-subtitle-2 font-medium mb-3">Sambungkan Dengan Objek</span>
            <CustomerAutoSuggest
              v-model="formData.customer"
              label="Cari pelanggan"
              class="mb-3"
            />
            <JobAutoSuggest
              v-model="formData.job"
              class="mb-2"
              label="Cari job"
              :filter-customer="formData.customer"
              :disabled="!formData.customer"
            />
            <ProspectAutoSuggest
              v-model="formData.prospect"
              class="mb-2"
              label="Cari prospek"
              :filter-customer="formData.customer"
              :disabled="!formData.customer"
            />
          </v-card>
        </v-col> -->
      </v-row>
    </v-form>
  </div>
</template>

<script>
import WorkflowInput from '../../components/WorkflowInput.vue'
import { computed, ref } from '@vue/composition-api'
// import { mdiClose } from '@mdi/js'
import { required, validateDeadline } from '@core/utils/validation'
// import TaskTag from '@/components/inputs/TagTask.vue'
import { isNumber } from '@core/utils/filter'
import WorkflowTaskAutoSuggest from '@/components/inputs/workflow/WorkflowTaskAutoSuggest.vue'
// import JobAutoSuggest from '@/components/inputs/JobAutoSuggest.vue'
// import ProspectAutoSuggest from '@/components/inputs/ProspectAutoSuggest.vue'
// import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
// import VisibilityPicker from '@/components/inputs/VisibilityPicker.vue'
// import UserTeamAutoSuggest from '@/components/inputs/UserTeamAutoSuggest.vue'

export default {
  components: {
    WorkflowInput,
    WorkflowTaskAutoSuggest,
    // TaskTag,
    // CustomerAutoSuggest,
    // JobAutoSuggest,
    // ProspectAutoSuggest,
    // DateTimePicker,
    // VisibilityPicker,
    // UserTeamAutoSuggest,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formData = ref({})
    const taskInput = ref(null)

    const selectedTask = ref(null)

    const propsVal = computed({
      set: value => {
        const result = {}
        value.forEach(el => {
          // props untuk workflowInput
          if (el.property_id === 1) selectedTask.value = +el.value

          if (!result[el.property_id]) result[el.property_id] = []
          result[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
        })
        formData.value = result

        return result
      },
    })
    propsVal.value = props.props

    const taskForm = ref(null)
    const onInput = data => {
      const result = []
      console.log(data)
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      if (!taskForm.value.validate()) isValid = false
      for (let i = 0; i < taskInput.value.length; i += 1) {
        if (!taskInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    const changeTask = val => {
      formData.value['1'] = {
        parent_id: null,
        property_id: 1,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    return {
      // WorkflowInput,
      required,
      formData,
      validateDeadline,
      onInput,
      taskForm,
      validate,
      taskInput,

      selectedTask,
      changeTask,
    }
  },
}
</script>
