<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="boardForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <trello-account-select
            ref="trelloUserSelect"
            v-model="selectedToken"
            :loading="loadingUserTrello"
            :items="trelloUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Trello"
                :details="[
                  'Akun Trello yang akan digunakan untuk membuat board',
                  'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </trello-account-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <workflow-input
                :key="1"
                ref="boardInput"
                v-model="formData['913']"
                :property-id="913"
                class="my-4"
                placeholder="Nama Board"
                :variables="options"
                :required="true"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Nama Board"
                    :details="[
                      'Nama dari Board yang akan dibuat',
                    ]"
                  />
                </template>
              </workflow-input>
              <workflow-input
                :key="2"
                ref="boardInput"
                v-model="formData['914']"
                :property-id="914"
                class="my-4"
                long
                placeholder="Deskripsi Board"
                :variables="options"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Deskripsi Board"
                    :details="[
                      'Deskripsi dari Board yang akan dibuat',
                    ]"
                  />
                </template>
              </workflow-input>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import WorkflowInput from '../../components/WorkflowInput.vue'
import { onMounted, ref } from '@vue/composition-api'
import useTrello from '@/composables/useTrello'
import { required } from '@core/utils/validation'
import { isNumber } from '@core/utils/filter'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'
import TrelloAccountSelect from '@/views/workflow/components/selects/TrelloAccountSelect.vue'

export default {
  components: {
    WorkflowInput,
    WorkflowFieldInfo,
    TrelloAccountSelect,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const boardForm = ref(null)
    const boardInput = ref(null)
    const selectedToken = ref(null)
    const formData = ref({})

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput

        if (!formData.value[el.property_id]) formData.value[el.property_id] = []
        formData.value[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
    }

    const {
      fetchUserTrello, loadingUserTrello, trelloUserList,
    } = useTrello()

    onMounted(() => {
      fetchUserTrello().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          loadFormEdit(props.props)
          selectedToken.value = trelloUserList.value.find(el => +el.id === props.token.id)
        }
      })
    })

    const validate = () => {
      let isValid = true
      console.log(boardInput.value)
      if (!boardForm.value.validate()) isValid = false
      for (let i = 0; i < boardInput.value.length; i += 1) {
        if (!boardInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    return {
      boardForm,
      boardInput,
      selectedToken,
      formData,
      onInput,
      changeToken,
      loadingUserTrello,
      trelloUserList,
      validate,
      required,
    }
  },
}
</script>
