<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="calendarForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <google-account-select
            ref="googleUserSelect"
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang kalender-nya akan diawasi untuk mengecek event trigger',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <div
            :key="0"
            class="d-flex align-center"
          >
            <v-select
              v-model="selectedCalendar"
              :items="calendarList"
              item-value="id"
              item-text="summary"
              flat
              outlined
              attach
              dense
              :disabled="!selectedToken"
              :loading="loadingCalendarList"
              label="Kalender"
              class="mt-4"
              hide-details="auto"
              placeholder="Pilih Kalender"
              :rules="[required]"
              @input="changeCalendar"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.summary }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Kalender"
              :details="[
                'Kalender yang akan diawasi untuk mengecek event trigger',
              ]"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import useGoogle from '@/composables/useGoogle'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import { required } from '@core/utils/validation'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    GoogleAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    webhookIdentifier: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const {
      fetchUserGoogle, loadingUserGoogle, googleUserList,
      fetchCalendarList, loadingCalendarList, calendarList,
    } = useGoogle()
    const calendarForm = ref(null)
    const selectedToken = ref(null)
    const selectedCalendar = ref(null)
    const googleUserSelect = ref(null)

    onMounted(() => {
      fetchUserGoogle().then(() => {
        console.log(props.webhookIdentifier)
        if (props.value) {
          selectedToken.value = props.value
          fetchCalendarList(props.value.refresh_token).then(() => {
            selectedCalendar.value = calendarList.value.find(x => x.id === props.webhookIdentifier.calendar_id)
          })
        }
      })
    })

    const changeToken = val => {
      emit('input', val)
      fetchCalendarList(val.refresh_token)
    }

    const changeCalendar = val => {
      emit('updateWebhook', { calendar_id: val })
    }

    const validate = () => {
      let isValid = true
      if (!calendarForm.value.validate()) isValid = false
      if (!googleUserSelect.value.validate()) isValid = false

      return isValid
    }

    return {
      selectedToken,
      selectedCalendar,
      calendarList,
      loadingCalendarList,
      googleUserSelect,
      loadingUserGoogle,
      googleUserList,
      changeToken,
      changeCalendar,

      calendarForm,
      required,
      validate,
    }
  },
}
</script>
