<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="calendarForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <google-account-select
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang akan digunakan untuk membuat kalender',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <workflow-input
                :key="1"
                ref="calendarInput"
                v-model="formData['1001']"
                :property-id="1001"
                placeholder="Nama Kalender"
                :variables="options"
                required
                class="my-4"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Nama kalender"
                    :details="[
                      'Nama dari kalender yang akan dibuat',
                    ]"
                  />
                </template>
              </workflow-input>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useGoogle from '@/composables/useGoogle'
import { onMounted, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import WorkflowInput from '../../components/WorkflowInput.vue'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import { isNumber } from '@/@core/utils/filter'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    WorkflowInput,
    GoogleAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { googleUserList, loadingUserGoogle, fetchUserGoogle } = useGoogle()
    const selectedToken = ref(null)
    const calendarInput = ref(null)
    const calendarForm = ref(null)
    const formData = ref({})

    const loadFormEdit = data => {
      console.log(props.props)
      data.forEach(el => {
        // props untuk workflowInput

        const formDataIndex = el.identifier ? `${el.property_id}-${el.identifier}` : el.property_id
        if (!formData.value[formDataIndex]) formData.value[formDataIndex] = []
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = googleUserList.value.find(el => el.id === props.token.id)
          loadFormEdit(props.props)
        }
      })
    })

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      console.log(calendarInput.value)
      if (!calendarForm.value.validate()) isValid = false
      for (let i = 0; i < calendarInput.value.length; i += 1) {
        if (!calendarInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    return {
      required,
      selectedToken,
      calendarInput,
      calendarForm,

      formData,
      googleUserList,
      loadingUserGoogle,

      changeToken,
      onInput,
      validate,
    }
  },
}
</script>
