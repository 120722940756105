import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import { customers, customerDetail as customerDetailQuery } from '@/graphql/queries'
import { archiveCustomer, connectProfileToCustomer } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'

const useCustomer = ({
  pagination, filter, search,
} = {}) => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const customerList = ref([])
  const customerCount = ref(0)
  const customerFilter = ref({
    pagination: {
      limit: 20,
      offset: 0,
    },
    filter: {
      is_archive: false,
      sort: {
        label: 'Nama Pelanggan A-Z',
        field: 'name',
        type: 'ASC',
      },
    },
  })
  const customerSortOptions = ref([
    {
      label: 'Nama Pelanggan A-Z',
      field: 'name',
      type: 'ASC',
    },
    {
      label: 'Nama Pelanggan Z-A',
      field: 'name',
      type: 'DESC',
    },
    {
      label: 'Aktivitas Terbaru',
      field: 'last_activity_date',
      type: 'ASC',
    },
    {
      label: 'Aktivitas Terlama',
      field: 'last_activity_date',
      type: 'DESC',
    },
  ])
  const customerDetail = ref({})
  const loadingCustomer = ref(false)
  const loadingCustomerPagination = ref(false)
  const loadingCustomerDetail = ref(false)

  const pushCustomers = list => {
    const filteredList = customerList.value.filter(el => !list.some(c => c.id === el.id))

    customerList.value = [...filteredList, ...list]
  }

  const fetchCustomers = async ({ fetchMore = false } = {}) => {
    loadingCustomer.value = true
    const { field, type } = customerFilter.value.filter.sort
    await apolloClient.query({
      query: customers,
      fetchPolicy: 'no-cache',
      variables: {
        pagination: pagination && !fetchMore ? pagination.value : customerFilter.value.pagination,
        filter: filter ? filter.value : {
          ...customerFilter.value.filter,
          sort: { sort: field, order: type },
        },
        search: search ? search.value : customerFilter.value.search,
        workspace_id: workspaceId,
      },
    }).then(result => {
      loadingCustomer.value = false

      customerCount.value = result.data.customers.count

      if (fetchMore) {
        pushCustomers(result.data.customers.customers)
      } else {
        customerList.value = result.data.customers.customers
      }
    }).catch(err => {
      loadingCustomer.value = false

      errorHandling(err)
    })
  }

  const fetchCustomerAutoSuggest = async ({ fetchMore = false } = {}) => {
    loadingCustomer.value = true
    const { field, type } = customerFilter.value.filter.sort
    await apolloClient.query({
      query: customers,
      fetchPolicy: 'no-cache',
      variables: {
        pagination: pagination && !fetchMore ? pagination : customerFilter.value.pagination,
        filter: filter ? filter.value : {
          ...customerFilter.value.filter,
          sort: { sort: field, order: type },
        },
        search: search ? search.value : customerFilter.value.search,
        workspace_id: workspaceId,
      },
    }).then(result => {
      loadingCustomer.value = false

      customerCount.value = result.data.customers.count

      if (fetchMore) {
        pushCustomers(result.data.customers.customers)
      } else {
        customerList.value = result.data.customers.customers
      }
    }).catch(err => {
      loadingCustomer.value = false

      errorHandling(err)
    })
  }

  const listenScrollFetchCustomers = () => useDebounceFn(async data => {
    if (
      (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 400)
      && customerFilter.value.pagination.offset <= customerCount.value
    ) {
      customerFilter.value.pagination.offset += 20
      loadingCustomerPagination.value = true
      await fetchCustomers({ fetchMore: true })
      loadingCustomerPagination.value = false
    }
  }, 200)

  const debouncedFetchCustomers = useDebounceFn(() => {
    fetchCustomers()
  }, 1000)

  const deleteCustomer = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus pelanggan?',
      body: 'Konfirmasi jika anda ingin menghapus pelanggan.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: archiveCustomer,
          variables: {
            id,
            is_archive: true,
            workspace_id: workspaceId,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil menghapus pelanggan!',
          })
          resolve(result)
        }).catch(err => {
          errorHandling(err, 'Hapus Pelanggan')
        })
      } else {
        reject()
      }
    })
  })

  const getCustomerDetail = id => {
    loadingCustomerDetail.value = true

    apolloClient.query({
      query: customerDetailQuery,
      variables: {
        customer_id: id,
        workspace_id: workspaceId,
      },
    }).then(result => {
      customerDetail.value = result.data.customerDetail
      loadingCustomerDetail.value = false
    }).catch(err => {
      loadingCustomerDetail.value = false
      errorHandling(err)
    })
  }

  const connectProfileCustomer = (custId, profileId) => new Promise((resolve, reject) => {
    apolloClient.mutate({
      mutation: connectProfileToCustomer,
      variables: {
        customer_id: custId,
        profile_id: profileId,
        workspace_id: workspaceId,
      },
    }).then(data => resolve(data))
      .catch(err => {
        reject(err)
        errorHandling(err)
      })
  })

  return {
    customerList,
    customerCount,
    customerFilter,
    customerSortOptions,
    customerDetail,
    loadingCustomer,
    loadingCustomerPagination,
    loadingCustomerDetail,
    connectProfileCustomer,
    fetchCustomers,
    deleteCustomer,
    listenScrollFetchCustomers,
    debouncedFetchCustomers,
    getCustomerDetail,
    fetchCustomerAutoSuggest,
  }
}

export default useCustomer
