import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"driveForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{ref:"googleUserSelect",attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang file-nya akan diawasi untuk mengecek event trigger',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}])})]},proxy:true}]),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),(_vm.selectedToken)?_c('div',{key:0,staticClass:"d-flex align-center"},[_c(VAutocomplete,{staticClass:"mt-4",attrs:{"items":_vm.driveFolderList,"item-text":"name","item-value":"id","flat":"","attach":"","outlined":"","dense":"","loading":_vm.loadingListFolder,"label":"Folder","hide-details":"auto","placeholder":"Pilih Folder","no-filter":"","rules":[_vm.required]},on:{"update:search-input":function (val) { return _vm.debouncedFetchFolder(_vm.selectedToken.refresh_token, val); },"input":_vm.changeFolder},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersectFolder),expression:"onIntersectFolder"}]})]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"#fef"}},[_c(VImg,{attrs:{"src":item.iconLink,"max-height":"24px","max-width":"24px"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,false,3919543923),model:{value:(_vm.selectedParent),callback:function ($$v) {_vm.selectedParent=$$v},expression:"selectedParent"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Parent Folder","details":[
              'Folder yang digunakan untuk memilih file yang akan diawasi' ]}})],1):_vm._e(),(_vm.selectedParent)?_c('div',{staticClass:"d-flex align-center"},[_c(VAutocomplete,{staticClass:"mt-4",attrs:{"items":_vm.driveFileList,"item-text":"name","item-value":"id","flat":"","outlined":"","attach":"","dense":"","rules":[_vm.required],"loading":_vm.loadingListFile,"label":"File","hide-details":"auto","placeholder":"Pilih File","no-filter":""},on:{"update:search-input":function (val) { return _vm.debouncedFetchFile(_vm.selectedToken.refresh_token, val, null, _vm.selectedParent); },"input":_vm.updateFile},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersectFile),expression:"onIntersectFile"}]})]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"#fef"}},[_c(VImg,{attrs:{"src":item.iconLink,"max-height":"24px","max-width":"24px"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,false,381412403),model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"File","details":[
              'File yang akan diawasi' ]}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }