import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({class:("text--disabled ms-2 " + _vm.marginTop),attrs:{"size":"25"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiHelpCircleOutline)+" ")])]}}])},[_c(VCard,{staticClass:"pa-3 d-flex flex-column",attrs:{"max-width":"480"}},[_c('span',{staticClass:"text-h6 mb-3"},[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.computedText),function(line,index){return _c('span',{key:index,staticClass:"text-subtitle-2 mb-1"},[_vm._v(_vm._s(line))])}),(_vm.hasAction)?_c(VDivider,{staticClass:"my-3"}):_vm._e(),_vm._t("actions")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }