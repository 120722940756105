<template>
  <div>
    <v-text-field
      v-if="filter.trigger_property.data_type.name === 'string' || filter.trigger_property.data_type.name === 'array_string'"
      v-model="inputData"
      type="text"
      outlined
      placeholder="Masukkan Value"
      hide-details="true"
      dense
      :rules="rules"
    />
    <v-text-field
      v-if="filter.trigger_property.data_type.name === 'integer' || filter.trigger_property.data_type.name === 'array_int'"
      v-model="inputData"
      type="number"
      outlined
      placeholder="Masukkan Value"
      hide-details="true"
      dense
      :rules="rules"
    />
    <DateTimePicker
      v-if="filter.trigger_property.data_type.name === 'date' || filter.trigger_property.data_type.name === 'array_date'"
      v-model="inputData"
      :rules="rules"
      :details="true"
    />
    <v-select
      v-if="filter.trigger_property.data_type.name === 'boolean' || filter.trigger_property.data_type.name === 'array_boolean'"
      v-model="inputData"
      :rules="rules"
      outlined
      flat
      dense
      appear
      item-text="text"
      item-value="value"
      xyz="fade left-4 duration-4"
      hide-details="true"
      :items="[{id: 1, text: 'True', value: 'true'}, {id: 2, text: 'False', value: 'false'}]"
    />
    <v-select
      v-if="filter.trigger_property.data_type.name === 'enum' || filter.trigger_property.data_type.name === 'array_enum'"
      v-model="inputData"
      :rules="rules"
      outlined
      flat
      dense
      appear
      item-text="name"
      item-value="value"
      xyz="fade left-4 duration-4"
      hide-details="true"
      :items="filter.trigger_property.options"
    />
    <div
      v-if="filter.trigger_property.data_type.name === 'object' && filter.trigger_property.data_type.category_name === 'user'"
      style="max-width: 400px;"
    >
      <user-auto-suggest

        v-model="inputData"
        label="Pilih pengguna"
        :return-object="false"
        :clearable="false"
        :pill="false"
      />
    </div>
  </div>
</template>
<script>
import { required } from '@core/utils/validation'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import { computed, onMounted } from '@vue/composition-api'
import { isNumber } from '@core/utils/filter'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'

export default {
  components: {
    UserAutoSuggest,
    DateTimePicker,
  },
  props: {
    value: {
      type: null,
      default: null,
    },
    filter: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.filter.value
      },
      set(val) {
        emit('input', val)
      },
    })
    onMounted(() => {
      if (isNumber(props.filter.value)) {
        inputData.value = parseFloat(props.filter.value)
      } else {
        inputData.value = props.filter.value
      }
    })

    return {
      required,
      inputData,
    }
  },
}
</script>
