import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"boardForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('trello-account-select',{ref:"trelloUserSelect",refInFor:true,attrs:{"loading":_vm.loadingUserTrello,"items":_vm.trelloUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Trello","details":[
                'Akun Trello yang akan digunakan untuk membuat board',
                'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('workflow-input',{key:1,ref:"boardInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":913,"placeholder":"Nama Board","variables":_vm.options,"required":true},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama Board","details":[
                    'Nama dari Board yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['913']),callback:function ($$v) {_vm.$set(_vm.formData, '913', $$v)},expression:"formData['913']"}}),_c('workflow-input',{key:2,ref:"boardInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":914,"long":"","placeholder":"Deskripsi Board","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Deskripsi Board","details":[
                    'Deskripsi dari Board yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['914']),callback:function ($$v) {_vm.$set(_vm.formData, '914', $$v)},expression:"formData['914']"}})]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }