<template>
  <v-menu
    v-model="isOpen"
    offset-y
    attach
    min-width="360px"
  >
    <template #activator="{ on }">
      <div
        v-on="on"
      >
        <slot />
      </div>
    </template>
    <v-list
      nav
      dense
      max-height="240px"
      width="360px"
      class="workflow-options-window"
    >
      <v-list-item
        v-for="(list, index) in options"
        :key="index"
        :value="list"
        :class="{ 'primary--text' : inputData === list}"
        @click="inputData = list; $emit('click')"
      >
        <div class="d-flex align-center justify-space-between flex-grow-1">
          <span class="text-subtitle-2">
            <span
              v-if="list.trigger_category"
              class="font-weight-bold"
            >
              ({{ list.trigger_category.name_view }})
            </span>
            {{ list.name ? list.name : list.value }}
          </span>
          <v-chip
            v-if="list.trigger_category"
            class="v-chip-light-bg primary--text font-weight-semibold"
            outlined
            small
          >
            {{ list.data_type.name_view }}
          </v-chip>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { computed, ref, onMounted } from '@vue/composition-api'

export default {
  props: {
    value: {
      type: null,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    openInitial: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    onMounted(() => {
      if (props.openInitial) {
        setTimeout(() => {
          show()
        }, 300)
      }
    })

    const validate = () => {
      if (!inputData.value) {
        return false
      }

      return true
    }

    return {
      isOpen,
      inputData,

      show,
      close,
      validate,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.workflow-options-window {
  @include style-scroll-bar();
  overflow: auto !important;
}
</style>
