<template>
  <v-autocomplete
    ref="taskAutoSuggest"
    v-model="inputData"
    placeholder="Cari Task..."
    :label="label"
    outlined
    dense
    :multiple="multiple"
    :loading="loadingTasks"
    :items="options"
    item-value="id"
    item-text="subject"
    hide-details="auto"
    filled
    no-filter
    solo
    flat
    clearable
    chips
    :rules="rules"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
    @click:clear="onClear"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p>
          Task tidak ditemukan.
        </p>
      </div>
    </template>
    <template
      #item="task"
    >
      <div class="d-flex flex-column">
        <span class="text-subtitle-2 my-1">
          {{ task.item.subject }}
        </span>
        <limit-elements
          v-if="task.item.tag && task.item.tag.length > 0"
          :elements="task.item.tag"
          :limit="3"
        >
          <template #default="{ data }">
            <v-chip
              x-small
              :color="data.color"
              class="mr-1"
            >
              {{ data.name }}
            </v-chip>
          </template>
          <template #others-activator="{ data }">
            <v-chip
              x-small
              color="primary"
              class="mr-1 v-chip-light-bg primary--text mb-2"
            >
              +{{ data.limit }}
            </v-chip>
          </template>
        </limit-elements>
        <span
          v-else
        >
          -
        </span>
      </div>
    </template>
    <template #append-item>
      <div
        v-intersect="onIntersect"
        class="text-center"
      >
        <p v-if="loadingTasks">
          Memuat data...
        </p>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useTask from '@/composables/useTask'
import limitElements from '@/components/misc/LimitElements.vue'

import { mdiClose } from '@mdi/js'

export default {
  components: {
    limitElements,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    propertyId: {
      type: Number,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Cari Task...',
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const taskAutoSuggest = ref(null)
    const isEmpty = ref(false)
    const searchQuery = ref('')

    const {
      loadingTasks, taskList,
      fetchTasksAutoSuggest,
      debouncedFetchTasksAutoSuggest,
      listenScrollAutoSuggestFetchMore,
    } = useTask({
      search: searchQuery,
      pagination: { limit: 20, offset: 0 },
    })
    const onIntersect = entry => {
      if (entry[0].isIntersecting) {
        listenScrollAutoSuggestFetchMore()
      }
    }
    const encoded = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const inputData = ref(null)
    watch(inputData, value => {
      encoded.value = value
    })

    const options = computed(() => {
      if (inputData.value) {
        return [
          ...taskList.value,
          inputData.value,
        ]
      }

      return taskList.value
    })

    onMounted(() => {
      fetchTasksAutoSuggest().then(() => {
        console.log(props)
        if (props.value) {
          inputData.value = props.value
        }
      })
    })

    const onClear = () => {
      searchQuery.value = ''
      debouncedFetchTasksAutoSuggest()
    }

    const onSearch = data => {
      searchQuery.value = data
      debouncedFetchTasksAutoSuggest()
    }

    const onIndex = () => {
      isEmpty.value = taskAutoSuggest.value.filteredItems.length === 0
    }

    return {
      taskAutoSuggest,
      options,
      loadingTasks,
      taskList,
      fetchTasksAutoSuggest,
      inputData,
      searchQuery,
      onSearch,
      isEmpty,
      onIndex,
      avatarText,
      limitElements,
      onIntersect,
      onClear,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>
</style>
