<template>
  <v-autocomplete
    ref="customerAutoSuggest"
    v-model="inputData"
    placeholder="Cari Pelanggan..."
    :label="label"
    outlined
    dense
    :multiple="multiple"
    :loading="loadingCustomer"
    :items="options"
    item-value="id"
    item-text="name"
    hide-details="auto"
    filled
    no-filter
    solo
    flat
    clearable
    chips
    :rules="rules"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
    @click:clear="onClear"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p>
          Pelanggan tidak ditemukan.
        </p>
      </div>
    </template>
    <template
      #item="data"
    >
      <div class="d-flex my-2">
        <v-avatar
          size="36"
          class="mr-2 v-avatar-light-bg"
        >
          <span>{{ avatarText(data.item.name) }}</span>
        </v-avatar>
        <div class="d-flex flex-column">
          <span class="text-subtitle-2">
            {{ data.item.name }}
          </span>
          <span class="text-caption">
            {{ data.item.email }}
          </span>
          <span class="text-caption">
            {{ data.item.phone_number }}
          </span>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useCustomer from '@/composables/useCustomer'

import { mdiClose } from '@mdi/js'

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    propertyId: {
      type: Number,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Cari Pelanggan...',
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const customerAutoSuggest = ref(null)
    const isEmpty = ref(false)
    const searchQuery = ref('')

    const {
      loadingCustomer, customerList,
      fetchCustomerAutoSuggest, // debouncedFetchCustomers,
    } = useCustomer({
      search: searchQuery,
      pagination: { limit: 20, offset: 0 },
    })
    const encoded = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const inputData = ref(null)
    watch(inputData, value => {
      encoded.value = value
    })

    const options = computed(() => {
      if (inputData.value) {
        return [
          ...customerList.value,
          inputData.value,
        ]
      }

      return customerList.value
    })

    onMounted(() => {
      fetchCustomerAutoSuggest().then(() => {
        if (props.value) {
          inputData.value = props.value
        }
      })
    })

    const onClear = () => {
      searchQuery.value = ''
      fetchCustomerAutoSuggest()
    }

    const onSearch = data => {
      searchQuery.value = data
      // debouncedFetchCustomers()
    }

    const onIndex = () => {
      isEmpty.value = customerAutoSuggest.value.filteredItems.length === 0
    }

    return {
      customerAutoSuggest,
      options,
      loadingCustomer,
      customerList,
      fetchCustomerAutoSuggest,
      inputData,
      searchQuery,
      onSearch,
      isEmpty,
      onIndex,
      avatarText,
      onClear,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>
</style>
