import { VChip } from 'vuetify/lib/components/VChip';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","attach":"","min-width":"360px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VList,{staticClass:"workflow-options-window",attrs:{"nav":"","dense":"","max-height":"240px","width":"360px"}},_vm._l((_vm.options),function(list,index){return _c(VListItem,{key:index,class:{ 'primary--text' : _vm.inputData === list},attrs:{"value":list},on:{"click":function($event){_vm.inputData = list; _vm.$emit('click')}}},[_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1"},[_c('span',{staticClass:"text-subtitle-2"},[(list.trigger_category)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" ("+_vm._s(list.trigger_category.name_view)+") ")]):_vm._e(),_vm._v(" "+_vm._s(list.name ? list.name : list.value)+" ")]),(list.trigger_category)?_c(VChip,{staticClass:"v-chip-light-bg primary--text font-weight-semibold",attrs:{"outlined":"","small":""}},[_vm._v(" "+_vm._s(list.data_type.name_view)+" ")]):_vm._e()],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }