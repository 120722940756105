<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="tokopediaForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <tokopedia-account-select
            ref="tokopediaUserSelect"
            v-model="selectedToken"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Tokopedia"
                :details="[
                  'Akun Seller Tokopedia yang akan diawasi untuk mengecek event trigger',
                  'Jika akun Seller Tokopedia belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </tokopedia-account-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import TokopediaAccountSelect from './components/TokopediaAccountSelect.vue'
import { onMounted, ref } from '@vue/composition-api'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    TokopediaAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const changeToken = val => {
      emit('input', val)
    }

    const tokopediaForm = ref(null)
    const tokopediaUserSelect = ref(null)
    const selectedToken = ref(null)
    const validate = () => {
      let isValid = true
      if (!tokopediaForm.value.validate()) isValid = false

      return isValid
    }

    onMounted(() => {
      if (props.value) {
        selectedToken.value = props.value
      }
    })

    return {
      changeToken,
      validate,

      tokopediaForm,
      tokopediaUserSelect,
      selectedToken,
    }
  },
}
</script>
