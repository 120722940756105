<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="productForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <workflow-input
            ref="productInput"
            v-model="formData.name"
            :property-id="36"
            placeholder="Nama Produk"
            :variables="options"
            required
            @input="onInput(formData)"
          />
          <workflow-input
            ref="productInput"
            v-model="formData.description"
            :property-id="37"
            placeholder="Deskripsi Produk"
            :variables="options"
            long
            required
            class="my-4"
            @input="onInput(formData)"
          />
          <v-text-field
            v-model="price"
            :property-id="39"
            label="Harga produk"
            placeholder="Input harga produk"
            outlined
            hide-details="auto"
            dense
            class="mb-4"
            type="number"
            :rules="[required, positiveInteger]"
          />
          <v-text-field
            v-model="stock"
            :property-id="41"
            label="Stok produk"
            placeholder="Input stok produk"
            outlined
            hide-details="auto"
            dense
            class="mb-4"
            type="number"
            :rules="[required, positiveInteger]"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import WorkflowInput from '../../components/WorkflowInput.vue'
import { required, positiveInteger } from '@core/utils/validation'

export default {
  components: {
    WorkflowInput,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const price = ref(null)
    const stock = ref(null)
    const productInput = ref(null)
    const productForm = ref(null)
    const formData = ref({
      // id: null,
      name: [],
      description: [],
      price: null,
      stock: null,
      // startDate: '',
      // endDate: '',
      // job: null,
      // customer: null,
      // visibility: null,
      // tags: [],
      // assign: [],
    })

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      console.log(productInput.value)
      if (!productForm.value.validate()) isValid = false
      for (let i = 0; i < productInput.value.length; i += 1) {
        if (!productInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    watch(price, val => {
      formData.value.price = {
        parent_id: null,
        property_id: 39,
        sort: 0,
        value: val.toString(),
      }
      onInput(formData.value)
    })

    watch(stock, val => {
      formData.value.stock = {
        parent_id: null,
        property_id: 41,
        sort: 0,
        value: val.toString(),
      }
      onInput(formData.value)
    })

    return {
      formData,
      onInput,
      required,
      price,
      stock,
      positiveInteger,
      validate,
      productInput,
      productForm,
    }
  },
}
</script>
