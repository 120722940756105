<template>
  <v-form
    ref="telegramForm"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="d-flex align-center">
      <v-select
        v-model="inputData"
        outlined
        flat
        dense
        attach
        :items="telegramBotList"
        item-text="connection_name"
        item-value="id"
        label="Pilih Akun"
        :loading="loadingTelegramBotList"
        :rules="[required]"
        :disabled="loadingTelegramBotList"
        class="mt-4"
        hide-details="auto"
        placeholder="Pilih BOT"
        return-object
      >
        <template #item="{ item }">
          <v-list-item-avatar>
            <v-avatar
              size="36"
              class="mr-2 v-avatar-light-bg"
            >
              <span>{{ avatarText(item.connection_name) }}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.connection_name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-select>
      <slot name="info" />
    </div>
  </v-form>
</template>
<script>
import { computed, onMounted } from '@vue/composition-api'
import useTelegram from '@/composables/useTelegram'
import { required } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const { fetchTelegramBotList, loadingTelegramBotList, telegramBotList } = useTelegram()

    onMounted(() => {
      fetchTelegramBotList()
    })

    const changeAccount = val => {
      emit('input', val)
    }

    return {
      telegramBotList,
      loadingTelegramBotList,
      changeAccount,

      required,
      inputData,

      avatarText,
    }
  },
}
</script>
