<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="jobForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <workflow-job-auto-suggest
            v-model="selectedJob"
            :property-id="101"
            class="mb-4"
            :rules="[required]"
            @input="changeJob"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { isNumber } from '@core/utils/filter'
import WorkflowJobAutoSuggest from '@/components/inputs/workflow/WorkflowJobAutoSuggest.vue'

export default {
  components: {
    WorkflowJobAutoSuggest,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formData = ref({})
    const jobForm = ref(null)
    const selectedJob = ref(null)

    const propsVal = computed({
      set: value => {
        const result = {}
        console.log('computed')
        value.forEach(el => {
          console.log(el)
          // props untuk workflowInput
          if (el.property_id === 101) selectedJob.value = +el.value

          if (!result[el.property_id]) result[el.property_id] = []
          result[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
        })
        formData.value = result

        return result
      },
    })
    propsVal.value = props.props

    const onInput = data => {
      const result = []
      console.log(data)
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      if (!jobForm.value.validate()) isValid = false

      return isValid
    }

    const changeJob = val => {
      formData.value['101'] = {
        parent_id: null,
        property_id: 101,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    return {
      required,
      jobForm,
      formData,
      validate,
      onInput,

      selectedJob,
      changeJob,
    }
  },
}
</script>
