<template>
  <div>
    <div class="workflow-input-outer-container">
      <div
        :class="resolveContainerClass"
        :style="`${!isValid ? 'margin-bottom: 2px' : ''}`"
      >
        <v-expand-transition>
          <v-card
            v-if="showVariableBox"
            outlined
            class="variable-box d-flex"
            elevation="4"
            :style="`margin-top: ${long ? '168px' : '48px'}`"
          >
            <v-list
              v-if="filteredVar.length"
              dense
              flat
            >
              <v-list-item
                v-for="(val, i) in filteredVar"
                :key="i"
                class="d-flex"
                @click="addVariable(val)"
              >
                <div class="d-flex align-center justify-space-between flex-grow-1">
                  <div class="font-weight-bold">
                    <span>({{ val.trigger_category.name_view }})</span><span class="font-weight-semibold"> {{ val.name }}</span>
                  </div>
                  <v-chip
                    class="v-chip-light-bg primary--text font-weight-semibold ms-10"
                    outlined
                  >
                    {{ val.data_type.name_view }}
                  </v-chip>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-expand-transition>

        <div
          class="pa-1 workflow-input-div"
        >
          <div
            v-if="isOpen"
            id="inputBox"
            ref="inputBox"
            :contenteditable="!disabled"
            :class="`${long ? 'pa-1 workflow-input workflow-input-textarea' : 'pa-1 workflow-input workflow-input-single'} ${isActive}`"
            @focus="onFocus()"
            @blur="onBlur()"
            @input="changeInput()"
            @keydown="onKeyDown($event)"
            @paste="onPaste($event)"
          />

          <label
            class="workflow-input-label"
          >
            {{ placeholder }}
          </label>
          <slot name="info" />
        </div>
      </div>
      <v-slide-x-reverse-transition>
        <div
          :class="`'v-text-field__details'${isValid ? '--hidden' : ''}`"
        >
          <div
            v-if="!isValid"
            class="error--text ps-3 v-messages theme--light"
          >
            {{ errorMessage }}
          </div>
        </div>
      </v-slide-x-reverse-transition>
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, computed,
} from '@vue/composition-api'
import { ellipsis } from '@core/utils/filter'
import {
  // eslint-disable-next-line no-unused-vars
  pasteHtmlAtCaretWorkflow, placeCaretAtEnd,
} from '@/utils/caretHelper'
import useWorkflowInputBox from '@/components/misc/WorkflowInputBox/useWorkflowInputBox'
import useVuetify from '@core/utils/vuetify'
import { useDebounceFn } from '@vueuse/core'

export default {
  components: {
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    long: {
      type: Boolean,
      default: false,
    },
    propertyId: {
      type: Number,
      required: true,
    },
    placeholder: {
      type: String,
      default: '...',
    },
    variables: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { rootThemeClasses } = useVuetify()
    const isValid = ref(true)
    const isActive = ref(false)
    const errorMessage = ref('')
    const showVariableBox = ref(false)
    const inputBox = ref(null)
    const loadingSubmit = ref(false)
    const value = ref([])
    const isOpen = ref(true)
    const mentionPos = ref(0)
    const filteredVar = ref([])
    const { encodeVariable } = useWorkflowInputBox()

    const onPaste = e => {
      e.stopPropagation()
      e.preventDefault()
      // pasteHtmlAtCaretWorkflow(e.clipboardData.getData('text/plain'))
    }

    const resolveContainerClass = computed(() => {
      const containerClass = rootThemeClasses.value
      containerClass['workflow-input-container'] = true
      containerClass['error-input'] = !isValid.value

      return containerClass
    })

    const changeInput = useDebounceFn(() => {
      if (inputBox.value.innerHTML) {
        isActive.value = 'workflow-input-active'
      } else {
        isActive.value = ''
      }
      value.value = encodeVariable(inputBox.value.innerHTML, props.propertyId, props.identifier)
      if (props.required) {
        if (value.value.length) {
          isValid.value = true
          errorMessage.value = ''
        }
      }
      emit('input', value.value)
    }, 100)

    // eslint-disable-next-line arrow-body-style
    const varBox = data => {
      return `&#x200B<div class="workflow-variable" contenteditable="false" data-id="${data.id}">&#x200B<span contenteditable="false" class="var-text">${data.name}</span></div>`
    }

    const addVariable = data => {
      console.log('addVar:', data)
      pasteHtmlAtCaretWorkflow(varBox(data))
      changeInput()
    }

    const loadVariable = data => {
      console.log('loadVar:', data)
      inputBox.value.innerHTML += varBox(data)
      changeInput()
    }

    const isAtLastLine = range => {
      // Create a new range to deal with text before the cursor
      const preRange = document.createRange()
      // Have this range select the entire contents of the editable div
      preRange.selectNodeContents(inputBox.value)
      // Set the end point of this range to the start point of the cursor
      preRange.setEnd(range.startContainer, range.startOffset)
      // Fetch the contents of this range (text before the cursor)
      const thisText = preRange.cloneContents()
      // If the text's length is 0, we're at the start of the div.
      // const atStart = thisText.textContent.length === 0
      // Rinse and repeat for text after the cursor to determine if we're at the end.
      const postRange = document.createRange()
      postRange.selectNodeContents(inputBox.value)
      postRange.setStart(range.endContainer, range.endOffset)
      const nextText = postRange.cloneContents()
      console.log(`'${thisText.textContent[thisText.textContent.length - 1]}'`)
      // console.log(`'${nextText.textContent}'`)
      // console.log(thisText.textContent.length)
      // console.log(nextText.textContent.length)
      const atEnd = nextText.textContent.length === 0 && thisText.textContent[thisText.textContent.length - 1] !== '\n'
      console.log(atEnd)

      return atEnd
    }

    const onKeyDown = e => {
      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        if (props.long) {
          if (getSelection().modify) { /* chrome */
            const selection = window.getSelection()
            const range = selection.getRangeAt(0)
            range.deleteContents()
            const newline = isAtLastLine(range) ? document.createTextNode('\n\n') : document.createTextNode('\n')
            range.insertNode(newline)
            range.setStartAfter(newline)
            range.setEndAfter(newline)
            range.collapse(false)
            selection.removeAllRanges()
            selection.addRange(range) /* end chrome */
          } else {
            document.createTextNode('\n') /* internet explorer */
            const range = getSelection().getRangeAt(0)
            const newline = isAtLastLine(range) ? document.createTextNode('\n\n') : document.createTextNode('\n')
            range.surroundContents(newline)
            range.selectNode(newline.nextSibling) /* end Internet Explorer 11 */
          }
        }
      } else if (e.key === 'Backspace') {
        console.log(e)
        console.log(e.target)
        if (getSelection().modify) {
          const selection = window.getSelection()
          const range = selection.getRangeAt(0)
          const preRange = document.createRange()
          preRange.selectNodeContents(inputBox.value)
          preRange.setEnd(range.startContainer, range.startOffset)
          const thisText = preRange.cloneContents()
          console.log(thisText)
        } else {
          const range = getSelection().getRangeAt(0)
          const preRange = document.createRange()
          preRange.selectNodeContents(inputBox.value)
          preRange.setEnd(range.startContainer, range.startOffset)
          const thisText = preRange.cloneContents()
          console.log(thisText)
        }
      }
      // if (e.key === 'Backspace') {
      //   console.log(e)
      //   console.log(e.target)
      //   if (getSelection().modify) {
      //     const selection = window.getSelection()
      //     const range = selection.getRangeAt(0)
      //     console.log(range)
      //   }
      // }
      changeInput()
    }

    const onFocus = () => {
      setTimeout(() => {
        showVariableBox.value = true
      }, 150)
    }
    const onBlur = () => {
      if (props.required && !value.value.length) {
        isValid.value = false
        errorMessage.value = 'Data ini wajib diisi.'
      }
      setTimeout(() => {
        showVariableBox.value = false
      }, 150)
    }

    const filterVariable = type => {
      if (type === 'date') return props.variables.filter(v => v.data_type.name === 'date')
      if (type === 'string') return props.variables.filter(v => v.data_type.name === 'string' || v.data_type.name === 'array_string')

      return props.variables
    }

    onMounted(() => {
      if (props.toggleable) {
        isOpen.value = false
      }
      if (props.value) {
        props.value.forEach(prop => {
          if (prop.parent_id) {
            loadVariable(props.variables.find(v => v.id === prop.parent_id))
          } else {
            inputBox.value.innerHTML += prop.value
          }
        })
        changeInput()
      }
      filteredVar.value = filterVariable(props.type)
    })

    const validate = () => {
      if (props.required) {
        if (value.value.length === 0) {
          errorMessage.value = 'Data ini wajib diisi!'
          isValid.value = false

          return false
        }
        errorMessage.value = ''
        isValid.value = true

        return true
      }

      return true
    }

    const isEmpty = () => value.value.length === 0

    return {
      loadingSubmit,
      inputBox,
      showVariableBox,
      addVariable,
      mentionPos,
      isOpen,
      onFocus,
      onBlur,
      ellipsis,
      changeInput,
      errorMessage,
      validate,
      isValid,
      rootThemeClasses,
      isActive,
      resolveContainerClass,
      isEmpty,
      onKeyDown,
      onPaste,
      filteredVar,
    }
  },
}
</script>

<style lang="scss">
@import './style.scss';
</style>

<style lang="scss" scoped>
@import '@/styles/pulse.scss';
@include pulseAnimation("red", 8px, #ff4c51);
</style>
