import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VRow,{staticClass:"mx-2 pt-2"},[_c(VCol,[_c('h3',[_vm._v("Workflow")]),_c('span',{staticClass:"d-block text-subtitle-2 mt-1"},[_vm._v("Buat aksi otomatis dengan Oriens CRM Workflow!")])]),_c(VCol,{staticClass:"ms-auto d-flex",attrs:{"cols":"12","md":"3"}},[_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.$refs.workflowForm.show()}}},[_vm._v(" Buat Workflow Baru ")])],1)],1)],1),_c(VCard,{staticClass:"mt-8"},[_c(VCardTitle,[_vm._v(" Daftar Workflow ")]),_c(VOverlay,{attrs:{"value":_vm.loadingWorkflowList || _vm.loadingDelete,"absolute":"","color":"black","opacity":"0.4"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1),_c(VDivider),(_vm.workflowCount > 0)?[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.workflowList),function(workflow){return _c(VCol,{key:workflow.id,attrs:{"cols":"12","md":"6"}},[_c('workflow-card',{attrs:{"data":workflow},on:{"change":_vm.refetchWorkflow,"onOpen":_vm.editWorkflow,"onDelete":function($event){_vm.loadingDelete = true}}})],1)}),1)],1),_c(VCardActions,{staticClass:"d-flex align-center justify-center"},[_c(VPagination,{attrs:{"length":Math.ceil(_vm.workflowCount / _vm.pagination.limit),"rounded":"circle"},on:{"input":function($event){_vm.fetchWorkflowListV2({ limit: _vm.pagination.limit, offset: (_vm.pagination.page - 1) * _vm.pagination.limit })}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)]:[_c(VCardText,{staticClass:" d-flex align-center justify-center "},[_c('div',{staticClass:"my-16 text-subtitle-1"},[_vm._v(" Belum Ada Workflow ")])])]],2),_c('WorkflowForm',{ref:"workflowForm",on:{"onSubmitWorkflow":_vm.refetchWorkflow}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }