import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"productAutoSuggest",attrs:{"placeholder":"Cari produk...","label":_vm.label,"outlined":"","dense":"","multiple":_vm.multiple,"loading":_vm.loading,"items":_vm.options,"item-value":"id","item-text":"name","hide-details":"auto","filled":"","no-filter":"","solo":"","flat":"","chips":"","clearable":"","rules":_vm.rules},on:{"update:search-input":_vm.onSearch,"update:list-index":_vm.onIndex,"click:clear":_vm.onClear},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-subtitle-2 text--disabled text-center"},[_c('p',[_vm._v(" Produk tidak ditemukan. ")])])]},proxy:true},{key:"item",fn:function(data){return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[_c(VImg,{attrs:{"src":data.item.photo ? data.item.photo.length > 0 ? data.item.photo[0].photo : 'default.png' : 'default.png'}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption"},[_vm._v(" "+_vm._s(data.item.code_sku ? data.item.code_sku : "Tidak ada SKU")+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(data.item.price)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" Stok "+_vm._s(data.item.stock)+" ")])],1)]}}]),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})}
var staticRenderFns = []

export { render, staticRenderFns }