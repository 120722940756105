<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="jobForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <workflow-job-auto-suggest
            v-model="selectedJob"
            :property-id="101"
            class="mb-4"
            :rules="[required]"
            @input="changeJob"
          />
          <workflow-input
            ref="jobInput"
            v-model="formData['3']"
            :property-id="3"
            long
            class="my-4"
            placeholder="Deskripsi Job"
            :variables="options"
            @input="onInput(formData)"
          />
          <workflow-customer-auto-suggest
            v-model="selectedCustomer"
            :property-id="108"
            class="mb-4"
            :rules="[required]"
            @input="changeCustomer"
          />
          <v-select
            v-model="selectedPriority"
            :items="priorityList"
            :property-id="107"
            item-text="name"
            item-value="id"
            label="Prioritas"
            outlined
            hide-details="auto"
            dense
            class="mb-4"
            :rules="[required]"
            @change="changePriority"
          >
            <template #item="{ item }">
              <v-chip
                small
                :color="priorityColor(item.id)"
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-select>
          <v-select
            v-model="selectedJobType"
            :items="jobTypeList"
            item-text="name"
            item-value="id"
            :property-id="105"
            label="Pipeline job"
            outlined
            dense
            hide-details="auto"
            class="mb-4"
            :rules="[required]"
            @change="changeJobType"
          >
            <template #item="{ item }">
              <v-chip
                small
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-select>
          <v-select
            v-model="selectedJobStatus"
            :items="jobStatusList"
            :property-id="106"
            item-text="name"
            item-value="id"
            label="Status job"
            outlined
            dense
            :disabled="!selectedJobType"
            hide-details="auto"
            class="mb-4"
            :rules="[required]"
            @change="changeJobStatus"
          >
            <template #item="{ item }">
              <v-chip
                small
                :color="item.color"
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import WorkflowInput from '../../components/WorkflowInput.vue'
import {
  ref, computed, onMounted,
} from '@vue/composition-api'
import { required, validateDeadline } from '@core/utils/validation'
import WorkflowCustomerAutoSuggest from '@/components/inputs/workflow/WorkflowCustomerAutoSuggest.vue'
import WorkflowJobAutoSuggest from '@/components/inputs/workflow/WorkflowJobAutoSuggest.vue'
import store from '@/store'
import useJob from '@/composables/useJob'
import { isNumber } from '@core/utils/filter'

export default {
  components: {
    WorkflowInput,
    WorkflowCustomerAutoSuggest,
    WorkflowJobAutoSuggest,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formData = ref({})

    const selectedJob = ref(null)
    const selectedPriority = ref(null)
    const selectedJobType = ref(null)
    const selectedJobStatus = ref(null)
    const selectedCustomer = ref(null)

    const jobInput = ref(null)
    const jobForm = ref(null)
    const {
      priorityColor, getJobPriority, getJobTypes, getJobStatus,
    } = useJob()

    const propsVal = computed({
      set: value => {
        const result = {}
        console.log('computed')
        value.forEach(el => {
          console.log(el)
          // props untuk workflowInput
          if (el.property_id === 108) selectedCustomer.value = +el.value
          if (el.property_id === 107) selectedPriority.value = +el.value
          if (el.property_id === 105) { selectedJobType.value = +el.value; getJobStatus(+el.value) }
          if (el.property_id === 106) selectedJobStatus.value = +el.value
          if (el.property_id === 101) selectedJob.value = +el.value

          if (!result[el.property_id]) result[el.property_id] = []
          result[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
        })
        formData.value = result

        return result
      },
    })
    propsVal.value = props.props

    const priorityList = computed(() => store.state.job.jobPriority)
    const jobTypeList = computed(() => store.state.job.jobTypes)
    const jobStatusList = computed(() => store.state.job.jobStatus)

    onMounted(() => {
      if (!priorityList.value.length) {
        getJobPriority()
      }
      if (!jobTypeList.value.length) {
        getJobTypes()
      }
    })

    const onInput = data => {
      const result = []
      console.log(data)
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      emit('input', result)
    }

    const changePriority = val => {
      formData.value['107'] = {
        parent_id: null,
        property_id: 107,
        sort: 0,
        value: val.toString(),
      }
      onInput(formData.value)
    }

    const changeJobStatus = val => {
      formData.value['106'] = {
        parent_id: null,
        property_id: 106,
        sort: 0,
        value: val.toString(),
      }
      onInput(formData.value)
    }

    const changeJobType = val => {
      formData.value['105'] = {
        parent_id: null,
        property_id: 105,
        sort: 0,
        value: val.toString(),
      }
      onInput(formData.value)
      getJobStatus(val)
    }

    const changeJob = val => {
      formData.value['101'] = {
        parent_id: null,
        property_id: 101,
        sort: 0,
        value: val.toString(),
      }
      onInput(formData.value)
    }

    const changeCustomer = val => {
      formData.value['108'] = {
        parent_id: null,
        property_id: 108,
        sort: 0,
        value: val.toString(),
      }
      onInput(formData.value)
    }

    const validate = () => {
      let isValid = true

      if (!jobForm.value.validate()) isValid = false
      for (let i = 0; i < jobInput.value.length; i += 1) {
        if (!jobInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    return {
      formData,
      required,
      validateDeadline,
      jobInput,
      jobForm,
      priorityColor,
      getJobPriority,
      getJobTypes,
      getJobStatus,
      priorityList,
      jobTypeList,
      jobStatusList,
      onInput,
      validate,

      selectedJob,
      changeJob,

      selectedPriority,
      changePriority,

      selectedJobType,
      changeJobType,

      selectedJobStatus,
      changeJobStatus,

      selectedCustomer,
      changeCustomer,
    }
  },
}
</script>
