<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="gmailForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <google-account-select
            ref="googleUserSelect"
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang inbox-nya akan diawasi untuk mengecek email trigger',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import useGoogle from '@/composables/useGoogle'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import { required } from '@core/utils/validation'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    GoogleAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const {
      fetchUserGoogle, loadingUserGoogle, googleUserList,
    } = useGoogle()
    const gmailForm = ref(null)
    const selectedToken = ref(null)
    const googleUserSelect = ref(null)

    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.value) {
          selectedToken.value = props.value
        }
      })
    })

    const changeToken = val => {
      emit('input', val)
      emit('updateWebhook', { email: val.email })
    }

    const validate = () => {
      let isValid = true
      if (!gmailForm.value.validate()) isValid = false
      if (!googleUserSelect.value.validate()) isValid = false

      return isValid
    }

    return {
      selectedToken,
      googleUserSelect,
      loadingUserGoogle,
      googleUserList,
      changeToken,

      gmailForm,
      required,
      validate,
    }
  },
}
</script>
