import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"sheetForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang akan digunakan untuk membuat worksheet',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('div',{key:0,staticClass:"d-flex align-center"},[_c(VAutocomplete,{staticClass:"mt-4",attrs:{"items":_vm.driveSpreadsheetList,"item-text":"name","item-value":"id","flat":"","outlined":"","attach":"","dense":"","property-id":1203,"loading":_vm.loadingListSpreadsheet,"label":"Spreadsheet","hide-details":"auto","placeholder":"Pilih Spreadsheet","no-filter":"","rules":[_vm.required]},on:{"update:search-input":function (val) { return _vm.debouncedFetchSpreadsheet(_vm.selectedToken.refresh_token, val); },"change":_vm.changeSpreadsheet},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]})]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"#fef"}},[_c(VImg,{attrs:{"src":item.iconLink,"max-height":"24px","max-width":"24px"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedSpreadsheet),callback:function ($$v) {_vm.selectedSpreadsheet=$$v},expression:"selectedSpreadsheet"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Spreadsheet","details":[
                  'Worksheet akan dibuat di dalam file spreadsheet ini.' ]}})],1),_c('workflow-input',{key:1,ref:"sheetInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1201,"placeholder":"Nama (harus unique)","variables":_vm.options,"required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama worksheet","details":[
                    'Nama dari worksheet yang akan dibuat (tidak boleh ada worksheet dengan nama yang sama di dalam 1 spreadsheet)' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1201']),callback:function ($$v) {_vm.$set(_vm.formData, '1201', $$v)},expression:"formData['1201']"}}),_c('workflow-input',{key:2,ref:"driveInput",refInFor:true,staticClass:"my-4",attrs:{"placeholder":"Headers","variables":_vm.options,"property-id":1205,"required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama Header","details":[
                    'Nama header yang akan dibuat pada baris pertama dalam worksheet',
                    'Jika ada lebih dari 1 header, Pisahkan dengan tanda koma (,)' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData["1205"]),callback:function ($$v) {_vm.$set(_vm.formData, "1205", $$v)},expression:"formData[`1205`]"}})]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }