<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :class="`text--disabled ms-2 ${marginTop}`"
        v-bind="attrs"
        size="25"
        v-on="on"
      >
        {{ icons.mdiHelpCircleOutline }}
      </v-icon>
    </template>
    <v-card
      max-width="480"
      class="pa-3 d-flex flex-column"
    >
      <span class="text-h6 mb-3">{{ title }}</span>
      <span
        v-for="(line, index) in computedText"
        :key="index"
        class="text-subtitle-2 mb-1"
      >{{ line }}</span>
      <v-divider
        v-if="hasAction"
        class="my-3"
      />
      <slot name="actions" />
    </v-card>
  </v-menu>
</template>
<script>
import { mdiHelpCircleOutline } from '@mdi/js'
import { computed } from '@vue/composition-api'

export default {
  props: {
    title: {
      type: String,
      default: 'Judul',
    },
    details: {
      type: [String, Array],
      default: 'Informasi',
    },
    marginTop: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const computedText = computed(() => (Array.isArray(props.details) ? props.details : [props.details]))
    const hasAction = computed(() => slots.actions)

    return {
      icons: {
        mdiHelpCircleOutline,
      },
      computedText,
      hasAction,
    }
  },
}
</script>
