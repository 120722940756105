<template>
  <v-form
    ref="tokopediaForm"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="d-flex align-center">
      <v-select
        v-model="inputData"
        outlined
        flat
        dense
        attach
        :items="tokopediaAccountList"
        item-text="connection_name"
        item-value="id"
        label="Pilih Akun"
        :loading="loadingAccountTokopedia"
        :rules="[required]"
        :disabled="loadingAccountTokopedia"
        class="mt-4"
        hide-details="auto"
        placeholder="Pilih akun"
        return-object
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.connection_name }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption font-weight-semibold">
              {{ item.shop_url }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
      <slot name="info" />
    </div>
  </v-form>
</template>
<script>
import { computed, onMounted } from '@vue/composition-api'
import useTokopedia from '@/composables/useTokopedia'
import { required } from '@core/utils/validation'

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const { tokopediaAccountList, loadingAccountTokopedia, fetchAccountTokopedia } = useTokopedia()

    onMounted(() => {
      fetchAccountTokopedia()
    })

    const changeAccount = val => {
      emit('input', val)
    }

    return {
      tokopediaAccountList,
      loadingAccountTokopedia,
      changeAccount,

      required,
      inputData,
    }
  },
}
</script>
