<template>
  <v-form
    ref="accurateForm"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="d-flex align-center">
      <v-select
        :value="value"
        outlined
        flat
        dense
        :items="accurateDBList"
        item-text="alias"
        item-value="id"
        label="Database"
        attach
        :loading="loadingDBAccurate"
        :rules="[required]"
        :disabled="loadingDBAccurate"
        class="mt-4"
        hide-details="auto"
        placeholder="Pilih Database"
        @change="changeDB"
      >
        <template #item="{ item }">
          <v-list-item-avatar>
            <v-avatar :color="`#${item.bgColor}`">
              <v-img
                :src="item.logoUrl ? item.logoUrl : 'https://static.vecteezy.com/system/resources/previews/004/657/673/original/database-line-style-icon-free-vector.jpg'"
                max-height="36"
                max-width="36"
              />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.alias }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption font-weight-semibold">
              {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
      <slot name="info" />
    </div>
  </v-form>
</template>
<script>
import { onMounted } from '@vue/composition-api'
import useAccurate from '@/composables/useAccurate'
import { required } from '@core/utils/validation'

export default {
  props: {
    value: {
      type: null,
      default: null,
    },
    tokenId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { accurateDBList, loadingDBAccurate, fetchDBAccurate } = useAccurate()

    onMounted(() => {
      fetchDBAccurate(props.tokenId)
    })

    const changeDB = val => {
      emit('input', val)
    }

    return {
      required,
      changeDB,
      loadingDBAccurate,
      accurateDBList,
    }
  },
}
</script>
