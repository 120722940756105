import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"taskAutoSuggest",attrs:{"placeholder":"Cari Task...","label":_vm.label,"outlined":"","dense":"","multiple":_vm.multiple,"loading":_vm.loadingTasks,"items":_vm.options,"item-value":"id","item-text":"subject","hide-details":"auto","filled":"","no-filter":"","solo":"","flat":"","clearable":"","chips":"","rules":_vm.rules},on:{"update:search-input":_vm.onSearch,"update:list-index":_vm.onIndex,"click:clear":_vm.onClear},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-subtitle-2 text--disabled text-center"},[_c('p',[_vm._v(" Task tidak ditemukan. ")])])]},proxy:true},{key:"item",fn:function(task){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-subtitle-2 my-1"},[_vm._v(" "+_vm._s(task.item.subject)+" ")]),(task.item.tag && task.item.tag.length > 0)?_c('limit-elements',{attrs:{"elements":task.item.tag,"limit":3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1",attrs:{"x-small":"","color":data.color}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"others-activator",fn:function(ref){
var data = ref.data;
return [_c(VChip,{staticClass:"mr-1 v-chip-light-bg primary--text mb-2",attrs:{"x-small":"","color":"primary"}},[_vm._v(" +"+_vm._s(data.limit)+" ")])]}}],null,true)}):_c('span',[_vm._v(" - ")])],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticClass:"text-center"},[(_vm.loadingTasks)?_c('p',[_vm._v(" Memuat data... ")]):_vm._e()])]},proxy:true}]),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})}
var staticRenderFns = []

export { render, staticRenderFns }