import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"accurateForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"value":_vm.value,"outlined":"","flat":"","dense":"","items":_vm.accurateDBList,"item-text":"alias","item-value":"id","label":"Database","attach":"","loading":_vm.loadingDBAccurate,"rules":[_vm.required],"disabled":_vm.loadingDBAccurate,"hide-details":"auto","placeholder":"Pilih Database"},on:{"change":_vm.changeDB},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":("#" + (item.bgColor))}},[_c(VImg,{attrs:{"src":item.logoUrl ? item.logoUrl : 'https://static.vecteezy.com/system/resources/previews/004/657/673/original/database-line-style-icon-free-vector.jpg',"max-height":"36","max-width":"36"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.alias)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}])}),_vm._t("info")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }