import gql from 'graphql-tag'

const getTokopediaIntegrateRequests = gql`
  query getTokopediaIntegrateRequests(
    $pagination: pagination!
    $filter: filterTokopediaRequest!
  ) {
    getTokopediaIntegrateRequests(
      pagination: $pagination
      filter: $filter
    ) {
      count
      tokens {
        id
        shop_url
        connection_name
        status
        created_at
        updated_at
        workspace {
          id
          name
        }
      }

    }
  }
`

export default getTokopediaIntegrateRequests
