import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"calendarForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang akan digunakan untuk menghapus event',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('div',{key:0,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.calendarList,"item-value":"id","item-text":"summary","flat":"","outlined":"","attach":"","dense":"","loading":_vm.loadingCalendarList,"label":"Kalender","hide-details":"auto","disabled":!_vm.selectedToken,"placeholder":"Pilih Kalender","rules":[_vm.required]},on:{"change":_vm.changeCalendar},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.summary)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedCalendar),callback:function ($$v) {_vm.selectedCalendar=$$v},expression:"selectedCalendar"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Kalender","details":[
              'Kalender yang digunakan untuk memilih event yang akan dihapus' ]}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VAutocomplete,{key:1,staticClass:"mt-4",attrs:{"id":"eventList","items":_vm.calendarEventList,"item-value":"id","item-text":"summary","flat":"","outlined":"","dense":"","attach":"","loading":_vm.loadingCalendarEventList,"label":"Event","disabled":!_vm.selectedCalendar,"hide-details":"auto","placeholder":"Pilih Event","no-filter":"","rules":[_vm.required]},on:{"update:search-input":_vm.debouncedFetchEvent,"change":_vm.changeEvent},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]})]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.summary ? item.summary : '(no title)')+" "+_vm._s(item.status === 'cancelled' ? '(deleted)' : '')+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(((_vm.formatDate(item.start.dateTime, _vm.dateFormat)) + " - " + (_vm.formatDate(item.end.dateTime, _vm.dateFormat))))+" ")])],1)]}}],null,true),model:{value:(_vm.selectedEvent),callback:function ($$v) {_vm.selectedEvent=$$v},expression:"selectedEvent"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Event","details":[
              'Event yang akan dihapus' ]}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }