export default () => {
  const encodeVariable = (string, propertyId, identifier) => {
    const doc = new DOMParser().parseFromString(string, 'text/html')
    const arr = [...doc.body.childNodes].map(child => child.outerHTML || child.textContent)

    const variableList = []
    arr.forEach((el, index) => {
      if (el.startsWith('<div class="workflow-variable"')) {
        const id = el.match('data-id="(.*?)">')[1]
        variableList.push({
          property_id: propertyId,
          parent_id: +id,
          value: null,
          sort: index,
          identifier,
        })
      } else {
        variableList.push({
          property_id: propertyId,
          parent_id: null,
          value: el.replace(/<\/?[^>]+(>|$)/g, ''),
          sort: index,
          identifier,
        })
      }
    })

    return variableList
  }

  return {
    encodeVariable,
  }
}
