import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"telegramForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"outlined":"","flat":"","dense":"","attach":"","items":_vm.telegramBotList,"item-text":"connection_name","item-value":"id","label":"Pilih Akun","loading":_vm.loadingTelegramBotList,"rules":[_vm.required],"disabled":_vm.loadingTelegramBotList,"hide-details":"auto","placeholder":"Pilih BOT","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{staticClass:"mr-2 v-avatar-light-bg",attrs:{"size":"36"}},[_c('span',[_vm._v(_vm._s(_vm.avatarText(item.connection_name)))])])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.connection_name)+" ")])],1)]}}]),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}}),_vm._t("info")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }