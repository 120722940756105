<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="calendarForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <google-account-select
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang akan digunakan untuk menghapus event',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <div
            :key="0"
            class="d-flex align-center"
          >
            <v-select

              v-model="selectedCalendar"
              :items="calendarList"
              item-value="id"
              item-text="summary"
              flat
              outlined
              attach
              dense
              :loading="loadingCalendarList"
              label="Kalender"
              class="mt-4"
              hide-details="auto"
              :disabled="!selectedToken"
              placeholder="Pilih Kalender"
              :rules="[required]"
              @change="changeCalendar"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.summary }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Kalender"
              :details="[
                'Kalender yang digunakan untuk memilih event yang akan dihapus',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-autocomplete
              id="eventList"
              :key="1"
              v-model="selectedEvent"
              :items="calendarEventList"
              item-value="id"
              item-text="summary"
              flat
              outlined
              dense
              attach
              :loading="loadingCalendarEventList"
              label="Event"
              class="mt-4"
              :disabled="!selectedCalendar"
              hide-details="auto"
              placeholder="Pilih Event"
              no-filter
              :rules="[required]"
              @update:search-input="debouncedFetchEvent"
              @change="changeEvent"
            >
              <template #append-item>
                <div v-intersect="onIntersect" />
              </template>
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.summary ? item.summary : '(no title)' }} {{ item.status === 'cancelled' ? '(deleted)' : '' }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ `${formatDate(item.start.dateTime, dateFormat)} - ${formatDate(item.end.dateTime, dateFormat)}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            <!-- <template #selection="{ item }">
              <div class="v-select__selection v-select__selection--comma">
                {{ item.summary ? item.summary : '(no title)' }} {{ item.status === 'cancelled' ? '(deleted)' : '' }}
              </div>
            </template> -->
            </v-autocomplete>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Event"
              :details="[
                'Event yang akan dihapus',
              ]"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useGoogle from '@/composables/useGoogle'
import { onMounted, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { formatDate, isNumber } from '@core/utils/filter'
import { useDebounceFn } from '@vueuse/core'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    GoogleAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      googleUserList, loadingUserGoogle, fetchUserGoogle,
      calendarEventColorList, loadingCalendarColor,
      fetchCalendarList, calendarList, loadingCalendarList,
      fetchCalendarEventList, calendarEventList, loadingCalendarEventList,
    } = useGoogle()
    const selectedToken = ref(null)
    // const calendarInput = ref(null)
    const calendarForm = ref(null)
    const formData = ref({})
    const selectedCalendar = ref(null)
    const selectedEvent = ref(null)
    const paramSearchEvent = ref({})

    const loadFormEdit = data => {
      console.log(data)
      data.forEach(el => {
        // props untuk WorkflowInput
        if (el.property_id === 1002) selectedCalendar.value = el.value
        if (el.property_id === 1012) selectedEvent.value = el.value

        if (!formData.value[el.property_id]) formData.value[el.property_id] = []
        formData.value[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    const dateFormat = {
      day: 'numeric', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric',
    }
    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = googleUserList.value.find(el => el.id === props.token.id)
          loadFormEdit(props.props)
          fetchCalendarList(selectedToken.value.refresh_token)
          fetchCalendarEventList(selectedToken.value.refresh_token, selectedCalendar.value, { ...paramSearchEvent.value, showDeleted: true }).then(result => {
            calendarEventList.value = result.items
            paramSearchEvent.value.pageToken = result.nextPageToken
          })
        }
      })
    })

    const resetParamEvent = () => {
      paramSearchEvent.value = {}
    }

    const debouncedFetchEvent = useDebounceFn((val, fetchMore = false) => {
      if (val !== null) {
        if (!fetchMore) {
          resetParamEvent()
          paramSearchEvent.value.q = `${val.split(' ').join('* ')}*`
        }
        fetchCalendarEventList(selectedToken.value.refresh_token, selectedCalendar.value, { ...paramSearchEvent.value, showDeleted: true }).then(result => {
          console.log(result)
          calendarEventList.value = fetchMore ? [...calendarEventList.value, ...result.items] : result.items
          paramSearchEvent.value.pageToken = result.nextPageToken
        })
      }
    }, 750)

    const onIntersect = entry => {
      if (entry[0].isIntersecting) {
        debouncedFetchEvent(paramSearchEvent.value.q, true)
      }
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const changeCalendar = val => {
      resetParamEvent()
      selectedEvent.value = null
      fetchCalendarEventList(selectedToken.value.refresh_token, val, paramSearchEvent.value)
      formData.value['1002'] = {
        parent_id: null,
        property_id: 1002,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeEvent = val => {
      formData.value['1012'] = {
        parent_id: null,
        property_id: 1012,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      fetchCalendarList(val.refresh_token)
      selectedCalendar.value = null
      selectedEvent.value = null
    }

    const validate = () => {
      let isValid = true
      // console.log(calendarInput.value)
      if (!calendarForm.value.validate()) isValid = false
      // for (let i = 0; i < calendarInput.value.length; i += 1) {
      //   if (!calendarInput.value[i].validate()) isValid = false
      // }

      return isValid
    }

    return {
      googleUserList,
      loadingUserGoogle,
      selectedToken,
      // calendarInput,
      calendarForm,
      formData,
      required,

      changeToken,
      onInput,
      validate,
      calendarEventColorList,
      loadingCalendarColor,

      calendarList,
      loadingCalendarList,
      selectedCalendar,

      selectedEvent,
      calendarEventList,
      loadingCalendarEventList,
      changeCalendar,
      changeEvent,
      formatDate,
      dateFormat,
      debouncedFetchEvent,

      onIntersect,
    }
  },
}
</script>
