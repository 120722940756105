import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"driveForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang akan digunakan untuk membuat folder',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('div',{key:0,staticClass:"d-flex align-center"},[_c(VAutocomplete,{staticClass:"mt-4",attrs:{"items":_vm.driveFolderList,"property-id":1102,"item-text":"name","item-value":"id","flat":"","outlined":"","attach":"","dense":"","loading":_vm.loadingListFolder,"label":"Folder","hide-details":"auto","placeholder":"Pilih Folder","no-filter":""},on:{"update:search-input":function (val) { return _vm.debouncedFetchFolder(_vm.selectedToken.refresh_token, val); },"change":_vm.changeParent},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]})]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"#fef"}},[_c(VImg,{attrs:{"src":item.iconLink,"max-height":"24px","max-width":"24px"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedParent),callback:function ($$v) {_vm.selectedParent=$$v},expression:"selectedParent"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Parent Folder","details":[
                  'Folder yang digunakan sebagai tempat membuat folder baru' ]}})],1),_c('workflow-input',{key:1,ref:"driveInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1101,"placeholder":"Nama Folder","variables":_vm.options,"required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama Folder","details":[
                    'Nama dari folder yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1101']),callback:function ($$v) {_vm.$set(_vm.formData, '1101', $$v)},expression:"formData['1101']"}}),_c('workflow-input',{key:2,ref:"driveInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1103,"placeholder":"Deskripsi","variables":_vm.options,"required":"","long":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Deskripsi Folder","details":[
                    'Deskripsi dari folder yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1103']),callback:function ($$v) {_vm.$set(_vm.formData, '1103', $$v)},expression:"formData['1103']"}})]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }