<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="checkListForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <trello-account-select
            ref="trelloUserSelect"
            v-model="selectedToken"
            :loading="loadingUserTrello"
            :items="trelloUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Trello"
                :details="[
                  'Akun Trello yang akan digunakan untuk menambah checklist ke card',
                  'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </trello-account-select>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedBoard"
              :property-id="901"
              :items="trelloBoardList"
              item-text="name"
              item-value="id"
              flat
              attach
              outlined
              dense
              :disabled="!selectedToken"
              :loading="loadingBoardTrello"
              label="Board"
              class="mt-4"
              hide-details="auto"
              placeholder="Pilih Board"
              :rules="[required]"
              @input="changeBoard"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Board"
              :details="[
                'Board yang akan digunakan untuk memilih card yang akan ditambahkan checklist',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedList"
              :items="trelloListList"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              attach
              :loading="loadingListTrello"
              label="List"
              class="mt-4"
              :disabled="!selectedBoard"
              :property-id="902"
              :rules="[required]"
              hide-details="auto"
              placeholder="Pilih List"
              @change="changeList"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="List"
              :details="[
                'List yang akan digunakan untuk memilih card yang akan ditambahkan checklist',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedCard"
              :items="trelloCardList"
              item-text="name"
              item-value="id"
              flat
              outlined
              attach
              dense
              :loading="loadingCardTrello"
              label="Card"
              class="mt-4"
              :disabled="!selectedList"
              :property-id="911"
              :rules="[required]"
              hide-details="auto"
              placeholder="Pilih Card"
              @change="changeCard"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Card"
              :details="[
                'Checklist akan ditambahkan ke card ini',
              ]"
            />
          </div>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedCard"
            >
              <workflow-input
                :key="1"
                ref="checkListInput"
                v-model="formData['915']"
                placeholder="Nama Checklist"
                class="mt-4"
                :variables="options"
                :property-id="915"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Nama Checklist"
                    :details="[
                      'Nama dari Checklist yang akan ditambahkan',
                    ]"
                  />
                </template>
              </workflow-input>
              <!-- <template v-if="itemcount > 0">
                  <workflow-input
                    v-for="(idx) in itemcount"
                    :key="`item-${idx - 1}`"
                    ref="checkListInput"
                    v-model="formData[`916-${idx-1}`]"
                    :placeholder="`Nama item ${idx}`"
                    class="my-4"
                    :variables="options"
                    :identifier="`${idx-1}`"
                    :property-id="916"
                    required
                    @input="onInput(formData)"
                  />
                </template>
                <v-btn
                  class="mt-4"
                  color="primary"
                  outlined
                  @click="itemcount+=1"
                >
                  Tambah Item
                </v-btn> -->
              <workflow-input
                :key="`2`"
                ref="checkListInput"
                v-model="formData['916']"
                placeholder="Item Checklist"
                class="my-4"
                :variables="options"
                :property-id="916"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Item Checklist"
                    :details="[
                      'Name dari item Checklist yang akan ditambahkan',
                      'Jika ada lebih dari 1 item, Pisahkan dengan tanda koma (,)',
                    ]"
                  />
                </template>
              </workflow-input>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useTrello from '@/composables/useTrello'
import { onMounted, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { isNumber } from '@core/utils/filter'
import WorkflowInput from '../../components/WorkflowInput.vue'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'
import TrelloAccountSelect from '@/views/workflow/components/selects/TrelloAccountSelect.vue'

export default {
  components: {
    WorkflowInput,
    WorkflowFieldInfo,
    TrelloAccountSelect,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    // const itemcount = ref(0)
    const checkListForm = ref(null)
    const checkListInput = ref(null)
    const selectedToken = ref(null)
    const selectedBoard = ref(null)
    const selectedList = ref(null)
    const selectedCard = ref(null)
    const formData = ref({})

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput
        if (el.property_id === 901) selectedBoard.value = el.value
        if (el.property_id === 902) selectedList.value = el.value
        if (el.property_id === 911) selectedCard.value = el.value

        const formDataIndex = el.identifier ? `${el.property_id}-${el.identifier}` : el.property_id
        if (!formData.value[formDataIndex]) {
          formData.value[formDataIndex] = []
          // if (el.identifier) itemcount.value += 1
        }
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const changeCard = val => {
      formData.value['911'] = {
        parent_id: null,
        property_id: 911,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const {
      fetchBoardsTrello, loadingBoardTrello, trelloBoardList,
      fetchUserTrello, loadingUserTrello, trelloUserList,
      fetchListsTrello, loadingListTrello, trelloListList,
      fetchCardsTrello, loadingCardTrello, trelloCardList,
    } = useTrello()

    const changeBoard = val => {
      fetchListsTrello(selectedToken.value.token, val)

      selectedList.value = null
      formData.value['54'] = null

      formData.value['901'] = {
        parent_id: null,
        property_id: 901,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeList = val => {
      selectedCard.value = null
      formData.value['63'] = null

      formData.value['902'] = {
        parent_id: null,
        property_id: 902,
        sort: 0,
        value: val,
      }
      onInput(formData.value)

      fetchCardsTrello(selectedToken.value.token, selectedList.value)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      fetchBoardsTrello(val.token)
    }

    const validate = () => {
      let isValid = true
      if (!checkListForm.value.validate()) isValid = false
      for (let i = 0; i < checkListInput.value.length; i += 1) {
        if (!checkListInput.value[i].validate()) isValid = false
      }
      // if (itemcount.value <= 0) isValid = false

      return isValid
    }

    onMounted(() => {
      formData.value['68'] = []
      fetchUserTrello().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          loadFormEdit(props.props)
          selectedToken.value = trelloUserList.value.find(el => +el.id === props.token.id)
          fetchBoardsTrello(selectedToken.value.token)
          fetchListsTrello(selectedToken.value.token, selectedBoard.value)
          fetchCardsTrello(selectedToken.value.token, selectedList.value)
        }
      })
    })

    return {
      selectedToken,
      selectedBoard,
      selectedList,
      selectedCard,
      checkListForm,
      checkListInput,
      formData,
      onInput,
      fetchUserTrello,
      loadingUserTrello,
      trelloUserList,
      fetchBoardsTrello,
      loadingBoardTrello,
      trelloBoardList,
      fetchListsTrello,
      loadingListTrello,
      trelloListList,
      fetchCardsTrello,
      loadingCardTrello,
      trelloCardList,
      changeBoard,
      changeList,
      changeToken,
      validate,
      required,
      // itemcount,

      changeCard,
    }
  },
}
</script>
