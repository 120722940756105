<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="trelloForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <trello-account-select
            ref="trelloUserSelect"
            v-model="selectedToken"
            :loading="loadingUserTrello"
            :items="trelloUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Trello"
                :details="[
                  'Akun Trello yang akan diawasi untuk mengecek event trigger',
                  'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </trello-account-select>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedBoard"
              :items="trelloBoardList"
              :property-id="920"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              attach
              :disabled="!selectedToken"
              :loading="loadingBoardTrello"
              label="Board"
              class="mt-4"
              hide-details="auto"
              placeholder="Pilih Board"
              :rules="[required]"
              required
              @change="changeBoard"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Board"
              :details="[
                'Board yang akan diawasi untuk mengecek event trigger',
              ]"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import TrelloAccountSelect from '@/views/workflow/components/selects/TrelloAccountSelect.vue'
import { onMounted, ref } from '@vue/composition-api'
import useTrello from '@/composables/useTrello'
import { required } from '@core/utils/validation'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    TrelloAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    webhookIdentifier: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const selectedToken = ref(null)
    const selectedBoard = ref(null)

    const {
      fetchBoardsTrello, loadingBoardTrello, trelloBoardList,
      fetchUserTrello, loadingUserTrello, trelloUserList,
    } = useTrello()

    const changeToken = val => {
      emit('input', val)
      fetchBoardsTrello(selectedToken.value.token)
    }

    const changeBoard = val => {
      emit('updateWebhook', { board_id: val })
    }

    onMounted(() => {
      console.log('props', props.webhookIdentifier)
      fetchUserTrello().then(() => {
        if (props.value) {
          selectedToken.value = props.value
          console.log('selectedToken.value', selectedToken.value)
          fetchBoardsTrello(selectedToken.value.access_token).then(() => {
            if (props.webhookIdentifier) {
              selectedBoard.value = props.webhookIdentifier.board_id
            }
          })
        }
      })
    })

    const trelloForm = ref(null)
    const trelloUserSelect = ref(null)

    const validate = () => {
      let isValid = true
      if (!trelloForm.value.validate()) isValid = false

      return isValid
    }

    return {
      changeToken,
      validate,

      trelloForm,
      trelloUserSelect,

      selectedToken,
      selectedBoard,

      trelloBoardList,
      loadingBoardTrello,

      changeBoard,
      required,

      trelloUserList,
      loadingUserTrello,
    }
  },
}
</script>
