<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="driveForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <google-account-select
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang akan digunakan untuk mengganti file',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <div
                :key="0"
                class="d-flex align-center"
              >
                <v-autocomplete

                  v-model="selectedParent"
                  :items="driveFolderList"
                  :property-id="1102"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  :rules="[required]"
                  :loading="loadingListFolder"
                  label="Folder"
                  class="mt-4"
                  hide-details="auto"
                  placeholder="Pilih Folder"
                  no-filter
                  @update:search-input="(val) => debouncedFetchFolder(selectedToken.refresh_token, val)"
                  @input="changeFolder"
                >
                  <template #append-item>
                    <div v-intersect="onIntersectFolder" />
                  </template>
                  <template #item="{ item }">
                    <v-list-item-avatar>
                      <v-avatar color="#fef">
                        <v-img
                          :src="item.iconLink"
                          max-height="24px"
                          max-width="24px"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.id }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <workflow-field-info
                  :margin-top="'mt-3'"
                  title="Parent Folder"
                  :details="[
                    'Folder yang digunakan untuk memilih file yang akan diubah',
                  ]"
                />
              </div>
            </template>
          </XyzTransitionGroup>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedParent"
            >
              <div
                :key="0"
                class="d-flex align-center"
              >
                <v-autocomplete
                  v-model="selectedFile"
                  :items="driveFileList"
                  :property-id="1105"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  attach
                  :rules="[required]"
                  :loading="loadingListFile"
                  label="File"
                  class="mt-4"
                  hide-details="auto"
                  placeholder="Pilih File"
                  no-filter
                  @update:search-input="(val) => debouncedFetchFile(selectedToken.refresh_token, val, null, selectedParent)"
                  @change="changeFile"
                >
                  <template #append-item>
                    <div v-intersect="onIntersectFile" />
                  </template>
                  <template #item="{ item }">
                    <v-list-item-avatar>
                      <v-avatar color="#fef">
                        <v-img
                          :src="item.iconLink"
                          max-height="24px"
                          max-width="24px"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.id }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <workflow-field-info
                  :margin-top="'mt-3'"
                  title="File"
                  :details="[
                    'File yang akan diubah',
                  ]"
                />
              </div>
              <workflow-input
                :key="1"
                ref="driveInput"
                v-model="formData['1101']"
                :property-id="1101"
                placeholder="Nama File"
                :variables="options"
                class="my-4"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Nama File"
                    :details="[
                      'Nama Baru dari File yang diubah',
                    ]"
                  />
                </template>
              </workflow-input>
              <workflow-input
                :key="2"
                ref="driveInput"
                v-model="formData['1103']"
                :property-id="1103"
                placeholder="Deskripsi"
                :variables="options"
                long
                class="my-4"
                @input="onInput(formData)"
              />
              <workflow-input
                :key="3"
                ref="driveInput"
                v-model="formData['1104']"
                :property-id="1104"
                placeholder="File URL"
                :variables="options"
                class="my-4"
                @input="onInput(formData)"
              />
            </template>
          </XyzTransitionGroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useGoogle from '@/composables/useGoogle'
import { onMounted, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import WorkflowInput from '../../components/WorkflowInput.vue'
import { useDebounceFn } from '@vueuse/core'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import { isNumber } from '@/@core/utils/filter'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    WorkflowInput,
    GoogleAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      googleUserList, loadingUserGoogle, fetchUserGoogle,
      fetchDriveFolderList, loadingListFolder, driveFolderList,
      fetchDriveFileList, driveFileList, loadingListFile,
      driveFolderNextPageToken, driveFileNextPageToken,
    } = useGoogle()

    const formData = ref({})

    const driveInput = ref(null)
    const driveForm = ref(null)

    const selectedToken = ref(null)
    const selectedParent = ref(null)
    const selectedFile = ref(null)

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      selectedParent.value = null
      selectedFile.value = null
      fetchDriveFolderList(val.refresh_token, '')
    }

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput

        if (el.property_id === 1102) selectedParent.value = el.value
        if (el.property_id === 1105) selectedFile.value = el.value

        const formDataIndex = el.identifier ? `${el.property_id}-${el.identifier}` : el.property_id
        if (!formData.value[formDataIndex]) formData.value[formDataIndex] = []
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = googleUserList.value.find(el => el.id === props.token.id)
          loadFormEdit(props.props)
          fetchDriveFolderList(selectedToken.value.refresh_token, '')
        }
      })
    })

    const debouncedFetchFolder = useDebounceFn((token, search, pageToken) => {
      if (token) fetchDriveFolderList(token, search, pageToken)
    }, 750)

    const onIntersectFolder = entry => {
      if (entry[0].isIntersecting && driveFolderList.value.length > 0) {
        if (driveFolderNextPageToken.value) {
          debouncedFetchFolder(selectedToken.value.refresh_token, '', driveFolderNextPageToken.value)
        }
      }
    }

    const debouncedFetchFile = useDebounceFn((token, search, pageToken, parentId) => {
      if (token) fetchDriveFileList(token, search, pageToken, parentId)
    }, 750)

    const onIntersectFile = entry => {
      if (entry[0].isIntersecting && driveFileList.value.length > 0) {
        if (driveFileNextPageToken.value) {
          debouncedFetchFile(selectedToken.value.refresh_token, '', driveFileNextPageToken.value, selectedParent.value)
        }
      }
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      console.log(driveInput.value)
      if (!driveForm.value.validate()) isValid = false
      for (let i = 0; i < driveInput.value.length; i += 1) {
        if (!driveInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    const changeFolder = val => {
      selectedFile.value = null
      fetchDriveFileList(selectedToken.value.refresh_token, '', '', val)

      formData.value['1102'] = {
        parent_id: null,
        property_id: 1102,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeFile = val => {
      formData.value['1105'] = {
        parent_id: null,
        property_id: 1105,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    return {
      required,
      selectedToken,
      selectedParent,
      selectedFile,

      googleUserList,
      loadingUserGoogle,
      fetchUserGoogle,

      fetchDriveFolderList,
      loadingListFolder,
      driveFolderList,

      fetchDriveFileList,
      loadingListFile,
      driveFileList,

      changeToken,
      changeFolder,
      onInput,
      validate,

      driveInput,
      driveForm,

      debouncedFetchFolder,
      onIntersectFolder,
      onIntersectFile,
      debouncedFetchFile,
      formData,
      changeFile,
    }
  },
}
</script>
<style>

</style>
