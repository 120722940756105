import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"gmailForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang akan digunakan untuk mengirim email',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('workflow-input',{key:0,ref:"gmailInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":802,"placeholder":"Kepada","variables":_vm.options,"required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Kepada","details":[
                    'Alamat email penerima',
                    'Jika ingin mengirimkan ke beberapa alamat email, pisahkan dengan tanda koma (,).' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['802']),callback:function ($$v) {_vm.$set(_vm.formData, '802', $$v)},expression:"formData['802']"}}),_c('workflow-input',{key:1,ref:"gmailInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":803,"placeholder":"Subjek","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Subjek","details":[
                    'Judul email yang akan dikirimkan.' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['803']),callback:function ($$v) {_vm.$set(_vm.formData, '803', $$v)},expression:"formData['803']"}}),_c('workflow-input',{key:2,ref:"gmailInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":804,"placeholder":"CC","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Cc","details":[
                    'Alamat email penerima salinan email.',
                    'Jika ingin mengirimkan ke beberapa alamat email, pisahkan dengan tanda koma (,).' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['804']),callback:function ($$v) {_vm.$set(_vm.formData, '804', $$v)},expression:"formData['804']"}}),_c('workflow-input',{key:3,ref:"gmailInput",refInFor:true,attrs:{"property-id":805,"placeholder":"BCC","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Bcc","details":[
                    'Sama seperti Cc, tetapi alamat email tidak bisa dilihat oleh penerima dan Cc.',
                    'Jika ingin mengirimkan ke beberapa alamat email, pisahkan dengan tanda koma (,).' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['805']),callback:function ($$v) {_vm.$set(_vm.formData, '805', $$v)},expression:"formData['805']"}}),_c('workflow-input',{key:4,ref:"gmailInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":806,"placeholder":"Body","variables":_vm.options,"long":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Body","details":[
                    'Isi dari email yang akan dikirimkan' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['806']),callback:function ($$v) {_vm.$set(_vm.formData, '806', $$v)},expression:"formData['806']"}})]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }