<template>
  <v-autocomplete
    ref="productAutoSuggest"
    v-model="inputData"
    placeholder="Cari produk..."
    :label="label"
    outlined
    dense
    :multiple="multiple"
    :loading="loading"
    :items="options"
    item-value="id"
    item-text="name"
    hide-details="auto"
    filled
    no-filter
    solo
    flat
    chips
    clearable
    :rules="rules"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
    @click:clear="onClear"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p>
          Produk tidak ditemukan.
        </p>
      </div>
    </template>
    <template #item="data">
      <v-list-item-avatar>
        <v-avatar color="primary">
          <v-img :src="data.item.photo ? data.item.photo.length > 0 ? data.item.photo[0].photo : 'default.png' : 'default.png'" />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle class="caption">
          {{ data.item.code_sku ? data.item.code_sku : "Tidak ada SKU" }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="caption font-weight-semibold">
          {{ data.item.price }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="caption font-weight-semibold">
          Stok {{ data.item.stock }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
// import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { products } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    propertyId: {
      type: Number,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Cari Produk...',
    },
  },
  setup(props, { emit }) {
    const productAutoSuggest = ref(null)
    const encoded = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const loading = ref(false)
    const searchQuery = ref('')
    const productList = ref([])
    const inputData = ref(null)

    watch(inputData, val => {
      if (val) {
        const formatted = {
          parent_id: null,
          property_id: props.propertyId,
          sort: 0,
          value: val.toString(),
        }
        encoded.value = formatted
      } else {
        encoded.value = null
      }
    })

    const options = computed(() => {
      if (inputData.value) {
        console.log('tes')

        return [
          ...productList.value,
          inputData.value,
        ]
      }

      return productList.value
    })
    const fetchProducts = () => {
      loading.value = true
      apolloClient.query({
        query: products,
        variables: {
          pagination: {
            limit: 16,
            offset: 0,
          },
          search: searchQuery.value,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        productList.value = result.data.products.products
        loading.value = false
      }).catch(err => {
        errorHandling(err)
        loading.value = false
      })
    }

    onMounted(() => {
      fetchProducts()
    })

    const onClear = () => {
      searchQuery.value = ''
      fetchProducts()
    }

    const onSearch = data => {
      searchQuery.value = data
      fetchProducts()
    }

    const isEmpty = ref(false)
    const onIndex = () => {
      isEmpty.value = productAutoSuggest.value.filteredItems.length === 0
    }

    return {
      productAutoSuggest,
      inputData,
      loading,
      options,
      searchQuery,
      onSearch,
      isEmpty,
      onIndex,
      productList,
      onClear,
    }
  },
}
</script>

<style>

</style>
