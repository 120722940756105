<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="cardForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <trello-account-select
            ref="trelloUserSelect"
            v-model="selectedToken"
            :loading="loadingUserTrello"
            :items="trelloUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Trello"
                :details="[
                  'Akun Trello yang akan digunakan untuk menghapus kartu',
                  'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </trello-account-select>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedBoard"
              :items="trelloBoardList"
              item-text="name"
              item-value="id"
              flat
              outlined
              attach
              dense
              :property-id="901"
              :disabled="!selectedToken"
              :loading="loadingBoardTrello"
              label="Board"
              class="mt-4"
              hide-details="auto"
              placeholder="Pilih Board"
              :rules="[required]"
              @input="changeBoard"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Board"
              :details="[
                'Board yang akan digunakan untuk memilih kartu yang akan dihapus',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedList"
              :items="trelloListList"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              attach
              :loading="loadingListTrello"
              label="List"
              class="mt-4"
              :disabled="!selectedBoard"
              :property-id="902"
              :rules="[required]"
              hide-details="auto"
              placeholder="Pilih List"
              @change="changeList"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="List"
              :details="[
                'List yang akan digunakan untuk memilih kartu yang akan dihapus',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedCard"
              :items="trelloCardList"
              item-text="name"
              item-value="id"
              flat
              attach
              outlined
              dense
              :loading="loadingCardTrello"
              label="Kartu"
              class="mt-4"
              :disabled="!selectedList"
              :property-id="911"
              :rules="[required]"
              hide-details="auto"
              placeholder="Pilih Kartu"
              @change="changeCard"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Kartu"
              :details="[
                'Kartu yang akan dihapus',
              ]"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mdiImage } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import useTrello from '@/composables/useTrello'
import { avatarText, isNumber } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'
import TrelloAccountSelect from '@/views/workflow/components/selects/TrelloAccountSelect.vue'

export default {
  components: {
    WorkflowFieldInfo,
    TrelloAccountSelect,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const cardForm = ref(null)
    const selectedToken = ref(null)
    const selectedBoard = ref(null)
    const selectedList = ref(null)
    const selectedCard = ref(null)
    const formData = ref({})

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput
        if (el.property_id === 901) selectedBoard.value = el.value
        if (el.property_id === 902) selectedList.value = el.value
        if (el.property_id === 911) selectedCard.value = el.value

        if (!formData.value[el.property_id]) formData.value[el.property_id] = []
        formData.value[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const changeCard = val => {
      formData.value['911'] = {
        parent_id: null,
        property_id: 911,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const {
      fetchBoardsTrello, loadingBoardTrello, trelloBoardList,
      fetchUserTrello, loadingUserTrello, trelloUserList,
      fetchListsTrello, loadingListTrello, trelloListList,
      fetchCardsTrello, loadingCardTrello, trelloCardList,
    } = useTrello()

    const changeBoard = val => {
      fetchListsTrello(selectedToken.value.token, val)

      selectedList.value = null
      formData.value['54'] = null

      formData.value['901'] = {
        parent_id: null,
        property_id: 901,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeList = val => {
      selectedCard.value = null
      formData.value['63'] = null

      formData.value['902'] = {
        parent_id: null,
        property_id: 902,
        sort: 0,
        value: val,
      }
      onInput(formData.value)

      fetchCardsTrello(selectedToken.value.token, selectedList.value)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      fetchBoardsTrello(val.token)
    }

    const validate = () => {
      let isValid = true
      if (!cardForm.value.validate()) isValid = false

      return isValid
    }

    onMounted(() => {
      fetchUserTrello().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          loadFormEdit(props.props)
          selectedToken.value = trelloUserList.value.find(el => +el.id === props.token.id)
          fetchBoardsTrello(selectedToken.value.token)
          fetchListsTrello(selectedToken.value.token, selectedBoard.value)
          fetchCardsTrello(selectedToken.value.token, selectedList.value)
        }
      })
    })

    return {
      cardForm,
      selectedToken,
      selectedBoard,
      selectedCard,
      formData,
      icons: {
        mdiImage,
      },

      loadingBoardTrello,
      trelloBoardList,
      fetchBoardsTrello,

      loadingUserTrello,
      trelloUserList,
      fetchUserTrello,

      changeBoard,
      loadingListTrello,
      trelloListList,

      fetchCardsTrello,
      loadingCardTrello,
      trelloCardList,

      changeToken,
      onInput,
      selectedList,
      validate,
      avatarText,
      required,
      changeList,

      changeCard,

    }
  },
}
</script>
<style>
</style>
