<template>
  <v-form
    ref="googleForm"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="d-flex align-center">
      <v-select
        :value="value"
        :items="items"
        item-text="email"
        item-value="id"
        flat
        outlined
        dense
        attach
        :loading="loading"
        label="Akun"
        class="mt-4"
        hide-details="auto"
        placeholder="Pilih Akun"
        return-object
        :rules="[required]"
        @input="handleChange"
      >
        <template #item="{ item }">
          <v-list-item-avatar>
            <v-avatar color="primary">
              <v-img
                :src="item.picture"
                referrerpolicy="no-referrer"
              />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.email }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption font-weight-semibold">
              {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
      <slot name="info" />
    </div>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {
  props: {
    value: {
      type: null,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const handleChange = val => {
      emit('input', val)
    }

    const googleForm = ref(null)

    const validate = () => googleForm.value.validate()

    return {
      handleChange,
      required,
      validate,
      googleForm,
    }
  },
}
</script>
