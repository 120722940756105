<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="calendarForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <google-account-select
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang akan digunakan untuk mengubah event',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <div
            :key="0"
            class="d-flex align-center"
          >
            <v-select
              v-model="selectedCalendar"
              :items="calendarList"
              item-value="id"
              item-text="summary"
              flat
              attach
              outlined
              dense
              :loading="loadingCalendarList"
              label="Kalender"
              class="mt-4"
              hide-details="auto"
              :disabled="!selectedToken"
              placeholder="Pilih Kalender"
              :rules="[required]"
              @change="changeCalendar"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.summary }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Kalender"
              :details="[
                'Kalender yang digunakan untuk memilih event yang akan diubah',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-autocomplete
              id="eventList"
              :key="1"
              v-model="selectedEvent"
              :items="calendarEventList"
              item-value="id"
              item-text="summary"
              flat
              attach
              outlined
              dense
              :loading="loadingCalendarEventList"
              label="Event"
              class="mt-4"
              :disabled="!selectedCalendar"
              hide-details="auto"
              placeholder="Pilih Event"
              no-filter
              :rules="[required]"
              @update:search-input="debouncedFetchEvent"
              @change="changeEvent"
            >
              <template #append-item>
                <div v-intersect="onIntersect" />
              </template>
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.summary ? item.summary : '(no title)' }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ `${formatDate(item.start.dateTime, dateFormat)} - ${formatDate(item.end.dateTime, dateFormat)}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Event"
              :details="[
                'Event yang akan diubah',
              ]"
            />
          </div>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedEvent"
            >
              <workflow-input
                :key="1"
                ref="calendarInput"
                v-model="formData['1003']"
                :property-id="1003"
                placeholder="Judul"
                :variables="options"
                class="my-4"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Judul"
                    :details="[
                      'Judul event yang baru (opsional)',
                    ]"
                  />
                </template>
              </workflow-input>
              <workflow-input
                :key="2"
                ref="calendarInput"
                v-model="formData['1004']"
                :property-id="1004"
                placeholder="Deskripsi"
                :variables="options"
                class="my-4"
                long
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Judul"
                    :details="[
                      'Deskripsi event yang baru (opsional)',
                    ]"
                  />
                </template>
              </workflow-input>
              <v-row
                :key="3"
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <workflow-input
                    ref="calendarInput"
                    v-model="formData['1005']"
                    :property-id="1005"
                    placeholder="Waktu Mulai"
                    type="date"
                    :variables="options"
                    @input="onInput(formData)"
                  >
                    <template #info>
                      <workflow-field-info
                        :margin-top="'mt-1'"
                        title="Waktu Mulai"
                        :details="[
                          'Waktu mulai event yang baru (opsional)',
                          'Format Waktu mulai: YYYY-MM-DDThh:mm:ss',
                          'YYYY: 4 digit tahun',
                          'MM: 2 digit bulan',
                          'DD: 2 digit hari',
                          'T: Pemisah tanggal & waktu',
                          'hh: 2 digit jam',
                          'mm: 2 digit menit',
                          'ss: 2 digit detik',
                          'Contoh: 2023-02-03T07:00:00'
                        ]"
                      />
                    </template>
                  </workflow-input>
                </v-col>
              </v-row>
              <v-row
                :key="4"
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="selectedOffset"
                      label="Offset Waktu"
                      outlined
                      dense
                      :rules="[validTimeOffset]"
                      hide-details="auto"
                      @change="changeOffset"
                    />
                    <div class="mt-2">
                      <workflow-field-info
                        title="Offset Waktu Mulai"
                        :details="[
                          'Offset Waktu mulai digunakan untuk mengubah waktu mulai relatif dari Waktu mulai yang sudah ditentukan (opsional)',
                          'Format penulisan offset: [+-][0-9]{1,2}[smhd]',
                          '+/- menentukan percepatan / perlambatan waktu mulai',
                          'Angka setelah +/- menentukan jumlah percepatan / perlambatan waktu mulai',
                          'huruf s/m/h/d menentukan satuan waktu nya (s: detik, m: menit, h: jam, d: hari)',
                          'Contoh:',
                          'Waktu Mulai: 2023-02-03T07:00:00',
                          'Offset: +2d -12h -30m',
                          'Artinya, waktu mulai akan ditambah dengan 2 hari dan dikurangi dengan 12 jam 30 menit',
                          'Waktu mulai Setelah offset: 2023-02-04T18:30:00'
                        ]"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row :key="5">
                <v-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="selectedDuration"
                      label="Durasi Event (dalam menit)"
                      placeholder="Durasi Event (dalam menit)"
                      outlined
                      dense
                      type="number"
                      hide-details="auto"
                      @change="changeDuration"
                    />
                    <workflow-field-info
                      :margin-top="'mt-2'"
                      title="Waktu Mulai"
                      :details="[
                        'Durasi event yang baru (opsional)',
                        'Format Waktu mulai: YYYY-MM-DDThh:mm:ss',
                        'YYYY: 4 digit tahun',
                        'MM: 2 digit bulan',
                        'DD: 2 digit hari',
                        'T: Pemisah tanggal & waktu',
                        'hh: 2 digit jam',
                        'mm: 2 digit menit',
                        'ss: 2 digit detik',
                        'Contoh: 2023-02-03T07:00:00'
                      ]"
                    />
                  </div>
                </v-col>
              </v-row>
              <workflow-input
                :key="6"
                ref="calendarInput"
                v-model="formData['1008']"
                :property-id="1008"
                placeholder="Peserta"
                :variables="options"
                class="my-4"
                type="string"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Peserta"
                    :details="[
                      'Peserta Event yang baru (opsional)',
                      'Jika ada lebih dari 1 peserta, Pisahkan dengan tanda koma (,)',
                    ]"
                  />
                </template>
              </workflow-input>
              <div
                :key="7"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedColor"
                  :items="calendarEventColorList"
                  item-value="id"
                  item-text="background"
                  flat
                  outlined
                  attach
                  dense
                  :loading="loadingCalendarColor"
                  label="Warna"
                  class="my-4"
                  hide-details="auto"
                  placeholder="Pilih Warna"
                  @change="changeColor"
                >
                  <template #item="{ item }">
                    <v-list-item-avatar>
                      <v-avatar
                        size="36"
                        :color="item.background"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.background }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <workflow-field-info
                  title="Warna"
                  :details="[
                    'Warna penanda baru dari event (opsional)',
                  ]"
                />
              </div>
              <div
                :key="8"
                class="d-flex align-center"
              >
                <RecurrencePicker
                  v-model="selectedRecurrence"
                  :property-id="1010"
                  label="Pengulangan"
                  @input="changeRecurrence"
                />
                <workflow-field-info
                  title="Warna"
                  :details="[
                    'Pola pengulangan event yang baru (opsional)',
                  ]"
                />
              </div>
              <div
                :key="9"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedVisibility"
                  class="mt-4"
                  label="Visibilitas"
                  :items="visibilityList"
                  item-text="text"
                  item-value="value"
                  flat
                  outlined
                  attach
                  dense
                  @change="changeVisibility"
                />
                <workflow-field-info
                  title="Visibilitas"
                  :details="[
                    'Visibilitas / privasi event (opsional)',
                  ]"
                />
              </div>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useGoogle from '@/composables/useGoogle'
import { onMounted, ref } from '@vue/composition-api'
import { required, validTimeOffset } from '@core/utils/validation'
import WorkflowInput from '../../components/WorkflowInput.vue'
import RecurrencePicker from './components/RecurrencePicker.vue'
import { formatDate, isNumber } from '@core/utils/filter'
import { useDebounceFn } from '@vueuse/core'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'

export default {
  components: {
    WorkflowInput,
    RecurrencePicker,
    WorkflowFieldInfo,
    GoogleAccountSelect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      googleUserList, loadingUserGoogle, fetchUserGoogle,
      fetchCalendarColor, calendarEventColorList, loadingCalendarColor,
      fetchCalendarList, calendarList, loadingCalendarList,
      fetchCalendarEventList, calendarEventList, loadingCalendarEventList,
    } = useGoogle()
    const selectedToken = ref(null)
    const calendarInput = ref(null)
    const calendarForm = ref(null)
    const formData = ref({})
    const attendeeList = ref([])
    const attendeeCount = ref(1)
    const maxAttendee = ref(10)
    const selectedColor = ref(null)
    const recurrence = ref(null)
    const selectedVisibility = ref(null)
    const startDate = ref(null)
    const endDate = ref(null)
    const selectedCalendar = ref(null)
    const selectedEvent = ref(null)
    const paramSearchEvent = ref({})
    const selectedRecurrence = ref(null)
    const selectedOffset = ref(null)
    const selectedDuration = ref(null)

    const loadFormEdit = data => {
      // console.log(data)
      data.forEach(el => {
        // props untuk workflowInput
        if (el.property_id === 1007) selectedOffset.value = el.value
        if (el.property_id === 1006) selectedDuration.value = el.value
        if (el.property_id === 1002) selectedCalendar.value = el.value
        if (el.property_id === 1010) selectedRecurrence.value = el.value
        if (el.property_id === 1009) selectedColor.value = el.value
        if (el.property_id === 1011) selectedVisibility.value = el.value
        if (el.property_id === 1012) selectedEvent.value = el.value

        if (!formData.value[el.property_id]) formData.value[el.property_id] = []
        formData.value[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    const dateFormat = {
      day: 'numeric', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric',
    }
    const visibilityList = ref([
      {
        text: 'Default',
        value: 'default',
      },
      {
        text: 'Public',
        value: 'public',
      },
      {
        text: 'Private',
        value: 'private',
      },
      {
        text: 'Confidential',
        value: 'confidential',
      },
    ])
    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = googleUserList.value.find(el => el.id === props.token.id)
          loadFormEdit(props.props)
          fetchCalendarList(selectedToken.value.refresh_token)
          fetchCalendarColor(selectedToken.value.refresh_token)
          fetchCalendarEventList(selectedToken.value.refresh_token, selectedCalendar.value, paramSearchEvent.value).then(result => {
            calendarEventList.value = result.items
            paramSearchEvent.value.pageToken = result.nextPageToken
          })
        }
      })
    })

    const resetParamEvent = val => {
      paramSearchEvent.value = { maxResults: val }
    }

    const debouncedFetchEvent = useDebounceFn((val, fetchMore = false) => {
      if (val !== null) {
        if (!fetchMore) {
          resetParamEvent()
          paramSearchEvent.value.q = `${val.split(' ').join('* ')}*`
        }
        fetchCalendarEventList(selectedToken.value.refresh_token, selectedCalendar.value, paramSearchEvent.value).then(result => {
          calendarEventList.value = fetchMore ? [...calendarEventList.value, ...result.items] : result.items
          paramSearchEvent.value.pageToken = result.nextPageToken
        })
      }
    }, 750)

    const onIntersect = entry => {
      if (entry[0].isIntersecting) {
        debouncedFetchEvent(paramSearchEvent.value.q, true)
      }
      // const { scrollTop, clientHeight, scrollHeight } = e.target
      // if (scrollTop + clientHeight >= scrollHeight) {
      //   paramSearchEvent.value.maxResults += perPage.value
      //   fetchCalendarEventList(selectedToken.value.refresh_token, selectedCalendar.value, paramSearchEvent.value)
      // }
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const changeCalendar = val => {
      resetParamEvent()
      selectedEvent.value = null
      fetchCalendarEventList(selectedToken.value.refresh_token, val, paramSearchEvent.value)

      formData.value['1002'] = {
        parent_id: null,
        property_id: 1002,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeEvent = val => {
      formData.value['1012'] = {
        parent_id: null,
        property_id: 1012,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeOffset = val => {
      formData.value['1007'] = {
        parent_id: null,
        property_id: 1007,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeDuration = val => {
      formData.value['1006'] = {
        parent_id: null,
        property_id: 1006,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeRecurrence = val => {
      formData.value['1010'] = {
        parent_id: null,
        property_id: 1010,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeColor = val => {
      formData.value['1009'] = {
        parent_id: null,
        property_id: 1009,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeVisibility = val => {
      formData.value['1011'] = {
        parent_id: null,
        property_id: 1011,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      fetchCalendarList(val.refresh_token)
      fetchCalendarColor(val.refresh_token)
      selectedCalendar.value = null
      selectedEvent.value = null
    }

    const validate = () => {
      let isValid = true
      console.log(calendarInput.value)
      if (!calendarForm.value.validate()) isValid = false
      for (let i = 0; i < calendarInput.value.length; i += 1) {
        if (!calendarInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    return {
      googleUserList,
      loadingUserGoogle,
      selectedToken,
      calendarInput,
      calendarForm,
      formData,
      required,

      changeToken,
      onInput,
      validate,
      attendeeCount,
      attendeeList,
      maxAttendee,
      selectedColor,
      calendarEventColorList,
      loadingCalendarColor,
      recurrence,
      visibilityList,
      selectedVisibility,

      startDate,
      endDate,
      calendarList,
      loadingCalendarList,
      selectedCalendar,

      selectedEvent,
      calendarEventList,
      loadingCalendarEventList,
      changeCalendar,
      formatDate,
      dateFormat,
      debouncedFetchEvent,

      onIntersect,
      selectedRecurrence,
      selectedOffset,
      selectedDuration,

      changeEvent,
      changeOffset,
      changeDuration,
      changeRecurrence,
      changeColor,
      changeVisibility,
      validTimeOffset,
    }
  },
}
</script>
