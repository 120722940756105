<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="driveForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <google-account-select
            ref="googleUserSelect"
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang file-nya akan diawasi untuk mengecek event trigger',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <div
            v-if="selectedToken"
            :key="0"
            class="d-flex align-center"
          >
            <v-autocomplete
              v-model="selectedParent"
              :items="driveFolderList"
              item-text="name"
              item-value="id"
              flat
              attach
              outlined
              dense
              :loading="loadingListFolder"
              label="Folder"
              class="mt-4"
              hide-details="auto"
              placeholder="Pilih Folder"
              no-filter
              :rules="[required]"
              @update:search-input="(val) => debouncedFetchFolder(selectedToken.refresh_token, val)"
              @input="changeFolder"
            >
              <template #append-item>
                <div v-intersect="onIntersectFolder" />
              </template>
              <template #item="{ item }">
                <v-list-item-avatar>
                  <v-avatar color="#fef">
                    <v-img
                      :src="item.iconLink"
                      max-height="24px"
                      max-width="24px"
                    />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Parent Folder"
              :details="[
                'Folder yang digunakan untuk memilih file yang akan diawasi',
              ]"
            />
          </div>
          <div
            v-if="selectedParent"
            class="d-flex align-center"
          >
            <v-autocomplete
              v-model="selectedFile"
              :items="driveFileList"
              item-text="name"
              item-value="id"
              flat
              outlined
              attach
              dense
              :rules="[required]"
              :loading="loadingListFile"
              label="File"
              class="mt-4"
              hide-details="auto"
              placeholder="Pilih File"
              no-filter
              @update:search-input="(val) => debouncedFetchFile(selectedToken.refresh_token, val, null, selectedParent)"
              @input="updateFile"
            >
              <template #append-item>
                <div v-intersect="onIntersectFile" />
              </template>
              <template #item="{ item }">
                <v-list-item-avatar>
                  <v-avatar color="#fef">
                    <v-img
                      :src="item.iconLink"
                      max-height="24px"
                      max-width="24px"
                    />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="File"
              :details="[
                'File yang akan diawasi',
              ]"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import useGoogle from '@/composables/useGoogle'
import { useDebounceFn } from '@vueuse/core'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import { required } from '@core/utils/validation'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    GoogleAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    webhookIdentifier: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const {
      fetchUserGoogle, loadingUserGoogle, googleUserList,
      driveFolderList, loadingListFolder, fetchDriveFolderList, driveFolderNextPageToken,
      driveFileList, loadingListFile, fetchDriveFileList, driveFileNextPageToken,
    } = useGoogle()
    const selectedToken = ref(null)
    const selectedParent = ref(null)
    const selectedFile = ref(null)
    const driveForm = ref(null)
    const googleUserSelect = ref(null)

    onMounted(() => {
      fetchUserGoogle().then(() => {
        console.log(props.webhookIdentifier)
        if (props.value) {
          selectedToken.value = props.value
          fetchDriveFolderList(props.value.refresh_token, '').then(() => {
            selectedParent.value = driveFolderList.value.find(folder => folder.id === props.webhookIdentifier.parent_id).id
            console.log(driveFileList.value)
          })
          fetchDriveFileList(props.value.refresh_token, '', '', selectedParent.value).then(() => {
            selectedFile.value = driveFileList.value.find(file => file.id === props.webhookIdentifier.file_id).id
          })
        }
      })
    })

    const changeToken = val => {
      emit('input', val)
      selectedParent.value = null
      selectedFile.value = null
      fetchDriveFolderList(val.refresh_token, '')
    }

    const updateFile = () => {
      console.log({ parent_id: selectedParent.value, file_id: selectedFile.value })
      emit('updateWebhook', { parent_id: selectedParent.value, file_id: selectedFile.value })
    }

    const validate = () => {
      let isValid = true
      if (!driveForm.value.validate()) isValid = false
      if (!googleUserSelect.value.validate()) isValid = false

      return isValid
    }

    const changeFolder = val => {
      selectedFile.value = null
      fetchDriveFileList(selectedToken.value.refresh_token, '', '', val)
      emit('updateWebhook', { parent_id: selectedParent.value, file_id: selectedFile.value })
    }

    const debouncedFetchFolder = useDebounceFn((token, search, pageToken) => {
      if (token) fetchDriveFolderList(token, search, pageToken)
    }, 750)

    const onIntersectFolder = entry => {
      if (entry[0].isIntersecting && driveFolderList.value.length > 0) {
        if (driveFolderNextPageToken.value) {
          debouncedFetchFolder(selectedToken.value.refresh_token, '', driveFolderNextPageToken.value)
        }
      }
    }

    const debouncedFetchFile = useDebounceFn((token, search, pageToken, parentId) => {
      if (token) fetchDriveFileList(token, search, pageToken, parentId)
    }, 750)

    const onIntersectFile = entry => {
      if (entry[0].isIntersecting && driveFileList.value.length > 0) {
        if (driveFileNextPageToken.value) {
          debouncedFetchFile(selectedToken.value.refresh_token, '', driveFileNextPageToken.value, selectedParent.value)
        }
      }
    }

    return {
      selectedToken,
      selectedParent,

      loadingUserGoogle,
      googleUserList,
      driveFolderList,

      debouncedFetchFolder,
      debouncedFetchFile,
      onIntersectFolder,
      loadingListFolder,
      selectedFile,
      driveFileList,
      loadingListFile,

      changeToken,
      changeFolder,
      onIntersectFile,
      required,
      updateFile,
      driveForm,
      validate,
      googleUserSelect,
    }
  },
}
</script>
