<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="whatsappForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template v-if="!loadingEdit">
              <workflow-input
                ref="whatsappInput"
                :key="1"
                v-model="formData['1501']"
                :property-id="1501"
                placeholder="Kepada"
                :variables="options"
                class="my-4"
                required
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Kepada"
                    :details="[
                      'Nomor Tujuan yang akan dikirimi pesan',
                      'Contoh: 6281234567890'
                    ]"
                  />
                </template>
              </workflow-input>
              <workflow-input
                ref="whatsappInput"
                :key="2"
                v-model="formData['1502']"
                :property-id="1502"
                placeholder="Text"
                :variables="options"
                class="my-4"
                required
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Text"
                    :details="[
                      'Isi dari pesan yang akan dikirim',
                    ]"
                  />
                </template>
              </workflow-input>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import WorkflowInput from '../../components/WorkflowInput.vue'
import { isNumber } from '@/@core/utils/filter'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    WorkflowInput,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const whatsappForm = ref(null)
    const whatsappInput = ref(null)
    const loadingEdit = ref(true)
    const formData = ref({})

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput

        const formDataIndex = el.property_id
        if (!formData.value[formDataIndex]) formData.value[formDataIndex] = []
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
        console.log(formData.value[formDataIndex])
      })
      console.log(formData.value)
      loadingEdit.value = false
    }

    onMounted(() => {
      loadFormEdit(props.props)
    })

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      console.log(whatsappInput.value)
      if (!whatsappForm.value.validate()) isValid = false
      for (let i = 0; i < whatsappInput.value.length; i += 1) {
        if (!whatsappInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    return {
      whatsappForm,
      whatsappInput,
      validate,
      formData,
      onInput,
      loadingEdit,
    }
  },
}
</script>
