import { render, staticRenderFns } from "./AccurateAddSalesOrderForm.vue?vue&type=template&id=1b12d4e8&"
import script from "./AccurateAddSalesOrderForm.vue?vue&type=script&lang=js&"
export * from "./AccurateAddSalesOrderForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports