import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"checkListForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('trello-account-select',{ref:"trelloUserSelect",refInFor:true,attrs:{"loading":_vm.loadingUserTrello,"items":_vm.trelloUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Trello","details":[
                'Akun Trello yang akan digunakan untuk menambah checklist ke card',
                'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"property-id":901,"items":_vm.trelloBoardList,"item-text":"name","item-value":"id","flat":"","attach":"","outlined":"","dense":"","disabled":!_vm.selectedToken,"loading":_vm.loadingBoardTrello,"label":"Board","hide-details":"auto","placeholder":"Pilih Board","rules":[_vm.required]},on:{"input":_vm.changeBoard},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedBoard),callback:function ($$v) {_vm.selectedBoard=$$v},expression:"selectedBoard"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Board","details":[
              'Board yang akan digunakan untuk memilih card yang akan ditambahkan checklist' ]}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloListList,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","attach":"","loading":_vm.loadingListTrello,"label":"List","disabled":!_vm.selectedBoard,"property-id":902,"rules":[_vm.required],"hide-details":"auto","placeholder":"Pilih List"},on:{"change":_vm.changeList},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"List","details":[
              'List yang akan digunakan untuk memilih card yang akan ditambahkan checklist' ]}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloCardList,"item-text":"name","item-value":"id","flat":"","outlined":"","attach":"","dense":"","loading":_vm.loadingCardTrello,"label":"Card","disabled":!_vm.selectedList,"property-id":911,"rules":[_vm.required],"hide-details":"auto","placeholder":"Pilih Card"},on:{"change":_vm.changeCard},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedCard),callback:function ($$v) {_vm.selectedCard=$$v},expression:"selectedCard"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Card","details":[
              'Checklist akan ditambahkan ke card ini' ]}})],1),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedCard)?[_c('workflow-input',{key:1,ref:"checkListInput",refInFor:true,staticClass:"mt-4",attrs:{"placeholder":"Nama Checklist","variables":_vm.options,"property-id":915},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama Checklist","details":[
                    'Nama dari Checklist yang akan ditambahkan' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['915']),callback:function ($$v) {_vm.$set(_vm.formData, '915', $$v)},expression:"formData['915']"}}),_c('workflow-input',{key:"2",ref:"checkListInput",refInFor:true,staticClass:"my-4",attrs:{"placeholder":"Item Checklist","variables":_vm.options,"property-id":916},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Item Checklist","details":[
                    'Name dari item Checklist yang akan ditambahkan',
                    'Jika ada lebih dari 1 item, Pisahkan dengan tanda koma (,)' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['916']),callback:function ($$v) {_vm.$set(_vm.formData, '916', $$v)},expression:"formData['916']"}})]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }