<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="1200"
    scrollable
  >
    <v-card>
      <div class="d-flex align-start justify-space-between pa-4">
        <div class="d-flex flex-column">
          <span class="text-h6 font-medium primary--text">{{ isEdit ? 'Edit' : 'Buat' }} Workflow</span>
          <span class="text-subtitle-2">Desain dan atur workflow mu sendiri</span>
        </div>
        <v-icon
          @click="closeForm"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-divider />
      <v-card-text class="pa-0 pt-2">
        <v-form
          v-if="!loadingDetailWorkflow"
          ref="formWorkflow"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <div class="pa-4 pt-2">
            <v-row dense>
              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  v-model="trigger.name"
                  label="Nama Workflow"
                  placeholder="Input nama workflow"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[required]"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="pl-2"
              >
                <router-link
                  :to="{ name: 'guide' }"
                >
                  <v-btn
                    text
                    outlined
                    block
                    color="primary"
                  >
                    Cara Penggunaan
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
            <v-row
              dense
              class="mt-2"
            >
              <v-col
                cols="12"
                md="9"
              >
                <span class="text-subtitle-2">Alur Workflow</span>
                <v-card
                  class="mt-2"
                  height="60vh"
                  outlined
                >
                  <div class="workflow-window h-full">
                    <div class="ma-3">
                      <v-card
                        outlined
                      >
                        <v-card-title class="white--text pa-3">
                          <v-chip
                            label
                            large
                          >
                            <v-icon
                              class="mx-1"
                              size="24"
                            >
                              {{ icons.mdiContainStart }}
                            </v-icon>
                          </v-chip>
                          <p class="ml-4 mb-0">
                            Trigger
                            <workflow-field-info
                              title="Trigger"
                              :details="[
                                ' Trigger adalah aksi yang akan menjadi pemicu workflow dijalankan.'
                              ]"
                            />
                          </p>
                        </v-card-title>
                        <v-divider />
                        <v-card-text class="pa-3">
                          <v-row>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-select
                                v-model="mainObject"
                                outlined
                                flat
                                hide-details="auto"
                                label="Objek"
                                placeholder="Objek yang ingin dijadikan trigger"
                                :items="objectList"
                                item-text="name"
                                item-value="id"
                                return-object
                                required
                                @change="changeObject"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-select
                                v-model="trigger.action"
                                :items="actionList"
                                outlined
                                flat
                                hide-details="auto"
                                label="Aksi"
                                placeholder="Aksi dari objek yang dijadikan trigger"
                                item-text="name_view"
                                item-value="id"
                                return-object
                                required
                                :loading="loadingAction"
                                :disabled="!mainObject"
                                @change="changeMainAction"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <div
                          v-if="trigger.action"
                          class="align-start px-3 mb-3"
                        >
                          <XyzTransition
                            appear
                            xyz="fade in-up-2 in-delay-2 in-stagger-1"
                          >
                            <template v-if="trigger.action.component_name">
                              <component
                                :is="trigger.action.component_name"
                                ref="filterAPI"
                                v-model="trigger.token"
                                :webhook-identifier="trigger.webhook_filter"
                                @updateWebhook="updateWebhook"
                              />
                            </template>
                          </XyzTransition>
                        </div>
                      </v-card>
                      <XyzTransitionGroup
                        appear
                        xyz="fade down-4 in-delay-2 in-stagger-1"
                      >
                        <div
                          v-if="trigger.action"
                          :key="0"
                          class="d-flex justify-center"
                        >
                          <v-icon
                            class="mx-1"
                            size="28"
                          >
                            {{ icons.mdiSourceCommit }}
                          </v-icon>
                        </div>
                        <v-card
                          v-if="trigger.action"
                          :key="1"
                          outlined
                        >
                          <v-skeleton-loader
                            v-if="loadingTriggerProperty"
                            :key="1"
                            type="list-item-avatar-three-line"
                          />
                          <template
                            v-else
                          >
                            <v-card-title class="white--text pa-3 d-flex justify-space-between">
                              <div class="d-flex align-center">
                                <v-chip
                                  label
                                  large
                                >
                                  <v-icon
                                    class="mx-1"
                                    size="24"
                                  >
                                    {{ icons.mdiFilterOutline }}
                                  </v-icon>
                                </v-chip>

                                <p class="ml-4 mb-0">
                                  Filter (Optional)
                                </p>
                                <workflow-field-info
                                  title="Filter"
                                  :details="[
                                    'Filter adalah kondisi yang akan menentukan apakah workflow akan dijalankan atau tidak.',
                                    'Semua filter harus terpenuhi agar workflow dijalankan.'
                                  ]"
                                />
                              </div>
                              <v-btn
                                color="primary"
                                outlined
                                icon
                                class="me-1"
                                @click="addFilter"
                              >
                                <v-icon size="30">
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-divider class="pb-3" />
                            <XyzTransition
                              v-for="(filter, index) in filterList"
                              :key="index"
                              appear
                              xyz="fade left-4 duration-4"
                            >
                              <div class="pb-3 px-3">
                                <v-card
                                  outlined
                                  class=""
                                >
                                  <div
                                    class="d-flex align-center justify-space-between pa-2"
                                  >
                                    <div class="d-flex align-center">
                                      <v-icon
                                        size="22"
                                        class="mr-2 ms-1 text--disabled"
                                      >
                                        {{ icons.mdiTuneVertical }}
                                      </v-icon>
                                      <workflow-filter-options
                                        ref="filterPropList"
                                        v-model="filter.trigger_property"
                                        :options="triggerPropertyList"
                                        @click="filter.operator = null"
                                      >
                                        <v-chip
                                          label
                                          class="mr-2"
                                          medium
                                        >
                                          {{ filter.trigger_property ? filter.trigger_property.name : "Pilih Property" }}
                                        </v-chip>
                                      </workflow-filter-options>
                                      <workflow-filter-options
                                        v-if="filter.trigger_property"
                                        ref="filterOperatorList"
                                        v-model="filter.operator"
                                        appear
                                        xyz="fade left-4 duration-4"
                                        :options="operatorList.filter(el => el.data_type.id === filter.trigger_property.data_type.id)"
                                      >
                                        <v-chip
                                          label
                                          class="mr-2"
                                        >
                                          {{ filter.operator ? filter.operator.value : "Pilih Operator" }}
                                        </v-chip>
                                      </workflow-filter-options>
                                      <workflow-filter-field
                                        v-if="filter.trigger_property"
                                        v-model="filter.value"
                                        :filter="filter"
                                        :items="filter.trigger_property.options"
                                      />
                                    </div>

                                    <v-chip
                                      label
                                      class="px-2 ms-3"
                                      @click="removeFilter(filter)"
                                    >
                                      <v-icon size="20">
                                        {{ icons.mdiClose }}
                                      </v-icon>
                                    </v-chip>
                                  </div>
                                </v-card>
                              </div>
                            </XyzTransition>
                          </template>
                        </v-card>
                        <div
                          v-if="trigger.action"
                          :key="2"
                          class="d-flex justify-center"
                        >
                          <v-icon
                            class="mx-1"
                            size="28"
                          >
                            {{ icons.mdiSourceCommit }}
                          </v-icon>
                        </div>
                        <v-card
                          v-if="trigger.action"
                          :key="3"
                          outlined
                          class="mb-3"
                        >
                          <v-skeleton-loader
                            v-if="loadingTriggerProperty"
                            :key="1"
                            type="article"
                          />
                          <template
                            v-else
                          >
                            <v-card-title class="white--text pa-3 d-flex justify-space-between">
                              <div class="d-flex align-center">
                                <v-chip
                                  label
                                  large
                                >
                                  <v-icon
                                    class="mx-1"
                                    size="24"
                                  >
                                    {{ icons.mdiContainEnd }}
                                  </v-icon>
                                </v-chip>

                                <p class="ml-4 mb-0">
                                  Action
                                </p>
                                <workflow-field-info
                                  title="Action"
                                  :details="[
                                    'Action adalah aksi / event yang akan dilakukan ketika workflow dijalankan.'
                                  ]"
                                />
                              </div>
                              <v-btn
                                v-if="executorList.length < 3"
                                color="primary"
                                outlined
                                icon
                                class="me-1"
                                @click="addExecutor"
                              >
                                <v-icon size="30">
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-divider class="pb-3" />
                            <XyzTransition
                              v-for="(executor, index) in executorList"
                              :key="index"
                              appear
                              xyz="fade left-4 duration-4"
                            >
                              <v-card
                                outlined
                                class="mx-3 mb-3"
                              >
                                <div class="d-flex justify-end">
                                  <v-chip
                                    label
                                    class="px-2 mt-2 me-2"
                                    @click="removeExecutor(index)"
                                  >
                                    <v-icon size="20">
                                      {{ icons.mdiClose }}
                                    </v-icon>
                                  </v-chip>
                                </div>

                                <v-card-text class="pa-3">
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="6"
                                    >
                                      <v-select
                                        v-model="executor.object"
                                        flat
                                        outlined
                                        :items="objectList"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Pilih objek"
                                        hide-details="auto"
                                        return-object
                                        @change="(val) => changeExecutorObject(index, val)"
                                      />
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      md="6"
                                    >
                                      <v-select
                                        v-model="executor.action"
                                        flat
                                        :disabled="!executor.object"
                                        outlined
                                        :items="executorActionTemp[index]"
                                        item-text="name_view"
                                        item-value="id"
                                        placeholder="Pilih Aksi"
                                        hide-details="auto"
                                        return-object
                                        :loading="loadingExecutorAction[index]"
                                        @change="changeExecutorAction(index)"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-divider class="mb-2" />
                                <component
                                  :is="executor.action.component_name"
                                  v-if="!isEdit && executor.action || isEdit && executor.action && executor.props"
                                  :key="formKey"
                                  ref="executorForms"
                                  v-model="executor.props"
                                  :props="executor.props"
                                  :token="executor.token"
                                  :options="triggerPropertyList"
                                  :index="index"
                                  class="pa-3"
                                  @changeToken="changeToken"
                                />
                                <div
                                  v-if="executor.action"
                                  class="mt-2"
                                />
                              </v-card>
                            </XyzTransition>
                          </template>
                        </v-card>
                      </XyzTransitionGroup>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="pl-md-2"
              >
                <span class="text-subtitle-2">Ringkasan</span>
                <XyzTransitionGroup
                  appear
                  xyz="fade right-2 duration-4"
                  class="summary-action"
                >
                  <v-card
                    v-for="(summary, index) in workflowSummary"
                    :key="index"
                    class="pa-2 mt-2 d-flex align-center"
                    outlined
                  >
                    <v-chip
                      class="mr-2 px-2 font-medium"
                      x-small
                    >
                      {{ index + 1 }}
                    </v-chip>
                    <v-chip
                      small
                      label
                      outlined
                      color="primary"
                    >
                      {{ title(summary.type) }}
                    </v-chip>
                    <span class="text-caption primary--text ms-2">{{ summary.name }}</span>
                  </v-card>
                </XyzTransitionGroup>
              </v-col>
            </v-row>
            <div class="d-flex flex-column flex-md-row mt-4">
              <v-btn
                class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
                outlined
                @click="resetForm"
              >
                Reset
              </v-btn>
              <v-btn
                color="primary"
                :loading="loadingSubmitWorkflow"
                :disabled="loadingSubmitWorkflow"
                @click="submitForm"
              >
                Simpan Workflow
              </v-btn>
            </div>
          </div>
        </v-form>
        <div
          v-else
          class="d-flex justify-center align-center"
          style="height: 600px"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@core/utils/validation'
import { onMounted, ref, computed } from '@vue/composition-api'
import {
  mdiClose, mdiPlus,
  mdiTuneVertical,
  mdiContainStart, mdiContainEnd, mdiFilterOutline,
  mdiSourceCommit,
} from '@mdi/js'
import WorkflowFilterField from './WorkflowFilterField.vue'

import useAutomationV2 from '@/composables/useAutomation'
import WorkflowFilterOptions from './components/WorkflowFilterOptions.vue'

import WorkflowCreateTaskForm from './forms/task/WorkflowCreateTaskForm.vue'
import WorkflowUpdateTaskForm from './forms/task/WorkflowUpdateTaskForm.vue'
import WorkflowDeleteTaskForm from './forms/task/WorkflowDeleteTaskForm.vue'

// import WorkflowCreateJobForm from './forms/job/WorkflowCreateJobForm.vue'
// import WorkflowUpdateJobForm from './forms/job/WorkflowUpdateJobForm.vue'
// import WorkflowDeleteJobForm from './forms/job/WorkflowDeleteJobForm.vue'

import WorkflowCreateJobForm from './forms/job/WorkflowCreateJobForm.vue'
import WorkflowUpdateJobForm from './forms/job/WorkflowUpdateJobForm.vue'
import WorkflowDeleteJobForm from './forms/job/WorkflowDeleteJobForm.vue'

import WorkflowCreateProductForm from './forms/product/WorkflowCreateProductForm.vue'
import WorkflowUpdateProductForm from './forms/product/WorkflowUpdateProductForm.vue'
import WorkflowDeleteProductForm from './forms/product/WorkflowDeleteProductForm.vue'

import TrelloWebhookFilter from './forms/trello/TrelloWebhookFilter.vue'
import TrelloCreateCardForm from './forms/trello/TrelloCreateCardForm.vue'
import TrelloUpdateCardForm from './forms/trello/TrelloUpdateCardForm.vue'
import TrelloDeleteCardForm from './forms/trello/TrelloDeleteCardForm.vue'
import TrelloCreateBoardForm from './forms/trello/TrelloCreateBoardForm.vue'
import TrelloAddChecklistCardForm from './forms/trello/TrelloAddChecklistCardForm.vue'
import TrelloCreateListForm from './forms/trello/TrelloCreateListForm.vue'
import TrelloCreateLabelForm from './forms/trello/TrelloCreateLabelForm.vue'

import GmailSendEmailForm from './forms/gmail/GmailSendEmailForm.vue'
import GmailEmailReceivedFilter from './forms/gmail/GmailEmailReceivedFilter.vue'

import CalendarCreateCalendarForm from './forms/google-calendar/CalendarCreateCalendarForm.vue'
import CalendarCreateEventForm from './forms/google-calendar/CalendarCreateEventForm.vue'
import CalendarUpdateEventForm from './forms/google-calendar/CalendarUpdateEventForm.vue'
import CalendarDeleteEventForm from './forms/google-calendar/CalendarDeleteEventForm.vue'
import CalendarEventFilter from './forms/google-calendar/CalendarEventFilter.vue'

import DriveUploadFileForm from './forms/google-drive/DriveUploadFileForm.vue'
import DriveReplaceFileForm from './forms/google-drive/DriveReplaceFileForm.vue'
import DriveCreateFolderForm from './forms/google-drive/DriveCreateFolderForm.vue'
import DriveFileChangedFilter from './forms/google-drive/DriveFileChangedFilter.vue'

import SheetCreateSpreadsheetForm from './forms/google-sheet/SheetCreateSpreadsheetForm.vue'
import SheetCreateWorksheetForm from './forms/google-sheet/SheetCreateWorksheetForm.vue'
import SheetCreateWorksheetRowForm from './forms/google-sheet/SheetCreateWorksheetRowForm.vue'
import SheetUpdateWorksheetRowForm from './forms/google-sheet/SheetUpdateWorksheetRowForm.vue'
import SheetDeleteWorksheetRowForm from './forms/google-sheet/SheetDeleteWorksheetRowForm.vue'

// import AccurateAddExpenseForm from './forms/accurate/AccurateAddExpenseForm.vue'
// import AccurateDeleteExpenseForm from './forms/accurate/AccurateDeleteExpenseForm.vue'
// import AccurateAddFobForm from './forms/accurate/AccurateAddFobForm.vue'
// import AccurateDeleteFobForm from './forms/accurate/AccurateDeleteFobForm.vue'
import AccurateAddSalesOrderForm from './forms/accurate/AccurateAddSalesOrderForm.vue'

import WhatsappSendMessageForm from './forms/whatsapp/WhatsappSendMessageForm.vue'

import TokopediaFilter from './forms/tokopedia/TokopediaFilter.vue'
import TokopediaOrderStatusFilter from './forms/tokopedia/TokopediaOrderStatusFilter.vue'

import TelegramSendMessageForm from './forms/telegram/TelegramSendMessageForm.vue'
import TelegramFilter from './forms/telegram/TelegramFilter.vue'

import store from '@/store'
import Vue from 'vue'
import useTrello from '@/composables/useTrello'
import useGoogle from '@/composables/useGoogle'
import WorkflowFieldInfo from './components/WorkflowFieldInfo.vue'
import { title } from '@/@core/utils/filter'

export default {
  components: {
    WorkflowFieldInfo,
    WorkflowFilterField,
    WorkflowFilterOptions,
    // TaskForm,
    WorkflowCreateTaskForm,
    WorkflowUpdateTaskForm,
    WorkflowDeleteTaskForm,

    WorkflowCreateJobForm,
    WorkflowUpdateJobForm,
    WorkflowDeleteJobForm,

    WorkflowCreateProductForm,
    WorkflowUpdateProductForm,
    WorkflowDeleteProductForm,

    TrelloWebhookFilter,
    TrelloCreateCardForm,
    TrelloUpdateCardForm,
    TrelloDeleteCardForm,
    TrelloCreateBoardForm,
    TrelloAddChecklistCardForm,
    TrelloCreateListForm,
    TrelloCreateLabelForm,

    CalendarCreateCalendarForm,
    CalendarCreateEventForm,
    CalendarUpdateEventForm,
    CalendarDeleteEventForm,
    CalendarEventFilter,

    DriveUploadFileForm,
    DriveReplaceFileForm,
    DriveCreateFolderForm,
    DriveFileChangedFilter,

    SheetCreateSpreadsheetForm,
    SheetCreateWorksheetForm,
    SheetCreateWorksheetRowForm,
    SheetUpdateWorksheetRowForm,
    SheetDeleteWorksheetRowForm,

    // AccurateAddExpenseForm,
    // AccurateDeleteExpenseForm,
    // AccurateAddFobForm,
    // AccurateDeleteFobForm,
    AccurateAddSalesOrderForm,

    WhatsappSendMessageForm,

    GmailSendEmailForm,
    GmailEmailReceivedFilter,

    TokopediaFilter,
    TokopediaOrderStatusFilter,

    TelegramSendMessageForm,
    TelegramFilter,
  },
  setup(props, { emit }) {
    const formKey = ref(0)
    const isEdit = ref(false)
    const editData = ref({})
    const {
      objectList,
      actionList,
      propertyList,
      operatorList,

      fetchWorkflowDetailV2,
      loadingDetailWorkflow,
      fetchAutomationObjectsV2,
      fetchTriggerActionsV2,
      fetchExecutorActionsV2,
      fetchObjectPropertiesV2,
      fetchOperatorsV2,

      loadingAction,

      createWorkflow,
      updateWorkflow,

      loadingSubmitWorkflow,

      fetchTriggerPropertiesV2,
      triggerPropertyList,
      loadingTriggerProperty,

    } = useAutomationV2()
    const { fetchUserTrello, loadingUserTrello } = useTrello()
    const { fetchUserGoogle, loadingUserGoogle } = useGoogle()

    const userList = ref([])
    const loadingUser = computed(() => {
      if (loadingUserTrello.value) return true
      if (loadingUserGoogle.value) return true

      return false
    })

    onMounted(() => {
      fetchAutomationObjectsV2()
      fetchObjectPropertiesV2()
      fetchOperatorsV2()
    })

    const filterPropList = ref(null)
    const filterOperatorList = ref(null)

    const isOpen = ref(false)
    const formWorkflow = ref(null)
    const filterAPI = ref(null)
    const executorForms = ref([])
    const show = () => {
      isOpen.value = true
      isEdit.value = false
    }

    const trigger = ref({
      name: '',
      action: null,
      workspace_id: store.getters.getCurrentWorkspaceId,
    })
    const mainObject = ref(null)
    const executorList = ref([])
    const filterList = ref([])
    const executorActionTemp = ref([])
    const propertyTemp = ref([])
    const loadingExecutorAction = ref([])

    const changeObject = val => {
      // reset action trigger nya
      trigger.value.action = null
      trigger.value.token = null
      actionList.value = []
      // reset filter yang ada
      filterList.value = []

      // filter property yang bisa dijadikan filter
      if (val.platform) {
        if (val.platform.name === 'Trello') {
          fetchUserTrello().then(result => {
            userList.value = result
          })
        } else if (val.platform.name === 'Google') {
          fetchUserGoogle().then(result => {
            userList.value = result
          })
        }
      }
      fetchTriggerActionsV2(val.id)
    }

    const changeMainAction = val => {
      // reset filter & executorForms
      filterList.value = []
      executorList.value = []
      fetchTriggerPropertiesV2(val.id)
    }

    const changeExecutorObject = (index, val) => {
      // menghapus property dari objek sebelumnya
      if (executorList.value[index]) {
        executorList.value[index].action = null
        executorList.value[index].props = []
        executorList.value[index].token = null
        executorList.value[index].token_id = null
      }
      // filter action yang bisa dijadikan action executor
      // executorActionTemp.value[index] = actionList.value.filter(el => el.object.id === val.id && el.category.id === 2)
      executorActionTemp.value[index] = []
      // load action executor
      loadingExecutorAction.value[index] = true
      fetchExecutorActionsV2(val.id, trigger.value.action.trigger_category.id).then(result => {
        loadingExecutorAction.value.splice(index, 1, false)
        executorActionTemp.value[index] = result
      })
    }

    const changeExecutorAction = index => {
      // menghapus property dari objek sebelumnya
      if (executorList.value[index]) {
        executorList.value[index].props = []
        executorList.value[index].token = null
        executorList.value[index].token_id = null
      }
    }

    const addExecutor = () => {
      // menambah executornya
      if (executorList.value.length < 3) {
        executorList.value.push({
          action: null,
          object: null,
          token_id: null,
          props: [],
        })

        loadingExecutorAction.value.push(false)
      }
    }

    const addFilter = () => {
      // menambah list filter
      filterList.value.push({
        trigger_property: null,
        operator: null,
        value: null,
      })
    }

    const removeExecutor = index => {
      // menghapus index dari list executor
      executorList.value.splice(index, 1)
      loadingExecutorAction.value.splice(index, 1)
    }

    const removeFilter = id => {
      // menghapus index dari list filter
      filterList.value = filterList.value.filter(el => el !== id)
    }

    const workflowSummary = computed(() => [
      { type: 'trigger', name: trigger.value.action?.name },
      ...filterList.value.map(el => ({ type: 'filter', name: el.trigger_property?.name })),
      ...executorList.value.map(el => ({ type: 'action', name: el.action?.name })),
    ])
    const resetForm = () => {
      trigger.value = {
        name: '',
        action: null,
        workspace_id: store.getters.getCurrentWorkspaceId,
      }
      filterList.value = []
      executorList.value = []
      formWorkflow.value.reset()
    }
    const closeForm = () => {
      isOpen.value = false
      resetForm()
    }
    const validateAll = () => {
      let isValid = true

      if (!formWorkflow.value.validate()) return false
      if (filterAPI.value && !filterAPI.value.validate()) return false
      if (trigger.value.action === null) {
        isValid = false
      }
      // filterPropList.value.forEach(el => {
      //   console.log(el.validate())
      //   isValid = el.validate()
      // })
      if (filterList.value.length > 0) {
        for (let i = 0; i < filterList.value.length; i += 1) {
          if (filterList.value[i].trigger_property === null || filterList.value[i].operator === null || filterList.value[i].value === null) {
            isValid = false
          }
        }
      }
      if (executorList.value.length > 0) {
        for (let i = 0; i < executorList.value.length; i += 1) {
          if (executorList.value[i].object === null || executorList.value[i].action === null) {
            isValid = false
          }
        }
      } else {
        isValid = false
      }
      for (let i = 0; i < executorForms.value.length; i += 1) {
        console.log(executorForms.value)
        if (!executorForms.value[i].validate()) {
          isValid = false
        }
      }

      return isValid
    }
    const submitForm = () => {
      if (validateAll()) {
        console.log(trigger.value.webhook_filter)
        const paramTrigger = {
          name: trigger.value.name,
          workspace_id: trigger.value.workspace_id,
          action_id: trigger.value.action.id,
          token_id: +trigger.value.token?.id,
          webhook_filter: trigger.value.webhook_filter ? {
            file_id: trigger.value.webhook_filter.file_id,
            parent_id: trigger.value.webhook_filter.parent_id,
            email: trigger.value.webhook_filter.email,
            calendar_id: trigger.value.webhook_filter.calendar_id,
            board_id: trigger.value.webhook_filter.board_id,
          } : null,
        }
        const paramFilter = filterList.value.map(el => ({
          operator_id: el.operator.id,
          property_id: el.trigger_property.id,
          value: el.value.toString(),
        }))
        const paramExecutor = executorList.value.map(el => ({
          action_id: el.action.id,
          props: el.props.map(item => ({
            ...item, value: Array.isArray(item.value) ? JSON.stringify(item.value) : item.value?.toString(),
          })),
          token_id: el.token_id ? +el.token_id : null,
        }))
        console.log(paramTrigger)
        console.log(paramFilter)
        console.log(paramExecutor)
        if (isEdit.value) {
          updateWorkflow({
            trigger_id: editData.value.id,
            trigger: paramTrigger,
            filters: paramFilter,
            executors: paramExecutor,
          }).then(() => {
            emit('onSubmitWorkflow')
            closeForm()
          })
        } else {
          createWorkflow({
            trigger: paramTrigger,
            filters: paramFilter,
            executors: paramExecutor,
          }).then(() => {
            emit('onSubmitWorkflow')
            closeForm()
          })
        }
      } else {
        Vue.notify({
          type: 'error',
          title: 'Error',
          text: 'Pastikan semua field sudah terisi',
        })
      }
    }

    const changeToken = (val, index) => {
      executorList.value[index].token_id = val
    }

    const updateWebhook = val => {
      trigger.value.webhook_filter = val
    }

    const getWebhookIdentifier = data => {
      if (data.gmail_webhook) return data.gmail_webhook
      if (data.drive_webhook) return data.drive_webhook
      if (data.calendar_webhook) return data.calendar_webhook
      if (data.trello_webhook) return data.trello_webhook

      return {}
    }

    const resetEdit = data => {
      console.log(data)
      // formKey.value += 1
      console.log(trigger.value)
      trigger.value.name = data.name
      console.log(data)
      console.log(getWebhookIdentifier(data))
      trigger.value.webhook_filter = getWebhookIdentifier(data)
      mainObject.value = data.action.object
      changeObject(data.action.object)
      trigger.value.action = data.action
      fetchTriggerPropertiesV2(trigger.value.action.id)
      trigger.value.token = data.token
      filterList.value = data.filters
      data.executors.forEach((el, index) => {
        changeExecutorObject(index, el.action.object)
        executorList.value.push({
          object: el.action.object,
          id: el.id,
          token: el.token,
          props: el.property_values.map(val => ({
            property_id: val.property.id,
            parent_id: val.parent?.id,
            value: val.value,
            sort: val.sort,
            identifier: val.identifier,
          })),
          action: el.action,
        })
        console.log(executorList.value)
      })
    }

    const showEdit = id => {
      isOpen.value = true
      isEdit.value = true
      // fetch workflow by id
      fetchWorkflowDetailV2(id).then(value => {
        editData.value = value
        resetEdit(value)
      })
    }

    return {
      isEdit,
      isOpen,

      formKey,

      show,
      showEdit,
      closeForm,
      submitForm,
      executorActionTemp,
      propertyTemp,
      mainObject,
      objectList,
      actionList,
      propertyList,
      operatorList,
      executorList,
      filterList,

      removeFilter,
      changeObject,
      addExecutor,
      addFilter,
      removeExecutor,
      changeExecutorObject,
      workflowSummary,

      resetForm,
      resetEdit,
      editData,

      icons: {
        mdiClose,
        mdiPlus,
        mdiTuneVertical,
        mdiContainStart,
        mdiFilterOutline,
        mdiContainEnd,
        mdiSourceCommit,
      },
      trigger,
      loadingSubmitWorkflow,
      formWorkflow,
      executorForms,
      required,
      changeToken,

      loadingUser,
      userList,
      updateWebhook,
      filterAPI,
      changeExecutorAction,

      loadingAction,
      loadingExecutorAction,

      triggerPropertyList,
      loadingTriggerProperty,

      changeMainAction,

      filterPropList,
      filterOperatorList,
      loadingDetailWorkflow,
      title,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.workflow-window {
  @include style-scroll-bar();
  overflow: auto !important;
}

.workflow-options-window {
  @include style-scroll-bar();
  overflow: auto !important;
}

.workflow-flex > * {
  flex-shrink: 0;
}

.summary-action {
  max-height: 60vh;
  overflow: auto;
  overflow-x: hidden;
  @include style-scroll-bar();
}
</style>
