<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="tokopediaForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <tokopedia-account-select
            v-model="selectedToken"
            @input="changeToken"
          />
          <!-- <tokopedia-order-status-select
            @input="updateStatus"
          /> -->
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import TokopediaAccountSelect from './components/TokopediaAccountSelect.vue'
// import TokopediaOrderStatusSelect from './components/TokopediaOrderStatusSelect.vue'
import { onMounted, ref } from '@vue/composition-api'

export default {
  components: {
    TokopediaAccountSelect,
    // TokopediaOrderStatusSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const changeToken = val => {
      emit('input', val)
    }

    const selectedToken = ref(null)

    // const updateStatus = val => {
    //   emit('updateWebhook', val)
    // }

    onMounted(() => {
      if (props.value) {
        selectedToken.value = props.value
      }
    })

    const tokopediaForm = ref(null)

    const validate = () => {
      let isValid = true
      if (!tokopediaForm.value.validate()) isValid = false

      return isValid
    }

    return {
      changeToken,
      validate,
      selectedToken,
      tokopediaForm,
      // updateStatus,
    }
  },
}
</script>
