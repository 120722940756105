import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"labelForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('trello-account-select',{ref:"trelloUserSelect",refInFor:true,attrs:{"loading":_vm.loadingUserTrello,"items":_vm.trelloUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Trello","details":[
                'Akun Trello yang akan digunakan untuk membuat label',
                'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloBoardList,"property-id":922,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","attach":"","disabled":!_vm.selectedToken,"loading":_vm.loadingBoardTrello,"label":"Board","hide-details":"auto","placeholder":"Pilih Board","rules":[_vm.required]},on:{"change":_vm.changeBoard},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedBoard),callback:function ($$v) {_vm.selectedBoard=$$v},expression:"selectedBoard"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Board","details":[
              'Board yang akan digunakan sebagai tempat membuat label' ]}})],1),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedBoard)?[_c('workflow-input',{key:1,ref:"labelInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":923,"placeholder":"Nama Label","variables":_vm.options,"required":true},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama Label","details":[
                    'Nama dari label yang akan dibuat.' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['923']),callback:function ($$v) {_vm.$set(_vm.formData, '923', $$v)},expression:"formData['923']"}}),_c('div',{key:2,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"item-text":"text","property-id":924,"item-value":"value","flat":"","dense":"","outlined":"","attach":"","placeholder":"Pilih Warna","hide-details":"auto","menu-props":{ top: true, offsetY: true },"items":_vm.trelloLabelOptions},on:{"change":_vm.changeColor},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":item.color}},[_c(VImg,{attrs:{"src":item.image}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}},{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c(VListItemAvatar,{staticClass:"m-0",attrs:{"size":26}},[_c(VAvatar,{attrs:{"color":item.color}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Warna Label","details":[
                  'Warna dari label yang akan dibuat.' ]}})],1)]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }