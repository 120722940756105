<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="telegramForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <telegram-bot-select
            ref="telegramUserSelect"
            v-model="selectedToken"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Bot Telegram"
                :details="[
                  'Akun Bot Telegram yang akan digunakan untuk mengirim pesan',
                  'Jika akun Bot Telegram belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </telegram-bot-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <div
                :key="1"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedChat"
                  :items="telegramChatList"
                  item-value="chat_room_id"
                  item-text="group_name"
                  flat
                  outlined
                  attach
                  dense
                  :property-id="1601"
                  :disabled="!selectedToken"
                  :loading="loadingTelegramChatList"
                  label="Chat"
                  class="mt-4"
                  hide-details="auto"
                  placeholder="Pilih Chat"
                  :rules="[required]"
                  @change="changeChat"
                >
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.group_name ? item.group_name : resolveChatName(item) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.group_name ? 'Group Chat' : 'Private Chat' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template #selection="{ item }">
                    <v-list-item-subtitle>
                      {{ item.group_name ? item.group_name : resolveChatName(item) }}
                    </v-list-item-subtitle>
                  </template>
                </v-select>
                <workflow-field-info
                  :margin-top="'mt-3'"
                  title="Chat"
                  :details="[
                    'Chat yang akan dikirimi pesan',
                    'Bot hanya bisa mengirim pesan ke orang yang pernah mengirim pesan ke bot ini.',
                    'Jika Pilihan Chat masih kosong, silahkan kirim chat ke bot terlebih dahulu melalui link dibawah.',
                  ]"
                >
                  <template #actions>
                    <span>
                      <a
                        :href="`https://t.me/${selectedToken.connection_name}`"
                        target="_blank"
                      >
                        Kirim Chat ke Bot
                      </a>
                    </span>
                  </template>
                </workflow-field-info>
              </div>
              <workflow-input
                ref="telegramInput"
                :key="2"
                v-model="formData['1602']"
                :property-id="1602"
                placeholder="Text"
                :variables="options"
                class="my-4"
                required
                long
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Text"
                    :details="[
                      'Isi dari pesan yang dikirim',
                    ]"
                  />
                </template>
              </workflow-input>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { isNumber } from '@core/utils/filter'
import TelegramBotSelect from './components/TelegramBotSelect.vue'
import useTelegram from '@/composables/useTelegram'
import { required } from '@core/utils/validation'
import WorkflowInput from '../../components/WorkflowInput.vue'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    TelegramBotSelect,
    WorkflowInput,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const formData = ref({})
    const telegramForm = ref(null)
    const telegramInput = ref(null)
    const selectedToken = ref(null)
    const selectedChat = ref(null)
    const { fetchTelegramChatList, loadingTelegramChatList, telegramChatList } = useTelegram()

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput
        if (el.property_id === 1601) selectedChat.value = el.value
        const formDataIndex = el.property_id
        if (!formData.value[formDataIndex]) formData.value[formDataIndex] = []
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
        console.log(formData.value[formDataIndex])
      })
      console.log(formData.value)
    }

    onMounted(() => {
      if (props.token) {
        emit('changeToken', props.token.id, props.index)
        loadFormEdit(props.props)
        selectedToken.value = props.token
        fetchTelegramChatList(props.token.id)
      }
    })

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      emit('input', result)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      fetchTelegramChatList(val.id)
    }

    const changeChat = val => {
      formData.value['1601'] = {
        parent_id: null,
        property_id: 1601,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const validate = () => {
      let isValid = true
      console.log(telegramInput.value)
      if (!telegramForm.value.validate()) isValid = false
      for (let i = 0; i < telegramInput.value.length; i += 1) {
        if (!telegramInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    const resolveChatName = chat => `${chat.first_name} ${chat.last_name ? `${chat.last_name} ` : ''}${chat.username ? `(${chat.username})` : ''}`

    return {
      formData,
      telegramForm,
      telegramInput,
      selectedToken,
      changeToken,

      selectedChat,
      changeChat,

      loadingTelegramChatList,
      telegramChatList,

      required,

      resolveChatName,
      onInput,
      validate,
    }
  },
}
</script>
