import { updateStatusTokopediaToken } from '@/graphql/mutations'
import { getMyTokopediaAccountData } from '@/graphql/queries'
import getTokopediaIntegrateRequests from '@/graphql/query/getTokopediaIntegrateRequests'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from '@vue/composition-api'
import Vue from 'vue'

const useTokopedia = () => {
  const tokopediaAccountList = ref([])

  const loadingAccountTokopedia = ref(false)

  const loadingTokenRequestTokopedia = ref(false)
  const TokenRequestCountTokopedia = ref(0)
  const TokenRequestListTokopedia = ref([])

  const loadingChangeStatusTokopediaToken = ref(false)

  const fetchAccountTokopedia = async () => {
    loadingAccountTokopedia.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getMyTokopediaAccountData,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingAccountTokopedia.value = false
        tokopediaAccountList.value = result.data.getTokopediaAccountData
        resolve(result.data.getTokopediaAccountData)
      }).catch(err => {
        loadingAccountTokopedia.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchTokopediaIntegrateRequests = async (pagination, filter) => {
    loadingTokenRequestTokopedia.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTokopediaIntegrateRequests,
        fetchPolicy: 'no-cache',
        variables: {
          pagination,
          filter,
        },
      }).then(result => {
        loadingTokenRequestTokopedia.value = false
        TokenRequestListTokopedia.value = result.data.getTokopediaIntegrateRequests.tokens
        TokenRequestCountTokopedia.value = result.data.getTokopediaIntegrateRequests.count
        resolve(result.data.getTokopediaIntegrateRequests)
      }).catch(err => {
        loadingTokenRequestTokopedia.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const changeStatusTokopediaToken = async param => {
    loadingChangeStatusTokopediaToken.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: updateStatusTokopediaToken,
        variables: {
          param,
        },
      }).then(result => {
        loadingChangeStatusTokopediaToken.value = false
        resolve(result.data.updateStatusTokopediaToken)
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil konfirmasi status token',
        })
      }).catch(err => {
        loadingChangeStatusTokopediaToken.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  return {
    tokopediaAccountList,
    loadingAccountTokopedia,
    fetchAccountTokopedia,

    fetchTokopediaIntegrateRequests,
    loadingTokenRequestTokopedia,
    TokenRequestListTokopedia,
    TokenRequestCountTokopedia,

    changeStatusTokopediaToken,
    loadingChangeStatusTokopediaToken,
  }
}

export default useTokopedia
