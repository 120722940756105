<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="cardForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <trello-account-select
            ref="trelloUserSelect"
            v-model="selectedToken"
            :loading="loadingUserTrello"
            :items="trelloUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Trello"
                :details="[
                  'Akun Trello yang akan digunakan untuk mengubah kartu',
                  'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </trello-account-select>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedBoard"
              :items="trelloBoardList"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              attach
              :property-id="901"
              :disabled="!selectedToken"
              :loading="loadingBoardTrello"
              label="Board"
              class="mt-4"
              hide-details="auto"
              placeholder="Pilih Board"
              :rules="[required]"
              @change="changeBoard"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Board"
              :details="[
                'Board yang akan digunakan untuk memilih kartu yang akan diubah',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedList"
              :items="trelloListList"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              attach
              :loading="loadingListTrello"
              label="List"
              class="mt-4"
              :disabled="!selectedBoard"
              :property-id="902"
              :rules="[required]"
              hide-details="auto"
              placeholder="Pilih List"
              @change="changeList"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="List"
              :details="[
                'List yang akan digunakan untuk memilih kartu yang akan diubah',
              ]"
            />
          </div>
          <div class="d-flex align-center">
            <v-select
              v-model="selectedCard"
              :items="trelloCardList"
              item-text="name"
              item-value="id"
              flat
              outlined
              dense
              attach
              :loading="loadingCardTrello"
              label="Card"
              class="mt-4"
              :disabled="!selectedList"
              :property-id="911"
              :rules="[required]"
              hide-details="auto"
              placeholder="Pilih Card"
              @change="changeCard"
            >
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption font-weight-semibold">
                    {{ item.id }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <workflow-field-info
              :margin-top="'mt-3'"
              title="Kartu"
              :details="[
                'Kartu yang akan diubah',
              ]"
            />
          </div>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedList"
            >
              <workflow-input
                :key="1"
                ref="cardInput"
                v-model="formData['903']"
                :property-id="903"
                class="my-4"
                placeholder="Nama Kartu"
                :variables="options"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Nama Kartu"
                    :details="[
                      'Nama baru dari kartu yang akan diubah (opsional)',
                    ]"
                  />
                </template>
              </workflow-input>
              <workflow-input
                :key="2"
                ref="cardInput"
                v-model="formData['904']"
                :property-id="904"
                class="my-4"
                placeholder="Deskripsi Kartu"
                long
                :variables="options"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Deskripsi Kartu"
                    :details="[
                      'Deskripsi baru dari kartu yang akan diubah (opsional)',
                    ]"
                  />
                </template>
              </workflow-input>
              <div
                :key="3"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedIdMembers"
                  item-text="username"
                  item-value="id"
                  :property-id="907"
                  placeholder="Ditugaskan ke"
                  flat
                  :loading="loadingBoardMemberTrello"
                  :items="trelloBoardMemberList"
                  outlined
                  multiple
                  chips
                  dense
                  attach
                  class="my-4"
                  hide-details="auto"
                  @change="changeMember"
                >
                  <template #item="{ item }">
                    <v-list-item-avatar>
                      <v-avatar
                        size="36"
                        class="mr-2 v-avatar-light-bg"
                      >
                        <span>{{ avatarText(item.fullName) }}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.username }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.fullName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <workflow-field-info
                  :margin-top="'mt-1'"
                  title="Ditugaskan Ke"
                  :details="[
                    'Member baru yang ditugaskan ke kartu (opsional)',
                  ]"
                />
              </div>
              <div
                :key="4"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedIdLabels"
                  item-text="color"
                  item-value="id"
                  :items="trelloLabelList"
                  :loading="loadingLabelTrello"
                  :property-id="908"
                  placeholder="Label"
                  flat
                  multiple
                  outlined
                  attach
                  dense
                  class="my-4"
                  chips
                  hide-details="auto"
                  @change="changeLabel"
                >
                  <template #item="{ item }">
                    <!-- <v-list-item-avatar>
                    <v-avatar
                      size="36"
                      color="#E53935"
                    >
                      <span>{{ avatarText(item.name) }}</span>
                    </v-avatar>
                  </v-list-item-avatar> -->
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.name ? item.name : item.color }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.color }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <workflow-field-info
                  :margin-top="'mt-1'"
                  title="Label"
                  :details="[
                    'Label baru yang ditambahkan ke kartu (opsional)',
                  ]"
                />
              </div>
              <div
                :key="5"
                class="d-flex align-center"
              >
                <v-select
                  v-model="isArchived"
                  item-text="text"
                  item-value="id"
                  :items="[{id: 0, text: 'Tidak'}, {id: 1, text: 'Ya'}]"
                  :property-id="912"
                  placeholder="Diarsipkan"
                  flat
                  attach
                  outlined
                  dense
                  class="my-4"
                  hide-details="auto"
                  @change="changeArchive"
                >
                  <template #item="{ item }">
                    <!-- <v-list-item-avatar>
                    <v-avatar
                      size="36"
                      color="#E53935"
                    >
                      <span>{{ avatarText(item.name) }}</span>
                    </v-avatar>
                  </v-list-item-avatar> -->
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <workflow-field-info
                  :margin-top="'mt-1'"
                  title="Diarsipkan"
                  :details="[
                    'Status arsip baru dari kartu (opsional)',
                  ]"
                />
              </div>
              <!-- <span
                key="5"
                class="text-caption mb-1"
              >Attachment</span>
              <v-card
                :key="6"
                width="110"
                height="110"
                outlined
                class="mt-1 d-flex align-center justify-center"
                @click="$refs.cardPhotoPicker[0].click()"
              >
                <v-img
                  v-if="fileSourcePreview"
                  :src="fileSourcePreview"
                  height="110"
                  width="110"
                  class="rounded"
                />
                <v-icon
                  v-else
                  class="text--disabled"
                  size="32"
                >
                  {{ icons.mdiImage }}
                </v-icon>
              </v-card>
              <input
                :key="7"
                ref="cardPhotoPicker"
                type="file"
                class="d-none"
                accept=".jpg, .jpeg, .png"
                @change="onFileChange($event.target.files)"
              >
              <span
                :key="8"
                class="text-caption error--text mb-1"
              >{{ errorPhoto }}</span> -->
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import WorkflowInput from '../../components/WorkflowInput.vue'
import { mdiImage } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import useTrello from '@/composables/useTrello'
import { avatarText, isNumber } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'
import TrelloAccountSelect from '@/views/workflow/components/selects/TrelloAccountSelect.vue'

export default {
  components: {
    WorkflowInput,
    WorkflowFieldInfo,
    TrelloAccountSelect,
    // TaskTag,
    // DateTimePicker,
    // VisibilityPicker,
    // UserTeamAutoSuggest,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const cardForm = ref(null)
    const cardInput = ref(null)
    const selectedToken = ref(null)
    const selectedBoard = ref(null)
    const selectedList = ref(null)
    const selectedCard = ref(null)
    const selectedIdMembers = ref(null)
    const selectedIdLabels = ref(null)
    const isArchived = ref(null)
    const attachment = ref(null)
    const formData = ref({})

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput
        if (el.property_id === 901) selectedBoard.value = el.value
        if (el.property_id === 902) selectedList.value = el.value
        if (el.property_id === 907) selectedIdMembers.value = JSON.parse(el.value)
        if (el.property_id === 908) selectedIdLabels.value = JSON.parse(el.value)
        if (el.property_id === 911) selectedCard.value = el.value
        if (el.property_id === 912) isArchived.value = +el.value

        if (!formData.value[el.property_id]) formData.value[el.property_id] = []
        formData.value[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const changeCard = val => {
      formData.value['911'] = {
        parent_id: null,
        property_id: 911,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeMember = val => {
      formData.value['907'] = {
        parent_id: null,
        property_id: 907,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeLabel = val => {
      formData.value['908'] = {
        parent_id: null,
        property_id: 908,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeArchive = val => {
      formData.value['912'] = {
        parent_id: null,
        property_id: 912,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const {
      fetchBoardsTrello, loadingBoardTrello, trelloBoardList,
      fetchUserTrello, loadingUserTrello, trelloUserList,
      fetchListsTrello, loadingListTrello, trelloListList,
      fetchBoardMemberTrello, loadingBoardMemberTrello, trelloBoardMemberList,
      fetchLabelsTrello, loadingLabelTrello, trelloLabelList,
      fetchCardsTrello, loadingCardTrello, trelloCardList,
    } = useTrello()

    const changeBoard = val => {
      fetchBoardMemberTrello(selectedToken.value.token, selectedBoard.value)
      fetchListsTrello(selectedToken.value.token, selectedBoard.value)
      fetchLabelsTrello(selectedToken.value.token, selectedBoard.value)

      selectedIdMembers.value = null
      formData.value['59'] = null

      selectedIdLabels.value = null
      formData.value['60'] = null

      selectedList.value = null
      formData.value['54'] = null

      selectedCard.value = null
      formData.value['63'] = null

      formData.value['901'] = {
        parent_id: null,
        property_id: 901,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeList = val => {
      selectedCard.value = null
      formData.value['63'] = null

      formData.value['902'] = {
        parent_id: null,
        property_id: 902,
        sort: 0,
        value: val,
      }
      onInput(formData.value)

      fetchCardsTrello(selectedToken.value.token, selectedList.value)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      fetchBoardsTrello(val.token)
    }

    const errorPhoto = ref('')
    const fileSourcePreview = ref(null)
    const onFileChange = files => {
      errorPhoto.value = ''

      // check for error photo
      if (files.length) {
        if (!files[0].type.includes('image')) errorPhoto.value = 'File tidak valid'
        if (files[0].size / 1000 > 2048) errorPhoto.value = 'Ukuran melebihi batas. Maksimum 2mb.'

        if (!errorPhoto.value) {
          fileSourcePreview.value = URL.createObjectURL(files[0])
          // eslint-disable-next-line prefer-destructuring
          attachment.value = files[0]
        }
      }
    }

    const validate = () => {
      let isValid = true
      console.log(cardInput.value)
      if (!cardForm.value.validate()) isValid = false
      for (let i = 0; i < cardInput.value.length; i += 1) {
        if (!cardInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    onMounted(() => {
      fetchUserTrello().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          loadFormEdit(props.props)
          selectedToken.value = trelloUserList.value.find(el => +el.id === props.token.id)
          fetchBoardsTrello(selectedToken.value.token)
          fetchBoardMemberTrello(selectedToken.value.token, selectedBoard.value)
          fetchLabelsTrello(selectedToken.value.token, selectedBoard.value)
          fetchListsTrello(selectedToken.value.token, selectedBoard.value)
          fetchCardsTrello(selectedToken.value.token, selectedList.value)
        }
      })
    })

    return {
      cardForm,
      selectedToken,
      selectedBoard,
      selectedCard,
      formData,
      cardInput,
      icons: {
        mdiImage,
      },
      onFileChange,
      fileSourcePreview,
      errorPhoto,

      loadingBoardTrello,
      trelloBoardList,
      fetchBoardsTrello,

      loadingUserTrello,
      trelloUserList,
      fetchUserTrello,

      changeBoard,
      loadingListTrello,
      trelloListList,

      fetchBoardMemberTrello,
      loadingBoardMemberTrello,
      trelloBoardMemberList,

      fetchLabelsTrello,
      loadingLabelTrello,
      trelloLabelList,

      fetchCardsTrello,
      loadingCardTrello,
      trelloCardList,

      changeToken,
      onInput,
      selectedList,
      selectedIdMembers,
      selectedIdLabels,
      validate,
      avatarText,
      required,
      changeList,
      isArchived,

      changeCard,
      changeMember,
      changeLabel,
      changeArchive,
    }
  },
}
</script>
<style>
</style>
