<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="sheetForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <google-account-select
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang akan digunakan untuk membuat spreadsheet',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <div
                :key="0"
                class="d-flex align-center"
              >
                <v-autocomplete
                  v-model="selectedParent"
                  :items="driveFolderList"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  attach
                  dense
                  :loading="loadingListFolder"
                  label="Folder"
                  class="mt-4"
                  hide-details="auto"
                  placeholder="Pilih Folder"
                  no-filter
                  :rules="[required]"
                  @update:search-input="(val) => debouncedFetchFolder(selectedToken.refresh_token, val)"
                  @input="changeParent"
                >
                  <template #append-item>
                    <div v-intersect="onIntersect" />
                  </template>
                  <template #item="{ item }">
                    <v-list-item-avatar>
                      <v-avatar color="#fef">
                        <v-img
                          :src="item.iconLink"
                          max-height="24px"
                          max-width="24px"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.id }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <workflow-field-info
                  :margin-top="'mt-3'"
                  title="Folder Parent"
                  :details="[
                    'Folder yang akan digunakan sebagai tempat membuat spreadsheet',
                  ]"
                />
              </div>
              <workflow-input
                :key="1"
                ref="sheetInput"
                v-model="formData['1201']"
                :property-id="1201"
                placeholder="Nama Spreadsheet"
                :variables="options"
                class="my-4"
                required
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Nama Spreadsheet"
                    :details="[
                      'Nama dari file spreadsheet yang akan dibuat',
                    ]"
                  />
                </template>
              </workflow-input>
            </template>
          </XyzTransitionGroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useGoogle from '@/composables/useGoogle'
import { onMounted, ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import { required } from '@core/utils/validation'
import WorkflowInput from '../../components/WorkflowInput.vue'
import { isNumber } from '@/@core/utils/filter'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    GoogleAccountSelect,
    WorkflowInput,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      googleUserList, loadingUserGoogle, fetchUserGoogle,
      fetchDriveFolderList, loadingListFolder, driveFolderList, driveFolderNextPageToken,
    } = useGoogle()

    const selectedToken = ref(null)
    const selectedParent = ref(null)
    const sheetForm = ref(null)
    const sheetInput = ref(null)
    const formData = ref({})

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput
        if (el.property_id === 1202) selectedParent.value = el.value

        const formDataIndex = el.property_id
        if (!formData.value[formDataIndex]) formData.value[formDataIndex] = []
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = googleUserList.value.find(el => el.id === props.token.id)
          fetchDriveFolderList(selectedToken.value.refresh_token, '')
          loadFormEdit(props.props)
        }
      })
    })

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      selectedParent.value = null
      fetchDriveFolderList(val.refresh_token, '')
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      console.log(sheetInput.value)
      if (!sheetForm.value.validate()) isValid = false
      for (let i = 0; i < sheetInput.value.length; i += 1) {
        if (!sheetInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    const debouncedFetchFolder = useDebounceFn((token, search, pageToken) => {
      if (token) fetchDriveFolderList(token, search, pageToken)
    }, 750)

    const onIntersect = entry => {
      if (entry[0].isIntersecting && driveFolderList.value.length > 0) {
        if (driveFolderNextPageToken.value) {
          debouncedFetchFolder(selectedToken.value.refresh_token, '', driveFolderNextPageToken.value)
        }
      }
    }

    const changeParent = val => {
      formData.value['1202'] = {
        parent_id: null,
        property_id: 1202,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    return {
      googleUserList,
      loadingUserGoogle,
      fetchUserGoogle,

      fetchDriveFolderList,
      loadingListFolder,
      driveFolderList,
      driveFolderNextPageToken,

      selectedToken,
      selectedParent,

      changeToken,
      onInput,
      formData,
      onIntersect,
      debouncedFetchFolder,
      sheetForm,
      sheetInput,
      required,
      validate,

      changeParent,
    }
  },
}
</script>
