<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="telegramForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <telegram-bot-select
            ref="telegramBotSelect"
            v-model="selectedToken"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Bot Telegram"
                :details="[
                  'Akun Bot Telegram yang chat-nya akan diawasi untuk mengecek event trigger',
                  'Jika akun Bot Telegram belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </telegram-bot-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import TelegramBotSelect from './components/TelegramBotSelect.vue'
import { onMounted, ref } from '@vue/composition-api'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    TelegramBotSelect,
    WorkflowFieldInfo,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const changeToken = val => {
      emit('input', val)
    }

    const telegramForm = ref(null)
    const telegramBotSelect = ref(null)
    const selectedToken = ref(null)

    const validate = () => {
      let isValid = true
      if (!telegramForm.value.validate()) isValid = false

      return isValid
    }

    onMounted(() => {
      if (props.value) {
        selectedToken.value = props.value
      }
    })

    return {
      changeToken,
      validate,

      telegramForm,
      telegramBotSelect,
      selectedToken,
    }
  },
}
</script>
