import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"accurateForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('accurate-user-select',{attrs:{"items":_vm.accurateUserList,"loading":_vm.loadingUserAccurate},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Accurate","details":[
                'Akun Accurate yang akan digunakan untuk melakukan aksi',
                'Jika akun Accurate belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('accurate-database-select',{key:0,attrs:{"token-id":_vm.selectedToken.id},on:{"input":_vm.changeDatabase},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Database Accurate","details":[
                    'Database Accurate yang akan digunakan sebagai tempat melakukan aksi',
                    'Jika belum ada database, silahkan membuat database terlebih dahulu di Accurate' ]}})]},proxy:true}],null,true),model:{value:(_vm.selectedDatabase),callback:function ($$v) {_vm.selectedDatabase=$$v},expression:"selectedDatabase"}}),_c('div',{key:1,staticClass:"mt-4 text-center"},[_vm._v(" Data Produk & customer dari Sales Order akan diisi otomatis oleh sistem ")])]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }