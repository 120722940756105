<template>
  <v-dialog
    v-model="isOpen"
    width="520"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        readonly
        outlined
        dense
        :hide-details="details"
        :value="recurrenceView"
        v-on="on"
      />
    </template>

    <v-card>
      <v-form
        ref="recurrenceForm"
        @submit.prevent="submitForm"
      >
        <v-card-title class="mb-4">
          Jadwalkan Pengulangan Event
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-center justify-space-between mb-2">
            <span class="me-2">Ulangi setiap</span>

            <v-text-field
              v-model="selectedInterval"
              flat
              outlined
              dense
              placeholder=""
              type="number"
              class="mx-2"
              :rules="[required, positiveInteger]"
            />
            <v-select
              v-model="selectedType"
              flat
              outlined
              dense
              :rules="[required]"
              item-value="value"
              item-text="text"
              :items="intervalType"
              class="ms-2"
            />
          </div>
          <div
            v-if="selectedType === 'WEEKLY'"
            class="mb-4"
          >
            <span class="me-2">Ulangi pada hari</span>
            <v-chip-group
              multiple
              @change="changeDays"
            >
              <v-chip
                v-for="day in days"
                :key="day.value"
                :value="day"
                :color="selectedDays.includes(day) ? 'primary' : 'grey lighten-2'"
              >
                {{ day.text }}
              </v-chip>
            </v-chip-group>
          </div>
          <span class="me-2">Berakhir pada</span>
          <v-radio-group v-model="selectedEnds">
            <div class="d-flex justify-space-between my-3">
              <v-radio
                key="1"
                label="Tidak pernah"
                value="never"
                class="mb-0"
              />
            </div>
            <div class="d-flex justify-space-between my-3">
              <v-radio
                key="2"
                label="Setelah"
                value="after"
                class="mb-0"
              />
              <v-text-field
                v-model="limitCount"
                type="number"
                outlined
                dense
                label="Jumlah"
                flat
                hide-details="auto"
                class="shrink"
                :rules="selectedEnds === 'after' ? [required, positiveInteger] : []"
                suffix="Pengulangan"
                :disabled="selectedEnds !== 'after'"
                @change="changeLimit"
              />
            </div>
            <div class="d-flex justify-space-between my-3">
              <v-radio
                key="3"
                label="Pada tanggal"
                value="on"
                class="mb-0"
              />
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="limitDate"
                    label="Tanggal"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    flat
                    :rules="selectedEnds === 'on' ? [required] : []"
                    hide-details="auto"
                    :disabled="selectedEnds !== 'on'"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="limitDate"
                  @input="menuDate = false;changeLimit(limitDate)"
                />
              </v-menu>
            </div>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="mt-n4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="reset"
          >
            Reset
          </v-btn>
          <v-btn
            color="primary"
            @click="validate"
          >
            Pilih
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
// eslint-disable-next-line no-unused-vars

import { computed, onMounted, ref } from '@vue/composition-api'
import { required, positiveInteger } from '@core/utils/validation'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    details: {
      type: [Boolean, String],
      default: 'auto',
    },
  },
  setup(props, { emit }) {
    const recurrenceView = ref(null)
    const limitDate = ref(null)
    const menuDate = ref(false)
    const recurrenceForm = ref(null)
    const isOpen = ref(false)
    const selectedType = ref(1)
    const selectedEnds = ref('never')
    const selectedLimit = ref(null)
    const selectedDays = ref([])
    const limitCount = ref(null)

    const intervalType = ref([{
      text: 'Hari',
      value: 'DAILY',
    }, {
      text: 'Minggu',
      value: 'WEEKLY',
    }, {
      text: 'Bulan',
      value: 'MONTHLY',
    }, {
      text: 'Tahun',
      value: 'YEARLY',
    }])
    const changeDays = val => {
      selectedDays.value = val
    }
    const selectedInterval = ref(null)

    const changeLimit = val => {
      selectedLimit.value = val
      console.log(val)
    }

    const limitRule = computed(() => {
      if (selectedEnds.value === 'after') {
        return `COUNT=${selectedLimit.value}`
      }
      if (selectedEnds.value === 'on') {
        return `UNTIL=${selectedLimit.value}`
      }

      return ''
    })

    const limitView = computed(() => {
      if (selectedEnds.value === 'after') {
        return `${selectedLimit.value} kali`
      }
      if (selectedEnds.value === 'on') {
        return `tanggal ${selectedLimit.value}`
      }

      return ''
    })

    const dayRule = computed(() => {
      console.log(selectedDays.value)
      if (selectedType.value === 'WEEKLY') {
        return selectedDays.value.map(el => el.value).join(',')
      }

      return ''
    })

    const typeView = computed(() => {
      if (selectedType.value === 'DAILY') return 'Hari'
      if (selectedType.value === 'WEEKLY') return 'Minggu'
      if (selectedType.value === 'MONTHLY') return 'Bulan'
      if (selectedType.value === 'YEARLY') return 'Tahun'

      return null
    })

    const reset = () => {
      recurrenceView.value = null
      emit('input', null)
      isOpen.value = false
    }

    const days = ref([
      {
        text: 'MON',
        value: 'MO',
        view: 'Senin',
      },
      {
        text: 'TUE',
        value: 'TU',
        view: 'Selasa',
      },
      {
        text: 'WED',
        value: 'WE',
        view: 'Rabu',
      },
      {
        text: 'THU',
        value: 'TH',
        view: 'Kamis',
      },
      {
        text: 'FRI',
        value: 'FR',
        view: 'Jumat',
      },
      {
        text: 'SAT',
        value: 'SA',
        view: 'Sabtu',
      },
      {
        text: 'SUN',
        value: 'SU',
        view: 'Minggu',
      },
    ])

    onMounted(() => {
      selectedDays.value = []
      if (props.value) {
        console.log(props.value)
        const vals = props.value.split(';')
        // eslint-disable-next-line prefer-destructuring
        selectedType.value = vals[0].split('=')[1]
        // eslint-disable-next-line prefer-destructuring
        selectedInterval.value = vals[2].split('=')[1]
        if (selectedType.value === 'WEEKLY') {
          // eslint-disable-next-line prefer-destructuring
          selectedDays.value = vals[4].split('=')[1].split(',').map(el => days.value.find(d => d.value === el))
        }
        // eslint-disable-next-line prefer-destructuring
        if (vals[3].split('=')[0] === 'UNTIL') {
          selectedEnds.value = 'on'
          // eslint-disable-next-line prefer-destructuring
          limitDate.value = vals[3].split('=')[1]
        } else if (vals[3].split('=')[0] === 'COUNT') {
          selectedEnds.value = 'after'
          // eslint-disable-next-line prefer-destructuring
          limitCount.value = vals[3].split('=')[1]
        }
        // eslint-disable-next-line prefer-destructuring
        selectedLimit.value = vals[3].split('=')[1]
        recurrenceView.value = `${selectedInterval.value} ${typeView.value} sekali, ${dayRule.value ? `pada hari ${selectedDays.value.map(el => el.view).join(', ')}, ` : ''}${limitView.value ? 'hingga' : 'selamanya'} ${limitView.value}`
        // console.log('RRULE:FREQ=WEEKLY;WKST=SU;INTERVAL=2;BYDAY=WE,SA;COUNT=33;')
      }
    })

    const validate = () => {
      if (recurrenceForm.value.validate()) {
        const rule = `RRULE:FREQ=${selectedType.value};WKST=SU;INTERVAL=${selectedInterval.value};${limitRule.value};${dayRule.value ? 'BYDAY=' : ''}${dayRule.value}`
        recurrenceView.value = `${selectedInterval.value} ${typeView.value} sekali, ${dayRule.value ? `pada hari ${selectedDays.value.map(el => el.view).join(', ')}, ` : ''}${limitView.value ? 'hingga' : 'selamanya'} ${limitView.value}`
        emit('input', rule)
        isOpen.value = false
      }
    }

    return {
      isOpen,
      intervalType,
      selectedType,
      recurrenceForm,
      validate,
      required,
      positiveInteger,
      menuDate,
      selectedEnds,
      selectedLimit,
      selectedInterval,
      changeLimit,
      days,
      selectedDays,
      limitDate,
      changeDays,
      recurrenceView,
      limitCount,
      reset,
    }
  },
}
</script>
