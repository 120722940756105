<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="productForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <workflow-product-auto-suggest
            v-model="formData.id"
            :property-id="35"
            class="mb-4"
            label="Produk"
            :rules="[required]"
            @input="onInput(formData)"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import WorkflowProductAutoSuggest from '@/components/inputs/workflow/WorkflowProductAutoSuggest.vue'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  components: {
    WorkflowProductAutoSuggest,
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const productForm = ref(null)

    const formData = ref({
      id: null,
      // name: [],
      // description: [],
      // price: null,
      // stock: null,
      // startDate: '',
      // endDate: '',
      // job: null,
      // customer: null,
      // visibility: null,
      // tags: [],
      // assign: [],
    })

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      if (!productForm.value.validate()) isValid = false

      return isValid
    }

    return {
      formData,
      required,
      onInput,
      productForm,
      validate,
    }
  },
}
</script>
