import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"calendarForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang akan digunakan untuk membuat kalender',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('workflow-input',{key:1,ref:"calendarInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1001,"placeholder":"Nama Kalender","variables":_vm.options,"required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama kalender","details":[
                    'Nama dari kalender yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1001']),callback:function ($$v) {_vm.$set(_vm.formData, '1001', $$v)},expression:"formData['1001']"}})]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }