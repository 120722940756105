import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"calendarForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang akan digunakan untuk membuat event',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('div',{key:0,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.calendarList,"item-value":"id","item-text":"summary","flat":"","outlined":"","attach":"","dense":"","loading":_vm.loadingCalendarList,"label":"Kalender","hide-details":"auto","placeholder":"Pilih Kalender","rules":[_vm.required]},on:{"change":_vm.changeCalendar},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.summary)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedCalendar),callback:function ($$v) {_vm.selectedCalendar=$$v},expression:"selectedCalendar"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Kalender","details":[
                  'Kalender yang digunakan sebagai tempat membuat event' ]}})],1),_c('workflow-input',{key:1,ref:"calendarInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1003,"placeholder":"Judul","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Judul","details":[
                    'Judul event yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1003']),callback:function ($$v) {_vm.$set(_vm.formData, '1003', $$v)},expression:"formData['1003']"}}),_c('workflow-input',{key:2,ref:"calendarInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1004,"placeholder":"Deskripsi","variables":_vm.options,"long":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Deskripsi","details":[
                    'Deskripsi event yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1004']),callback:function ($$v) {_vm.$set(_vm.formData, '1004', $$v)},expression:"formData['1004']"}}),_c(VRow,{key:3},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('workflow-input',{ref:"calendarInput",refInFor:true,attrs:{"property-id":1005,"placeholder":"Waktu Mulai","type":"date","required":"","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Waktu Mulai","details":[
                        'Waktu mulai event yang akan dibuat',
                        'Format Waktu mulai: YYYY-MM-DDThh:mm:ss',
                        'YYYY: 4 digit tahun',
                        'MM: 2 digit bulan',
                        'DD: 2 digit hari',
                        'T: Pemisah tanggal & waktu',
                        'hh: 2 digit jam',
                        'mm: 2 digit menit',
                        'ss: 2 digit detik',
                        'Contoh: 2023-02-03T07:00:00'
                      ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1005']),callback:function ($$v) {_vm.$set(_vm.formData, '1005', $$v)},expression:"formData['1005']"}})],1)],1),_c(VRow,{key:4},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c(VTextField,{attrs:{"label":"Offset Waktu","outlined":"","dense":"","rules":[_vm.validTimeOffset],"hide-details":"auto"},on:{"change":_vm.changeOffset},model:{value:(_vm.selectedOffset),callback:function ($$v) {_vm.selectedOffset=$$v},expression:"selectedOffset"}}),_c('div',{staticClass:"mt-2"},[_c('workflow-field-info',{attrs:{"title":"Offset Waktu Mulai","details":[
                        'Offset Waktu mulai digunakan untuk mengubah waktu mulai relatif dari Waktu mulai yang sudah ditentukan',
                        'Format penulisan offset: [+-][0-9]{1,2}[smhd]',
                        '+/- menentukan percepatan / perlambatan waktu mulai',
                        'Angka setelah +/- menentukan jumlah percepatan / perlambatan waktu mulai',
                        'huruf s/m/h/d menentukan satuan waktu nya (s: detik, m: menit, h: jam, d: hari)',
                        'Contoh:',
                        'Waktu Mulai: 2023-02-03T07:00:00',
                        'Offset: +2d -12h -30m',
                        'Artinya, waktu mulai akan ditambah dengan 2 hari dan dikurangi dengan 12 jam 30 menit',
                        'Waktu mulai Setelah offset: 2023-02-04T18:30:00'
                      ]}})],1)],1)])],1),_c(VRow,{key:5},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c(VTextField,{attrs:{"label":"Durasi Event (dalam menit)","outlined":"","dense":"","type":"number","hide-details":"auto"},on:{"change":_vm.changeDuration},model:{value:(_vm.selectedDuration),callback:function ($$v) {_vm.selectedDuration=$$v},expression:"selectedDuration"}}),_c('div',{staticClass:"mt-2"},[_c('workflow-field-info',{attrs:{"title":"Durasi Event","details":[
                        'Durasi event dalam satuan menit (jika tidak diisi maka event dianggap berlangsung selama 1 hari penuh)'
                      ]}})],1)],1)])],1),_c('workflow-input',{key:6,ref:"calendarInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":1008,"placeholder":"Peserta","variables":_vm.options,"type":"string"},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Peserta","details":[
                    'Email dari peserta yang akan diundang ke event',
                    'Jika ada lebih dari 1 peserta, Pisahkan dengan tanda koma (,)' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1008']),callback:function ($$v) {_vm.$set(_vm.formData, '1008', $$v)},expression:"formData['1008']"}}),_c('div',{key:7,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"my-4",attrs:{"items":_vm.calendarEventColorList,"item-value":"id","item-text":"background","flat":"","outlined":"","attach":"","dense":"","loading":_vm.loadingCalendarColor,"label":"Warna","hide-details":"auto","placeholder":"Pilih Warna"},on:{"change":_vm.changeColor},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"size":"36","color":item.background}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.background)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}}),_c('workflow-field-info',{attrs:{"title":"Warna","details":[
                  'Warna penanda dari event' ]}})],1),_c('div',{key:8,staticClass:"d-flex align-center"},[_c('RecurrencePicker',{attrs:{"property-id":1010,"label":"Pengulangan"},on:{"input":_vm.changeRecurrence},model:{value:(_vm.selectedRecurrence),callback:function ($$v) {_vm.selectedRecurrence=$$v},expression:"selectedRecurrence"}}),_c('workflow-field-info',{attrs:{"title":"Pengulangan","details":[
                  'Menentukan apakah event adalah event berulang dan bagaimana pola pengulangannya' ]}})],1),_c('div',{key:9,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"my-4",attrs:{"label":"Visibilitas","items":_vm.visibilityList,"item-text":"text","item-value":"value","flat":"","attach":"","outlined":"","dense":"","hide-details":"auto"},on:{"change":_vm.changeVisibility},model:{value:(_vm.selectedVisibility),callback:function ($$v) {_vm.selectedVisibility=$$v},expression:"selectedVisibility"}}),_c('workflow-field-info',{attrs:{"title":"Visibilitas","details":[
                  'Visibilitas / privasi event' ]}})],1)]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }