import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"jobForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('workflow-job-auto-suggest',{staticClass:"mb-4",attrs:{"property-id":101,"rules":[_vm.required]},on:{"input":_vm.changeJob},model:{value:(_vm.selectedJob),callback:function ($$v) {_vm.selectedJob=$$v},expression:"selectedJob"}}),_c('workflow-input',{ref:"jobInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":3,"long":"","placeholder":"Deskripsi Job","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},model:{value:(_vm.formData['3']),callback:function ($$v) {_vm.$set(_vm.formData, '3', $$v)},expression:"formData['3']"}}),_c('workflow-customer-auto-suggest',{staticClass:"mb-4",attrs:{"property-id":108,"rules":[_vm.required]},on:{"input":_vm.changeCustomer},model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}}),_c(VSelect,{staticClass:"mb-4",attrs:{"items":_vm.priorityList,"property-id":107,"item-text":"name","item-value":"id","label":"Prioritas","outlined":"","hide-details":"auto","dense":"","rules":[_vm.required]},on:{"change":_vm.changePriority},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":_vm.priorityColor(item.id)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.selectedPriority),callback:function ($$v) {_vm.selectedPriority=$$v},expression:"selectedPriority"}}),_c(VSelect,{staticClass:"mb-4",attrs:{"items":_vm.jobTypeList,"item-text":"name","item-value":"id","property-id":105,"label":"Pipeline job","outlined":"","dense":"","hide-details":"auto","rules":[_vm.required]},on:{"change":_vm.changeJobType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.selectedJobType),callback:function ($$v) {_vm.selectedJobType=$$v},expression:"selectedJobType"}}),_c(VSelect,{staticClass:"mb-4",attrs:{"items":_vm.jobStatusList,"property-id":106,"item-text":"name","item-value":"id","label":"Status job","outlined":"","dense":"","disabled":!_vm.selectedJobType,"hide-details":"auto","rules":[_vm.required]},on:{"change":_vm.changeJobStatus},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.selectedJobStatus),callback:function ($$v) {_vm.selectedJobStatus=$$v},expression:"selectedJobStatus"}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }