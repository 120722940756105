import {
  getMyTrelloUserData, getTrelloBoardMember, getTrelloBoards, getTrelloCards, getTrelloLabels, getTrelloList,
} from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from '@vue/composition-api'

const useTrello = () => {
  const trelloAuthorizeURL = 'https://trello.com/1/authorize?'
  const trelloAppName = 'Oriens'
  const trelloScope = 'read,write,account'
  const trelloExpiration = 'never'

  const trelloUserList = ref([])
  const trelloBoardList = ref([])
  const trelloListList = ref([])
  const trelloBoardMemberList = ref([])
  const trelloLabelList = ref([])
  const trelloCardList = ref([])

  const loadingUserTrello = ref(false)
  const loadingBoardTrello = ref(false)
  const loadingListTrello = ref(false)
  const loadingBoardMemberTrello = ref(false)
  const loadingLabelTrello = ref(false)
  const loadingCardTrello = ref(false)

  const fetchUserTrello = async () => {
    loadingUserTrello.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getMyTrelloUserData,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingUserTrello.value = false
        trelloUserList.value = result.data.getUserTrelloData.map(item => ({
          ...item, id: +item.id,
        }))
        resolve(result.data.getUserTrelloData)
      }).catch(err => {
        loadingUserTrello.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchBoardsTrello = async token => {
    loadingBoardTrello.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTrelloBoards,
        variables: {
          token,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingBoardTrello.value = false
        resolve(result.data.getTrelloBoards)
        trelloBoardList.value = result.data.getTrelloBoards
      }).catch(err => {
        loadingBoardTrello.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchListsTrello = async (token, boardId) => {
    loadingListTrello.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTrelloList,
        variables: {
          token,
          board_id: boardId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingListTrello.value = false
        resolve(result.data.getTrelloLists)
        trelloListList.value = result.data.getTrelloLists
      }).catch(err => {
        loadingListTrello.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchBoardMemberTrello = async (token, boardId) => {
    loadingBoardMemberTrello.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTrelloBoardMember,
        variables: {
          token,
          board_id: boardId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingBoardMemberTrello.value = false
        trelloBoardMemberList.value = result.data.getTrelloBoardMembers
        resolve(result.data.getTrelloBoardMembers)
      }).catch(err => {
        loadingBoardMemberTrello.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchLabelsTrello = async (token, boardId) => {
    loadingLabelTrello.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTrelloLabels,
        variables: {
          token,
          board_id: boardId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingLabelTrello.value = false
        trelloLabelList.value = result.data.getTrelloLabels
        resolve(result.data.getTrelloLabels)
      }).catch(err => {
        loadingLabelTrello.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchCardsTrello = async (token, listId) => {
    loadingCardTrello.value = true
    console.log(listId)

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTrelloCards,
        variables: {
          token,
          list_id: listId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingCardTrello.value = false
        trelloCardList.value = result.data.getTrelloCards
        resolve(result.data.getTrelloCards)
      }).catch(err => {
        reject(err)
        errorHandling(err)
        loadingCardTrello.value = false
      })
    })
  }

  return {
    trelloAuthorizeURL,
    trelloAppName,
    trelloScope,
    trelloExpiration,

    fetchUserTrello,
    loadingUserTrello,
    trelloUserList,

    fetchBoardsTrello,
    trelloBoardList,
    loadingBoardTrello,

    fetchListsTrello,
    trelloListList,
    loadingListTrello,

    fetchBoardMemberTrello,
    trelloBoardMemberList,
    loadingBoardMemberTrello,

    fetchLabelsTrello,
    trelloLabelList,
    loadingLabelTrello,

    fetchCardsTrello,
    trelloCardList,
    loadingCardTrello,
  }
}

export default useTrello
