import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"cardForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('trello-account-select',{ref:"trelloUserSelect",refInFor:true,attrs:{"loading":_vm.loadingUserTrello,"items":_vm.trelloUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Trello","details":[
                'Akun Trello yang akan digunakan untuk menghapus kartu',
                'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloBoardList,"item-text":"name","item-value":"id","flat":"","outlined":"","attach":"","dense":"","property-id":901,"disabled":!_vm.selectedToken,"loading":_vm.loadingBoardTrello,"label":"Board","hide-details":"auto","placeholder":"Pilih Board","rules":[_vm.required]},on:{"input":_vm.changeBoard},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedBoard),callback:function ($$v) {_vm.selectedBoard=$$v},expression:"selectedBoard"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Board","details":[
              'Board yang akan digunakan untuk memilih kartu yang akan dihapus' ]}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloListList,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","attach":"","loading":_vm.loadingListTrello,"label":"List","disabled":!_vm.selectedBoard,"property-id":902,"rules":[_vm.required],"hide-details":"auto","placeholder":"Pilih List"},on:{"change":_vm.changeList},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"List","details":[
              'List yang akan digunakan untuk memilih kartu yang akan dihapus' ]}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloCardList,"item-text":"name","item-value":"id","flat":"","attach":"","outlined":"","dense":"","loading":_vm.loadingCardTrello,"label":"Kartu","disabled":!_vm.selectedList,"property-id":911,"rules":[_vm.required],"hide-details":"auto","placeholder":"Pilih Kartu"},on:{"change":_vm.changeCard},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedCard),callback:function ($$v) {_vm.selectedCard=$$v},expression:"selectedCard"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Kartu","details":[
              'Kartu yang akan dihapus' ]}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }