import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"sheetForm",attrs:{"lazy-validation":""}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('google-account-select',{ref:"googleAccountSelect",refInFor:true,attrs:{"loading":_vm.loadingUserGoogle,"items":_vm.googleUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Google","details":[
                'Akun Google yang akan digunakan untuk menghapus baris di worksheet',
                'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('div',{key:0,staticClass:"d-flex align-center"},[_c(VAutocomplete,{staticClass:"mt-4",attrs:{"items":_vm.driveSpreadsheetList,"property-id":1203,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","attach":"","loading":_vm.loadingListSpreadsheet,"label":"Spreadsheet","hide-details":"auto","placeholder":"Pilih Spreadsheet","no-filter":"","rules":[_vm.required]},on:{"update:search-input":function (val) { return _vm.debouncedFetchSpreadsheet(_vm.selectedToken.refresh_token, val); },"change":_vm.changeSpreadsheet},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]})]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"#fef"}},[_c(VImg,{attrs:{"src":item.iconLink,"max-height":"24px","max-width":"24px"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedSpreadsheet),callback:function ($$v) {_vm.selectedSpreadsheet=$$v},expression:"selectedSpreadsheet"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Spreadsheet","details":[
                  'anda akan menghapus baris di worksheet yang terdapat di dalam spreadsheet ini.' ]}})],1),(_vm.selectedSpreadsheet)?_c('div',{key:1,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.driveWorksheetList,"property-id":1204,"flat":"","outlined":"","dense":"","attach":"","item-value":"title","item-text":"title","label":"Worksheet","hide-details":"auto","placeholder":"Pilih Worksheet","no-filter":"","rules":[_vm.required],"loading":_vm.loadingListWorksheet},on:{"change":_vm.changeWorksheet},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(((item.sheetType) + " - " + (item.sheetId)))+" ")])],1)]}}],null,true),model:{value:(_vm.selectedWorksheet),callback:function ($$v) {_vm.selectedWorksheet=$$v},expression:"selectedWorksheet"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Worksheet","details":[
                  'anda akan menghapus baris di worksheet ini.' ]}})],1):_vm._e(),(_vm.selectedWorksheet)?_c(VRow,{key:2},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.driveWorksheetHeaderList,"item-text":"name","item-value":"id","flat":"","attach":"","outlined":"","dense":"","label":"Kolom filter","rules":[_vm.required],"hide-details":"auto","no-filter":"","required":"","loading":_vm.loadingListWorksheetHeader},on:{"change":_vm.changeWorksheetHeader},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(("#" + (item.id + 1) + ") " + (item.name ? item.name : '(no name)')))+" ")])],1)]}}],null,true),model:{value:(_vm.selectedHeader),callback:function ($$v) {_vm.selectedHeader=$$v},expression:"selectedHeader"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Kolom Filter","details":[
                      'Kolom yang digunakan sebagai patokan pemilihan baris mana yang akan dihapus.' ]}})],1)]),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('workflow-input',{ref:"sheetInput",refInFor:true,class:_vm.$vuetify.breakpoint.mdAndUp ? 'my-4' : '',attrs:{"property-id":1207,"variables":_vm.options,"label":"Value Kolom","required":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Value Kolom","details":[
                        'Kolom Filter harus memiliki value yang sama dengan value ini agar baris bisa dihapus' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['1207']),callback:function ($$v) {_vm.$set(_vm.formData, '1207', $$v)},expression:"formData['1207']"}})],1)],1):_vm._e()]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }