import {
  getCalendarColor, getCalendarEventList, getCalendarList, getDriveFileList, getDriveFolderList, getDriveSpreadsheetList, getGoogleUserData, getSpreadsheetData, getWorksheetHeader,
} from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from '@vue/composition-api'

const useGoogle = () => {
  const googleScope = 'email openid https://mail.google.com/ https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile'
  const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
  const googleUserList = ref([])
  const calendarList = ref([])
  const calendarEventColorList = ref([])
  const calendarEventList = ref([])
  const driveFolderList = ref([])
  const driveFileList = ref([])
  const driveSpreadsheetList = ref([])
  const driveWorksheetList = ref([])
  const driveWorksheetHeaderList = ref([])
  const driveFolderNextPageToken = ref(null)
  const driveFileNextPageToken = ref(null)
  const driveSpreadsheetNextPageToken = ref(null)

  const loadingUserGoogle = ref(false)
  const loadingGoogle = ref(false)
  const loadingCalendarColor = ref(false)
  const loadingCalendarList = ref(false)
  const loadingCalendarEventList = ref(false)
  const loadingListFolder = ref(false)
  const loadingListFile = ref(false)
  const loadingListSpreadsheet = ref(false)
  const loadingListWorksheet = ref(false)
  const loadingListWorksheetHeader = ref(false)

  const handleCredentialResponse = response => {
    console.log(response)
    console.log(`Encoded JWT ID token: ${response.credential}`)
  }
  const tokenClient = ref(null)
  const codeClient = ref(null)

  const fetchUserGoogle = async () => {
    loadingUserGoogle.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getGoogleUserData,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        googleUserList.value = data.getGoogleUserData
        loadingUserGoogle.value = false
        resolve(data.getGoogleUserData)
      }).catch(err => {
        errorHandling(err)
        loadingUserGoogle.value = false
        reject(err)
      })
    })
  }

  const fetchCalendarColor = async token => {
    loadingCalendarColor.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getCalendarColor,
        variables: {
          refresh_token: token,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingCalendarColor.value = false
        calendarEventColorList.value = data.getCalendarColor.event
        resolve(data.getCalendarColor)
      }).catch(err => {
        errorHandling(err)
        loadingCalendarColor.value = false
        reject(err)
      })
    })
  }

  const fetchCalendarList = async token => {
    loadingCalendarList.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getCalendarList,
        variables: {
          refresh_token: token,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingCalendarList.value = false
        calendarList.value = data.getCalendarList.items
        resolve(data.getCalendarList.items)
      }).catch(err => {
        errorHandling(err)
        loadingCalendarList.value = false
        reject(err)
      })
    })
  }

  const fetchCalendarEventList = async (token, calendarId, param) => {
    loadingCalendarEventList.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getCalendarEventList,
        variables: {
          refresh_token: token,
          calendar_id: calendarId,
          args: param,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingCalendarEventList.value = false
        // calendarEventList.value = data.getEventList.items
        resolve(data.getEventList)
      }).catch(err => {
        errorHandling(err)
        loadingCalendarEventList.value = false
        reject(err)
      })
    })
  }

  const fetchDriveFolderList = async (token, search, pageToken, pageSize = 50) => {
    loadingListFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getDriveFolderList,
        variables: {
          refresh_token: token,
          search,
          page_token: pageToken,
          page_size: pageSize,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingListFolder.value = false
        driveFolderNextPageToken.value = data.getDriveFolderList.nextPageToken

        if (pageToken) driveFolderList.value = [...driveFolderList.value, ...data.getDriveFolderList.files]
        else driveFolderList.value = [{ name: 'root', id: 'root' }, ...data.getDriveFolderList.files]

        resolve(data.getDriveFolderList)
      }).catch(err => {
        errorHandling(err)
        loadingListFolder.value = false
        reject(err)
      })
    })
  }

  const fetchDriveFileList = async (token, search, pageToken, parentId, pageSize = 50) => {
    loadingListFile.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getDriveFileList,
        variables: {
          refresh_token: token,
          search,
          page_token: pageToken,
          parent_id: parentId,
          page_size: pageSize,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingListFile.value = false
        driveFileNextPageToken.value = data.getDriveFileList.nextPageToken

        if (pageToken) driveFileList.value = [...driveFileList.value, ...data.getDriveFileList.files]
        else driveFileList.value = data.getDriveFileList.files

        resolve(data.getDriveFileList)
      }).catch(err => {
        errorHandling(err)
        loadingListFile.value = false
        reject(err)
      })
    })
  }

  const fetchDriveSpreadsheetList = async (token, search, pageToken, pageSize = 8) => {
    loadingListSpreadsheet.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getDriveSpreadsheetList,
        variables: {
          refresh_token: token,
          search,
          page_token: pageToken,
          page_size: pageSize,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingListSpreadsheet.value = false
        driveSpreadsheetNextPageToken.value = data.getSpreadsheetList.nextPageToken

        if (pageToken) driveSpreadsheetList.value = [...driveSpreadsheetList.value, ...data.getSpreadsheetList.files]
        else driveSpreadsheetList.value = data.getSpreadsheetList.files

        resolve(data.getSpreadsheetList)
      }).catch(err => {
        errorHandling(err)
        loadingListSpreadsheet.value = false
        reject(err)
      })
    })
  }

  const fetchDriveWorksheetList = async (token, spreadsheetId) => {
    loadingListWorksheet.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getSpreadsheetData,
        variables: {
          refresh_token: token,
          spreadsheet_id: spreadsheetId,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingListWorksheet.value = false
        const result = data.getSpreadsheetData.sheets.map(el => el.properties)
        driveWorksheetList.value = result
        resolve(result)
      }).catch(err => {
        errorHandling(err)
        loadingListWorksheet.value = false
        reject(err)
      })
    })
  }

  const fetchDriveWorksheetHeader = async (token, spreadsheetId, worksheetTitle) => {
    loadingListWorksheetHeader.value = true
    console.log(spreadsheetId)

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorksheetHeader,
        variables: {
          refresh_token: token,
          spreadsheet_id: spreadsheetId,
          worksheet_title: worksheetTitle,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingListWorksheetHeader.value = false
        console.log(data)
        const result = data.getWorksheetHeader.values ? data.getWorksheetHeader.values[0].map((el, index) => ({ id: index, name: el })) : null
        driveWorksheetHeaderList.value = result
        resolve(result)
      }).catch(err => {
        errorHandling(err)
        loadingListWorksheetHeader.value = false
        reject(err)
      })
    })
  }

  // tokenClient.value = google.accounts.oauth2.initTokenClient({
  //   client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  //   scope: gmailScope,
  //   callback: response => {
  //     console.log(response)
  //     if (response && response.access_token) {
  //       console.log(`Access token: ${response.access_token}`)
  //     }
  //   },
  // })
  // console.log(tokenClient.value)

  return {
    googleScope,
    handleCredentialResponse,
    tokenClient,
    codeClient,
    loadingGoogle,
    googleClientId,

    fetchUserGoogle,
    loadingUserGoogle,
    googleUserList,

    fetchCalendarColor,
    loadingCalendarColor,
    calendarEventColorList,

    fetchCalendarList,
    loadingCalendarList,
    calendarList,

    fetchCalendarEventList,
    loadingCalendarEventList,
    calendarEventList,

    fetchDriveFolderList,
    loadingListFolder,
    driveFolderList,
    driveFolderNextPageToken,

    fetchDriveFileList,
    loadingListFile,
    driveFileList,
    driveFileNextPageToken,

    fetchDriveSpreadsheetList,
    loadingListSpreadsheet,
    driveSpreadsheetList,
    driveSpreadsheetNextPageToken,

    fetchDriveWorksheetList,
    loadingListWorksheet,
    driveWorksheetList,

    fetchDriveWorksheetHeader,
    loadingListWorksheetHeader,
    driveWorksheetHeaderList,
  }
}

export default useGoogle
