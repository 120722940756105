import { ref } from '@vue/composition-api'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'
import {
  getMyAccurateUserData, getMyAccurateDBList, getAccurateAccounts, getAccurateExpenses, getAccurateFobs,
} from '@/graphql/queries'

const useAccurate = () => {
  const accurateUserList = ref([])
  const accurateDBList = ref([])
  const accurateAccountList = ref([])
  const accurateExpenseList = ref([])
  const accurateFobList = ref([])

  const loadingUserAccurate = ref(false)
  const loadingDBAccurate = ref(false)
  const loadingAccountAccurate = ref(false)
  const loadingExpenseAccurate = ref(false)
  const loadingFobAccurate = ref(false)

  const fetchUserAccurate = async () => {
    loadingUserAccurate.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getMyAccurateUserData,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingUserAccurate.value = false
        accurateUserList.value = result.data.getAccurateUserData
        resolve(result.data.getAccurateUserData)
      }).catch(err => {
        loadingUserAccurate.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchDBAccurate = async tokenId => {
    loadingDBAccurate.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getMyAccurateDBList,
        variables: {
          token_id: tokenId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingDBAccurate.value = false
        accurateDBList.value = result.data.accurateDBList
        resolve(result.data.accurateDBList)
      }).catch(err => {
        loadingDBAccurate.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchAccurateAccounts = async (tokenId, dbId, type, pagination, search) => {
    loadingAccountAccurate.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getAccurateAccounts,
        variables: {
          token_id: tokenId,
          db_id: dbId,
          type,
          pagination,
          search,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingAccountAccurate.value = false
        accurateAccountList.value = result.data.getAccurateAccounts
        resolve(result.data.getAccurateAccounts)
      }).catch(err => {
        loadingAccountAccurate.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchAccurateExpenses = async (tokenId, dbId, pagination, search) => {
    loadingExpenseAccurate.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getAccurateExpenses,
        variables: {
          token_id: tokenId,
          db_id: dbId,
          pagination,
          search,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingExpenseAccurate.value = false
        accurateExpenseList.value = result.data.getAccurateExpenses
        resolve(result.data.getAccurateExpenses)
      }).catch(err => {
        loadingExpenseAccurate.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchAccurateFobs = async (tokenId, dbId, search, pagination) => {
    loadingFobAccurate.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getAccurateFobs,
        variables: {
          token_id: tokenId,
          db_id: dbId,
          pagination,
          search,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingFobAccurate.value = false
        accurateFobList.value = result.data.getAccurateFobs
        resolve(result.data.getAccurateFobs)
      }).catch(err => {
        loadingFobAccurate.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  return {
    accurateUserList,
    loadingUserAccurate,
    fetchUserAccurate,

    accurateDBList,
    loadingDBAccurate,
    fetchDBAccurate,

    accurateAccountList,
    loadingAccountAccurate,
    fetchAccurateAccounts,

    accurateExpenseList,
    loadingExpenseAccurate,
    fetchAccurateExpenses,

    accurateFobList,
    loadingFobAccurate,
    fetchAccurateFobs,
  }
}

export default useAccurate
