<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="sheetForm"
      lazy-validation
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <google-account-select
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang akan digunakan untuk membuat baris di worksheet',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <div
                :key="0"
                class="d-flex align-center"
              >
                <v-autocomplete
                  v-model="selectedSpreadsheet"
                  :items="driveSpreadsheetList"
                  :property-id="1203"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  attach
                  dense
                  :loading="loadingListSpreadsheet"
                  label="Spreadsheet"
                  class="mt-4"
                  hide-details="auto"
                  placeholder="Pilih Spreadsheet"
                  no-filter
                  required
                  @update:search-input="(val) => debouncedFetchSpreadsheet(selectedToken.refresh_token, val)"
                  @change="changeSpreadsheet"
                >
                  <template #append-item>
                    <div v-intersect="onIntersect" />
                  </template>
                  <template #item="{ item }">
                    <v-list-item-avatar>
                      <v-avatar color="#fef">
                        <v-img
                          :src="item.iconLink"
                          max-height="24px"
                          max-width="24px"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.id }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <workflow-field-info
                  :margin-top="'mt-3'"
                  title="Spreadsheet"
                  :details="[
                    'baris akan dibuat di dalam worksheet yang ada di file spreadsheet ini.',
                  ]"
                />
              </div>
              <div
                :key="1"
                class="d-flex align-center"
              >
                <v-select
                  v-if="selectedSpreadsheet"
                  v-model="selectedWorksheet"
                  :items="driveWorksheetList"
                  :property-id="1204"
                  flat
                  outlined
                  dense
                  attach
                  item-value="title"
                  item-text="title"
                  label="Worksheet"
                  class="mt-4"
                  hide-details="auto"
                  placeholder="Pilih Worksheet"
                  no-filter
                  :rules="[required]"
                  :loading="loadingListWorksheet"
                  @change="changeWorksheet"
                >
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ `${item.sheetType} - ${item.sheetId}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <workflow-field-info
                  :margin-top="'mt-3'"
                  title="Worksheet"
                  :details="[
                    'baris akan dibuat di dalam worksheet ini',
                  ]"
                />
              </div>
              <template
                v-if="selectedWorksheet"
              >
                <div
                  v-if="loadingListWorksheetHeader"
                  :key="2"
                  class="mt-4 d-flex justify-center"
                >
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  />
                </div>
                <template
                  v-else
                >
                  <span
                    :key="2"
                    class="ms-1 mt-3 text-caption d-block"
                  >
                    Daftar Kolom
                  </span>
                  <v-card
                    :key="3"
                    outlined
                    class="px-3"
                  >
                    <workflow-input
                      v-for="(item, idx) in driveWorksheetHeaderList"
                      :key="`item-${idx}`"
                      ref="sheetInput"
                      v-model="formData[`1206-${idx}`]"
                      :property-id="1206"
                      :placeholder="`#${idx}) ${item.name ? item.name : '(no name)'}`"
                      :identifier="`${idx}`"
                      :variables="options"
                      class="my-4"
                      @input="onInput(formData)"
                    >
                      <template #info>
                        <workflow-field-info
                          :margin-top="'mt-1'"
                          title="Isi kolom"
                          :details="[
                            'isi dari kolom',
                          ]"
                        />
                      </template>
                    </workflow-input>
                  </v-card>
                </template>
              </template>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useGoogle from '@/composables/useGoogle'
import { onMounted, ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import { required } from '@core/utils/validation'
import WorkflowInput from '../../components/WorkflowInput.vue'
import { isNumber } from '@/@core/utils/filter'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'

export default {
  components: {
    GoogleAccountSelect,
    WorkflowInput,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      googleUserList, loadingUserGoogle, fetchUserGoogle,
      fetchDriveSpreadsheetList, loadingListSpreadsheet, driveSpreadsheetList, driveSpreadsheetNextPageToken,
      fetchDriveWorksheetList, loadingListWorksheet, driveWorksheetList,
      fetchDriveWorksheetHeader, loadingListWorksheetHeader, driveWorksheetHeaderList,
    } = useGoogle()

    const sheetForm = ref(null)
    const sheetInput = ref(null)
    const selectedToken = ref(null)
    const selectedSpreadsheet = ref(null)
    const selectedWorksheet = ref(null)
    const formData = ref({})

    const loadFormEdit = data => {
      data.forEach(el => {
        // props untuk workflowInput

        if (el.property_id === 1203) selectedSpreadsheet.value = el.value
        if (el.property_id === 1204) selectedWorksheet.value = el.value

        const formDataIndex = el.identifier ? `${el.property_id}-${el.identifier}` : el.property_id
        if (!formData.value[formDataIndex]) formData.value[formDataIndex] = []
        formData.value[formDataIndex].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.token) {
          loadFormEdit(props.props)
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = googleUserList.value.find(el => el.id === props.token.id)
          fetchDriveSpreadsheetList(selectedToken.value.refresh_token, '')
          fetchDriveWorksheetList(selectedToken.value.refresh_token, selectedSpreadsheet.value)
          fetchDriveWorksheetHeader(selectedToken.value.refresh_token, selectedSpreadsheet.value, selectedWorksheet.value)
        }
      })
    })

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      selectedSpreadsheet.value = null
      fetchDriveSpreadsheetList(val.refresh_token, '')
    }

    const resetHeaderValue = () => {
      Object.keys(formData.value).forEach(key => {
        if (key.startsWith('105')) {
          delete formData.value[key]
        }
      })
    }

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      emit('input', result)
    }

    const validate = () => {
      let isValid = true
      console.log(sheetInput.value)
      if (!sheetForm.value.validate()) isValid = false
      for (let i = 0; i < sheetInput.value.length; i += 1) {
        if (!sheetInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    const debouncedFetchSpreadsheet = useDebounceFn((token, search, pageToken) => {
      if (token) fetchDriveSpreadsheetList(token, search, pageToken)
    }, 750)

    const onIntersect = entry => {
      if (entry[0].isIntersecting && driveSpreadsheetList.value.length > 0) {
        console.log(fetchDriveSpreadsheetList.value)
        if (driveSpreadsheetNextPageToken.value) {
          debouncedFetchSpreadsheet(selectedToken.value.refresh_token, '', driveSpreadsheetNextPageToken.value)
        }
      }
    }

    const changeSpreadsheet = val => {
      formData.value['1203'] = {
        parent_id: null,
        property_id: 1203,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
      fetchDriveWorksheetList(selectedToken.value.refresh_token, val)
    }

    const changeWorksheet = val => {
      formData.value['1204'] = {
        parent_id: null,
        property_id: 1204,
        sort: 0,
        value: val,
      }
      onInput(formData.value)

      fetchDriveWorksheetHeader(selectedToken.value.refresh_token, selectedSpreadsheet.value, val)
      resetHeaderValue()
    }

    return {
      sheetForm,
      sheetInput,
      formData,

      googleUserList,
      loadingUserGoogle,
      changeToken,
      selectedToken,
      selectedWorksheet,

      onInput,
      validate,
      onIntersect,

      loadingListSpreadsheet,
      driveSpreadsheetList,
      selectedSpreadsheet,

      required,

      debouncedFetchSpreadsheet,
      changeSpreadsheet,
      loadingListWorksheet,
      driveWorksheetList,
      changeWorksheet,
      loadingListWorksheetHeader,
      driveWorksheetHeaderList,
    }
  },
}
</script>
<style>

</style>
