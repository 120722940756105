<template>
  <div
    class="mt-2"
  >
    <v-form
      ref="calendarForm"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <v-row>
        <v-col
          v-for="i in 1"
          :key="i"
          cols="12"
          md="12"
        >
          <google-account-select
            v-model="selectedToken"
            :loading="loadingUserGoogle"
            :items="googleUserList"
            @input="changeToken"
          >
            <template #info>
              <workflow-field-info
                :margin-top="'mt-3'"
                title="Akun Google"
                :details="[
                  'Akun Google yang akan digunakan untuk membuat event',
                  'Jika akun Google belum terdaftar, silahkan menghubungkan melalui halaman integrasi',
                ]"
              >
                <template #actions>
                  <span>
                    <router-link
                      :to="{ name: 'integrations' }"
                      class="text-decoration-none text-caption"
                    >
                      Halaman integrasi
                    </router-link>
                  </span>
                </template>
              </workflow-field-info>
            </template>
          </google-account-select>
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-1 out-stagger-1"
          >
            <template
              v-if="selectedToken"
            >
              <div
                :key="0"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedCalendar"
                  :items="calendarList"
                  item-value="id"
                  item-text="summary"
                  flat
                  outlined
                  attach
                  dense
                  :loading="loadingCalendarList"
                  label="Kalender"
                  class="mt-4"
                  hide-details="auto"
                  placeholder="Pilih Kalender"
                  :rules="[required]"
                  @change="changeCalendar"
                >
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.summary }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption font-weight-semibold">
                        {{ item.id }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <workflow-field-info
                  :margin-top="'mt-3'"
                  title="Kalender"
                  :details="[
                    'Kalender yang digunakan sebagai tempat membuat event',
                  ]"
                />
              </div>
              <workflow-input
                :key="1"
                ref="calendarInput"
                v-model="formData['1003']"
                :property-id="1003"
                placeholder="Judul"
                :variables="options"
                class="my-4"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Judul"
                    :details="[
                      'Judul event yang akan dibuat',
                    ]"
                  />
                </template>
              </workflow-input>
              <workflow-input
                :key="2"
                ref="calendarInput"
                v-model="formData['1004']"
                :property-id="1004"
                placeholder="Deskripsi"
                :variables="options"
                class="my-4"
                long
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Deskripsi"
                    :details="[
                      'Deskripsi event yang akan dibuat',
                    ]"
                  />
                </template>
              </workflow-input>
              <v-row
                :key="3"
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <workflow-input
                    ref="calendarInput"
                    v-model="formData['1005']"
                    :property-id="1005"
                    placeholder="Waktu Mulai"
                    type="date"
                    required
                    :variables="options"
                    @input="onInput(formData)"
                  >
                    <template #info>
                      <workflow-field-info
                        :margin-top="'mt-1'"
                        title="Waktu Mulai"
                        :details="[
                          'Waktu mulai event yang akan dibuat',
                          'Format Waktu mulai: YYYY-MM-DDThh:mm:ss',
                          'YYYY: 4 digit tahun',
                          'MM: 2 digit bulan',
                          'DD: 2 digit hari',
                          'T: Pemisah tanggal & waktu',
                          'hh: 2 digit jam',
                          'mm: 2 digit menit',
                          'ss: 2 digit detik',
                          'Contoh: 2023-02-03T07:00:00'
                        ]"
                      />
                    </template>
                  </workflow-input>
                </v-col>
              </v-row>
              <v-row
                :key="4"
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="selectedOffset"
                      label="Offset Waktu"
                      outlined
                      dense
                      :rules="[validTimeOffset]"
                      hide-details="auto"
                      @change="changeOffset"
                    />
                    <div class="mt-2">
                      <workflow-field-info
                        title="Offset Waktu Mulai"
                        :details="[
                          'Offset Waktu mulai digunakan untuk mengubah waktu mulai relatif dari Waktu mulai yang sudah ditentukan',
                          'Format penulisan offset: [+-][0-9]{1,2}[smhd]',
                          '+/- menentukan percepatan / perlambatan waktu mulai',
                          'Angka setelah +/- menentukan jumlah percepatan / perlambatan waktu mulai',
                          'huruf s/m/h/d menentukan satuan waktu nya (s: detik, m: menit, h: jam, d: hari)',
                          'Contoh:',
                          'Waktu Mulai: 2023-02-03T07:00:00',
                          'Offset: +2d -12h -30m',
                          'Artinya, waktu mulai akan ditambah dengan 2 hari dan dikurangi dengan 12 jam 30 menit',
                          'Waktu mulai Setelah offset: 2023-02-04T18:30:00'
                        ]"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row :key="5">
                <v-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="selectedDuration"
                      label="Durasi Event (dalam menit)"
                      outlined
                      dense
                      type="number"
                      hide-details="auto"
                      @change="changeDuration"
                    />
                    <div class="mt-2">
                      <workflow-field-info
                        title="Durasi Event"
                        :details="[
                          'Durasi event dalam satuan menit (jika tidak diisi maka event dianggap berlangsung selama 1 hari penuh)'
                        ]"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <workflow-input
                :key="6"
                ref="calendarInput"
                v-model="formData['1008']"
                :property-id="1008"
                placeholder="Peserta"
                :variables="options"
                class="my-4"
                type="string"
                @input="onInput(formData)"
              >
                <template #info>
                  <workflow-field-info
                    :margin-top="'mt-1'"
                    title="Peserta"
                    :details="[
                      'Email dari peserta yang akan diundang ke event',
                      'Jika ada lebih dari 1 peserta, Pisahkan dengan tanda koma (,)',
                    ]"
                  />
                </template>
              </workflow-input>
              <div
                :key="7"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedColor"
                  :items="calendarEventColorList"
                  item-value="id"
                  item-text="background"
                  flat
                  outlined
                  attach
                  dense
                  :loading="loadingCalendarColor"
                  label="Warna"
                  class="my-4"
                  hide-details="auto"
                  placeholder="Pilih Warna"
                  @change="changeColor"
                >
                  <template #item="{ item }">
                    <v-list-item-avatar>
                      <v-avatar
                        size="36"
                        :color="item.background"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ item.background }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <workflow-field-info
                  title="Warna"
                  :details="[
                    'Warna penanda dari event',
                  ]"
                />
              </div>
              <div
                :key="8"
                class="d-flex align-center"
              >
                <RecurrencePicker
                  v-model="selectedRecurrence"
                  :property-id="1010"
                  label="Pengulangan"
                  @input="changeRecurrence"
                />
                <workflow-field-info
                  title="Pengulangan"
                  :details="[
                    'Menentukan apakah event adalah event berulang dan bagaimana pola pengulangannya',
                  ]"
                />
              </div>
              <div
                :key="9"
                class="d-flex align-center"
              >
                <v-select
                  v-model="selectedVisibility"
                  class="my-4"
                  label="Visibilitas"
                  :items="visibilityList"
                  item-text="text"
                  item-value="value"
                  flat
                  attach
                  outlined
                  dense
                  hide-details="auto"
                  @change="changeVisibility"
                />
                <workflow-field-info
                  title="Visibilitas"
                  :details="[
                    'Visibilitas / privasi event',
                  ]"
                />
              </div>
            </template>
          </xyztransitiongroup>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import useGoogle from '@/composables/useGoogle'
import { onMounted, ref } from '@vue/composition-api'
import { required, validTimeOffset } from '@core/utils/validation'
import WorkflowInput from '../../components/WorkflowInput.vue'
import RecurrencePicker from './components/RecurrencePicker.vue'
import GoogleAccountSelect from '../../components/selects/GoogleAccountSelect.vue'
import WorkflowFieldInfo from '../../components/WorkflowFieldInfo.vue'
import { isNumber } from '@/@core/utils/filter'

export default {
  components: {
    WorkflowInput,
    RecurrencePicker,
    GoogleAccountSelect,
    WorkflowFieldInfo,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    token: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      googleUserList, loadingUserGoogle, fetchUserGoogle,
      fetchCalendarColor, calendarEventColorList, loadingCalendarColor,
      fetchCalendarList, calendarList, loadingCalendarList,
    } = useGoogle()
    const selectedToken = ref(null)
    const calendarInput = ref(null)
    const calendarForm = ref(null)
    const formData = ref({})
    const selectedColor = ref(null)
    const selectedRecurrence = ref(null)
    const selectedVisibility = ref('default')
    const selectedCalendar = ref(null)
    const selectedOffset = ref(null)
    const selectedDuration = ref(null)

    const visibilityList = ref([
      {
        text: 'Default',
        value: 'default',
      },
      {
        text: 'Public',
        value: 'public',
      },
      {
        text: 'Private',
        value: 'private',
      },
      {
        text: 'Confidential',
        value: 'confidential',
      },
    ])

    const loadFormEdit = data => {
      // console.log(data)
      data.forEach(el => {
        // props untuk workflowInput
        if (el.property_id === 1007) selectedOffset.value = el.value
        if (el.property_id === 1006) selectedDuration.value = el.value
        if (el.property_id === 1002) selectedCalendar.value = el.value
        if (el.property_id === 1010) selectedRecurrence.value = el.value
        if (el.property_id === 1009) selectedColor.value = el.value
        if (el.property_id === 1011) selectedVisibility.value = el.value

        if (!formData.value[el.property_id]) formData.value[el.property_id] = []
        formData.value[el.property_id].push({ ...el, value: isNumber(el.value) ? parseFloat(el.value) : el.value })
      })
    }

    onMounted(() => {
      fetchUserGoogle().then(() => {
        if (props.token) {
          emit('changeToken', props.token.id, props.index)
          selectedToken.value = googleUserList.value.find(el => el.id === props.token.id)
          loadFormEdit(props.props)
          fetchCalendarList(selectedToken.value.refresh_token)
          fetchCalendarColor(selectedToken.value.refresh_token)
        }
      })
    })

    const onInput = data => {
      const result = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([_, val]) => {
        if (val) {
          if (Array.isArray(val)) result.push(...val)
          else result.push(val)
        }
      })
      console.log(result)
      emit('input', result)
    }

    const changeOffset = val => {
      formData.value['1007'] = {
        parent_id: null,
        property_id: 1007,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeDuration = val => {
      formData.value['1006'] = {
        parent_id: null,
        property_id: 1006,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeCalendar = val => {
      formData.value['1002'] = {
        parent_id: null,
        property_id: 1002,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeRecurrence = val => {
      formData.value['1010'] = {
        parent_id: null,
        property_id: 1010,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeColor = val => {
      formData.value['1009'] = {
        parent_id: null,
        property_id: 1009,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeVisibility = val => {
      formData.value['1011'] = {
        parent_id: null,
        property_id: 1011,
        sort: 0,
        value: val,
      }
      onInput(formData.value)
    }

    const changeToken = val => {
      emit('changeToken', val.id, props.index)
      fetchCalendarList(val.refresh_token)
      console.log(val)
      fetchCalendarColor(val.refresh_token)
    }

    const validate = () => {
      let isValid = true
      console.log(calendarInput.value)
      if (!calendarForm.value.validate()) isValid = false
      for (let i = 0; i < calendarInput.value.length; i += 1) {
        if (!calendarInput.value[i].validate()) isValid = false
      }

      return isValid
    }

    return {
      googleUserList,
      loadingUserGoogle,
      selectedToken,
      calendarInput,
      calendarForm,
      formData,
      required,
      validTimeOffset,

      changeToken,
      onInput,
      validate,
      selectedColor,
      calendarEventColorList,
      loadingCalendarColor,
      selectedRecurrence,
      visibilityList,
      selectedVisibility,

      calendarList,
      loadingCalendarList,
      selectedCalendar,

      selectedOffset,
      selectedDuration,

      changeOffset,
      changeDuration,
      changeCalendar,
      changeRecurrence,
      changeColor,
      changeVisibility,
    }
  },
}
</script>
