import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"cardForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,_vm._l((1),function(i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"12"}},[_c('trello-account-select',{ref:"trelloUserSelect",refInFor:true,attrs:{"loading":_vm.loadingUserTrello,"items":_vm.trelloUserList},on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Trello","details":[
                'Akun Trello yang akan digunakan untuk membuat kartu',
                'Jika akun Trello belum terdaftar, silahkan menghubungkan melalui halaman integrasi' ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[_c('router-link',{staticClass:"text-decoration-none text-caption",attrs:{"to":{ name: 'integrations' }}},[_vm._v(" Halaman integrasi ")])],1)]},proxy:true}],null,true)})]},proxy:true}],null,true),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloBoardList,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","attach":"","property-id":901,"disabled":!_vm.selectedToken,"loading":_vm.loadingBoardTrello,"label":"Board","hide-details":"auto","placeholder":"Pilih Board","rules":[_vm.required]},on:{"change":_vm.changeBoard},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedBoard),callback:function ($$v) {_vm.selectedBoard=$$v},expression:"selectedBoard"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Board","details":[
              'Board yang akan digunakan sebagai tempat membuat kartu' ]}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.trelloListList,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","attach":"","loading":_vm.loadingListTrello,"label":"List","disabled":!_vm.selectedBoard,"property-id":902,"rules":[_vm.required],"hide-details":"auto","placeholder":"Pilih List"},on:{"change":_vm.changeList},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"List","details":[
              'List yang akan digunakan sebagai tempat membuat kartu' ]}})],1),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedList)?[_c('workflow-input',{key:1,ref:"cardInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":903,"placeholder":"Nama Kartu","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Nama Kartu","details":[
                    'Nama dari kartu yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['903']),callback:function ($$v) {_vm.$set(_vm.formData, '903', $$v)},expression:"formData['903']"}}),_c('workflow-input',{key:2,ref:"cardInput",refInFor:true,staticClass:"my-4",attrs:{"property-id":904,"placeholder":"Deskripsi Kartu","long":"","variables":_vm.options},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Deskripsi Kartu","details":[
                    'Deskripsi dari kartu yang akan dibuat' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['904']),callback:function ($$v) {_vm.$set(_vm.formData, '904', $$v)},expression:"formData['904']"}}),_c('div',{key:3,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"my-4",attrs:{"item-text":"username","item-value":"id","property-id":907,"placeholder":"Ditugaskan ke","flat":"","loading":_vm.loadingBoardMemberTrello,"items":_vm.trelloBoardMemberList,"outlined":"","multiple":"","chips":"","dense":"","attach":"","hide-details":"auto"},on:{"change":_vm.changeMember},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c(VListItemAvatar,[_c(VAvatar,{staticClass:"mr-2 v-avatar-light-bg",attrs:{"size":"36"}},[_c('span',[_vm._v(_vm._s(_vm.avatarText(item.fullName)))])])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.username)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedIdMembers),callback:function ($$v) {_vm.selectedIdMembers=$$v},expression:"selectedIdMembers"}}),_c('workflow-field-info',{attrs:{"title":"Ditugaskan Ke","details":[
                  'Member yang ditugaskan ke kartu ini' ]}})],1),_c('div',{key:4,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"my-4",attrs:{"item-text":"color","item-value":"id","items":_vm.trelloLabelList,"loading":_vm.loadingLabelTrello,"property-id":908,"placeholder":"Label","flat":"","multiple":"","attach":"","outlined":"","dense":"","chips":"","hide-details":"auto"},on:{"change":_vm.changeLabel},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name ? item.name : item.color)+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.color)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedIdLabels),callback:function ($$v) {_vm.selectedIdLabels=$$v},expression:"selectedIdLabels"}}),_c('workflow-field-info',{attrs:{"title":"Label","details":[
                  'Label yang ditambahkan ke kartu ini' ]}})],1),_c('span',{key:5,staticClass:"ms-1 mt-3 mb-1 text-caption d-block"},[_vm._v(" Checklist (opsional) ")]),_c(VCard,{key:6,staticClass:"pa-3 pt-0",attrs:{"outlined":""}},[_c('workflow-input',{key:1,ref:"checkListInput",refInFor:true,staticClass:"mt-4",attrs:{"placeholder":"Nama Checklist","variables":_vm.options,"property-id":915},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":"mt-1","title":"Nama Checklist","details":[
                      'Nama dari Checklist yang ditambahkan' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['915']),callback:function ($$v) {_vm.$set(_vm.formData, '915', $$v)},expression:"formData['915']"}}),_c('workflow-input',{key:2,ref:"checkListInput",refInFor:true,staticClass:"my-4",attrs:{"placeholder":"Item Checklist","variables":_vm.options,"property-id":916},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Item Checklist","details":[
                      'Name dari item Checklist yang akan ditambahkan ke kartu',
                      'Jika ada lebih dari 1 item, Pisahkan dengan tanda koma (,)' ]}})]},proxy:true}],null,true),model:{value:(_vm.formData['916']),callback:function ($$v) {_vm.$set(_vm.formData, '916', $$v)},expression:"formData['916']"}})],1)]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }